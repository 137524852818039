import Navbar from "./Navbar";
import Footer from "./Footer";
import laser from "./imgs/laser.svg"
import cnc from "./imgs/cnc.svg"
import threeD from "./imgs/3DPrinter.svg"

import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react"
import { getTeam, getUser, updateNotifSettings } from "./js/database";
import { useAuthState } from "react-firebase-hooks/auth";
import {newTeam, addrSearch} from './js/script'
import $ from "jquery"
import { useNavigate } from "react-router-dom";

function Settings() {
    const auth = getAuth()
    const [team, setTeam] = useState()
    const [userP, setUserProfile] = useState()
    const navigate = useNavigate()
    const [user, , ] = useAuthState(auth, {onUserChanged:  (u) => {
        if (u === null) navigate("/login?redirect="+window.location.pathname)
        getUser(auth.currentUser.uid).then((userProfile) => {
            setUserProfile(userProfile)
            getTeam(userProfile.team).then(setTeam)
        })
    }})

    useEffect(() => {
        if (team !== undefined) {
        $.ajax({
            type: "GET",
            url:"https://nominatim.openstreetmap.org/search.php?q="+team.address+"&format=jsonv2",
            success: (locdata) => {
                if (locdata[0].display_name !== undefined) {
                    $("#teamAddr").val(locdata[0].display_name)
                }
                $("#teamLat").val(locdata[0].lat)
                $("#teamLong").val(locdata[0].lon)
                $("#teamAddrIframe").attr("src", `https://www.openstreetmap.org/export/embed.html?bbox=${locdata[0].boundingbox[2]}%2C${locdata[0].boundingbox[0]}%2C${locdata[0].boundingbox[3]}%2C${locdata[0].boundingbox[1]}%2C&amp;layer=mapnik`)
                $("#teamAddrIframe").show()
                $("#teamSurveySubmit").show()
            }
        })
    }
    }, [team])

    if (team===undefined) return;
    return (<div className="App">
    <Navbar user={auth.currentUser}></Navbar>

    <div className="container text-center">
    <form id="form" onSubmit={newTeam} onKeyDown={addrSearch}>
        <p id="teamName" style={{display:"none"}} >{team.name}</p>
        <input type="hidden" value={userP.team} id="searchTeam"></input>
        <h1>Team Settings</h1>
    <div className="row mt-2">
            <h4>Select all fabrication tools available to your team:</h4>
            <p>This is important so we can know what requests to send your way.</p>
            <div className="col">
                <input type="checkbox" className="btn-check" id="btn-check-1" name="3d" autoComplete="off" defaultChecked={team.resources["3d"] === "on"}/>
<label className="btn btn-yellow-tool" htmlFor="btn-check-1">3D Printer<br/><img src={threeD} alt="3d printer" height="150"/></label>
            </div>
            <div className="col">
                <input type="checkbox" className="btn-check" id="btn-check-2" name="laser" autoComplete="off" defaultChecked={team.resources["laser"] === "on"}/>
<label className="btn btn-yellow-tool" htmlFor="btn-check-2">Laser Cutter<br/><img height="150" src={laser} alt="laser"/></label>
            </div>
            <div className="col">
                <input type="checkbox" className="btn-check" id="btn-check-3" name="cnc" autoComplete="off" defaultChecked={team.resources["cnc"] === "on"}/>
<label className="btn btn-yellow-tool" htmlFor="btn-check-3">CNC<br/><img src={cnc} height="150" alt="cnc"/></label>
            </div>
        </div>
        <div className="row mt-2">
            <div className="col">
            <h4>Address of Your Workshop</h4>
            <p>We aim to match requests to the closest team, making the process cheaper and faster for both teams</p>
            <input className="form-control mt-2" type="text" name="addr" id="teamAddr" defaultValue={team.address}/>
            <input className="form-control mt-2" type="hidden" name="lat" id="teamLat"/>
            <input className="form-control mt-2" type="hidden" name="long" id="teamLong"/>
            <iframe title="Team Location" width="90%" height="350" style={{display:"none"}} className="mt-2" id="teamAddrIframe"></iframe><br/>
            <button className="btn btn-yellow mt-2" style={{display:"none"}} id="teamSurveySubmit">Save</button>


            </div>
        </div>
            </form>
            <h1 className="mt-3">User Settings</h1>
            <div className="row mt-3">
                <div className="col">
            <h4>Notifications</h4>
                <p className="m-0">Do you want to recieve notifications?</p>
                <div className="form-check form-switch justify-content-center d-flex">
  <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={userP.hasOwnProperty("notifSettings") ? userP.notifSettings.enabled : true}/>
</div>
                <h4>Notification Range</h4>
                <p>The distance from your workshop that you want to recieve notifications for new requests from.</p>
                <input className="form-control mt-2" type="number" name="range" id="userRange" defaultValue={userP.hasOwnProperty("notifSettings") ? userP.notifSettings.range : 3}/>
                <button className="btn btn-yellow mt-2" id="userNotifSave" onClick={saveNotifSettings}>Save</button><br/>
                <button className="btn btn-outline-danger mt-2"  data-bs-toggle="modal" data-bs-target="#confirmTeamChange">Change Team</button>
            </div>
            </div>
  </div>
  <div className="modal fade" id="confirmTeamChange" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Are you sure you want to change your team?</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        If you proceed you will be directed to the set team page.
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
        <button onClick={() => navigate("/onboard")} className="btn btn-primary" data-bs-dismiss="modal">Yes</button>
      </div>
    </div>
  </div>
</div>
  <Footer></Footer>
  </div>)

  function saveNotifSettings() {
    updateNotifSettings(user.uid, {enabled:$("#flexSwitchCheckDefault").is(":checked"), range:$("#userRange").val()})
  }

}

export default Settings;