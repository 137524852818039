import { StyledFirebaseAuth } from "react-firebaseui";
import Navbar from "./Navbar";
import { getAuth } from "firebase/auth";
import { uiConfig } from "./js/script";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import Footer from "./Footer";
import CFLogo from "./imgs/CrowdForgeLogo.svg";

function  Login() {
    const auth = getAuth()
    const navigate = useNavigate()
    const [, , ] = useAuthState(auth, {onUserChanged:  (u) => {
        var query = new URLSearchParams(window.location.search);
        if (u !== null) {
            navigate(query.get("redirect") || "/")
        }}
    })
    return (
        <div>
            <Navbar></Navbar>
            <div className="px-4 pt-5 my-5 text-center">
      <h1 className="display-4 fw-bold">Login</h1>
      <div className="col-lg-9 mx-auto">
      <div className="container px-5">
        <div className="row">
        <div className="col"><img src={CFLogo} height="400px" alt="CF Logo"/></div>
        </div>
        </div>
             <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}></StyledFirebaseAuth>   
      </div>
    </div>
    <div className="container mt-3">
  </div>
  <Footer></Footer>
  </div>
    )
}

export default Login;