import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import 'bootstrap';
import reportWebVitals from './reportWebVitals';
import Dashboard from './Dashboard';
import Onboard from './Onboard';
import NewRequest from './NewRequest';
import Request from './Request';
import TeamRequests from './TeamRequests';

import About from './About';
import Support from './Support.js'
import Stats from './Stats';
import Settings from './Settings';
import NotFound from './404';
import EditRequest from './EditRequest';
import HelpPage from './HelpPage';
import Help from './Help';
import Login from './Login';


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <App />
    ),
  },
  {
    path:"/dashboard",
    element:<Dashboard />
  },
  {
    path:"/onboard",
    element:<Onboard />
  },
  {
    path:"/newRequest",
    element:<NewRequest />
  },
  {
    path:"/request/:id",
    element:<Request />
  },
  {
    path:"/team/request",
    element:<TeamRequests />
  },
  {
    path:"/about",
    element:<About />
  },
  {
    path:"/support",
    element:<Support />
  },
  {
    path:"/team/stats",
    element:<Stats />
  },
  {
    path:"/settings",
    element:<Settings />
  },
  {
    path:"/editRequest/:id",
    element:<EditRequest/>
  },
  {
    path:"/help/:id",
    element:<HelpPage/>
  },
  {
    path:"/help/",
    element:<Help/>
  }
  ,
  {
    path:"/login",
    element:<Login/>
  },
  {
    path:"*",
    element:<NotFound/>
  }
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
