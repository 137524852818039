
import './App.scss';
import Navbar from './Navbar';



import  './js/script.js';
import { useEffect, useState } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { getRequests, getTeams, getUser } from './js/database';
import RequestPreview from './RequestPreview';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';
const auth = getAuth()

function TeamRequests() {
  const navigate = useNavigate()
  const [user, , ] = useAuthState(auth, {onUserChanged:(u) => {
    if (u === null) navigate("/login?redirect="+window.location.pathname)

    fetchRequests()
   // window.location.href="/dashboard"
  }});
  const [requests, setRequests] = useState({requests:[], accepted:[]})
  
  useEffect(() => {
    
    if (user!= null) {
    
     
    }
  })

  return (
    <div className="App">
      <Navbar user={auth.currentUser}></Navbar>
      <div className=" mt-2 container-fluid">
        <div className=" row">
            <div className="col">
            <h2>Your Requests</h2>
            </div>
            <div className="col d-flex justify-content-end">
                <a href="/newRequest"><button type="button" className="btn btn-yellow me-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg> Create Request</button></a>
            </div>
        </div>
        <div className="mt-2 row" id="requests">
    {requests.requests.map(([key, value]) => {
      return <RequestPreview id={key} request={value} team={true}/>
    })}
    </div>
    <div className="mt-2 row" id="requests">
        <h2>Accepted Requests</h2>
    {requests.accepted.map(([key, value]) => {
      return <RequestPreview id={key} request={value} team={true}/>
    })}
    </div>
    </div>
    <Footer></Footer>
    </div>
    
  );

  function fetchRequests() {
    getUser(auth.currentUser.uid).then(userProfile => {
      getTeams().then(teams => {
        getRequests().then(r => {
          Object.entries(r).forEach(([key, value]) => {
            value.id = key
          })
          var requestsEntries = Object.entries(r).filter(val => val[1].team===userProfile.team)
          var acceptedEntries = Object.entries(r).filter(val => val[1].assigned===userProfile.team)
              
              setRequests({requests:requestsEntries, accepted:acceptedEntries})
            
        })
      
       
      })
    })
    
  }
}


export default TeamRequests;
