import { getAuth } from "firebase/auth";
import { useEffect, useState, useRef } from "react";
import { getRequests, getUser } from "./js/database";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useAuthState } from "react-firebase-hooks/auth";
import { Chart, registerables } from "chart.js";
import { useNavigate } from "react-router-dom";


const auth = getAuth()
function Stats() {
    const [stats, setStats] = useState({})
    const navigate = useNavigate()
    const [, , ] = useAuthState(auth, {onUserChanged:  (u) => {
      if (u === null) navigate("/login?redirect="+window.location.pathname)

        getUser(u.uid).then(userProfile => {
            getRequests().then(requests => {
                console.log(userProfile)
                var stat = {fabricator:[], requested:[]}
                Object.entries(requests).forEach((request) => {
                    if (request[1].complete) {
                        if (request[1].assigned===userProfile.team) stat.fabricator.push(request)
                        else if (request[1].team===userProfile.team) stat.requested.push(request)
                    }
                })
                setStats(stat)
                console.log(stat)
            })
        })
    }})
    var chart;


    useEffect(() => {
        console.log(chart )
        
    })
    
    if (Object.entries(stats).length===0) return;
    console.log(stats)
    return (<div className="App" >
    <Navbar user={auth.currentUser}></Navbar>
    <div className="px-4 pt-5 my-5 text-center">
      <h1 className="display-4 fw-bold">Team's Stats</h1>
      <div className="col-lg-6 mx-auto">
      <div className="container px-5">
        <div className="row">
        <div className="col">
            <h2>Total Requests Made</h2>
            <h1 className="display-1">{stats.requested.length}</h1>
        </div>
        <div className="col">
            <h2>Total Requests Fabricated</h2>
            <h1 className="display-1">{stats.fabricator.length}</h1>
        </div>
        </div>
        </div>
      </div>
    </div>
    <div className="container mt-3 text-center">
        <div className="row ">
            <h2>Requests Fabricated</h2>
            <MyChart data={stats.fabricator}></MyChart>
        </div>
        <div className="row mt-3 ">
        <h2>Requests Made</h2>
            <MyChart data={stats.requested}></MyChart>
        </div>
  </div>
  <div className="container mt-3 text-center">
    <div className="row">
        <div className="col ">
            <h2>Requests Fabricated</h2>
            <PieChart data={stats.fabricator}></PieChart>
        </div>
        <div className="col ">
        <h2>Requests Made</h2>
            <PieChart data={stats.requested}></PieChart>
        </div>
        </div>
  </div>
  <Footer></Footer>
  </div>)
    
}

function MyChart({ data }) {
    const chartRef = useRef(null);
  
    useEffect(() => {
      if (data && chartRef.current) {
        const chart = createRequestsChart(data, chartRef.current);
        return () => {
          chart.destroy();
        };
      }
    }, [data]);
  
    return <canvas className="mx-auto" width="60%" ref={chartRef} />;
  }

  function PieChart({ data }) {
    const chartRef = useRef(null);
  
    useEffect(() => {
      if (data && chartRef.current) {
        var threed = 0;
        var laser = 0;
        var cnc = 0;
        
        data.forEach((request) => {
            if (request[1].tool === "3d") threed++;
            else if (request[1].tool ==="laser") laser++;
            else if (request[1].tool === "cnc") cnc++;
        })
        const ctx = chartRef.current.getContext("2d");
        
         
        const chart = new Chart(ctx, {
            type: "pie",
            data: {
                datasets: [{
                    data: [threed, laser, cnc]
                }],
            
                // These labels appear in the legend and in the tooltips when hovering different arcs
                labels: [
                    '3D Printing',
                    'Laser Cutting',
                    'CNCing'
                ]
            }
          });
        return () => {
          chart.destroy();
        };
      }
    }, [data]);
  
    return <canvas className="mx-auto"  ref={chartRef} />;
  }
  

function createRequestsChart(data, canvasRef) {
  console.log(data)
    // Create an object to store the count of completed requests for each month
    Chart.register(...registerables)
    const countsByMonth = {};
    const currentDate = new Date();

  
    // Loop through the data and update the countsByMonth object
    data.forEach((item) => {
      const timestamp = new Date(item[1].complete);
      console.log(timestamp) // Convert from Unix time to JavaScript Date object
      const month = timestamp.getMonth() + 1; // Month is zero-indexed, so add 1
      const year = timestamp.getFullYear().toString();
      const currentYear = currentDate.getFullYear().toString();
      const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
      const twelveMonthsAgo = new Date(
        currentDate.setFullYear(currentDate.getFullYear() - 1)
      );
      const twelveMonthsAgoYear = twelveMonthsAgo.getFullYear().toString();
      const twelveMonthsAgoMonth = (
        twelveMonthsAgo.getMonth() + 1
      ).toString().padStart(2, "0");
  
      // Only count requests completed in the last 12 months
      if (
        year >= twelveMonthsAgoYear &&
        (year !== twelveMonthsAgoYear || month >= twelveMonthsAgoMonth) &&
        (year !== currentYear || month <= currentMonth)
      ) {

        const monthYear = `${timestamp.toLocaleString('default', { month: 'long' }) } ${year}`;
        
        if (countsByMonth[monthYear]) {
          countsByMonth[monthYear]++;
        } else {
          countsByMonth[monthYear] = 1;
        }
      }
    });
  
    // Extract the counts and labels from the countsByMonth object into arrays
    const counts = [];
    const labels = [];
    let currentDateLabel = new Date();
    for (let i = 0; i < 12; i++) {
      currentDateLabel.setMonth(currentDateLabel.getMonth() - 1);
   //   const month = (currentDateLabel.getMonth() + 1).toString().padStart(2, "0");
      const year = currentDateLabel.getFullYear().toString();
      const monthYear = `${currentDateLabel.toLocaleString('default', { month: 'long' })} ${year}`;
      if (countsByMonth[monthYear] !== undefined){
    //    console.log("uhuxbubw")
      counts.unshift(countsByMonth[monthYear]);
      } else {
        console.log(monthYear)
        console.log(countsByMonth[monthYear])
        counts.unshift(0)
      }
      console.log(counts)
      labels.unshift(monthYear);
    }
  
    // Create the Chart.js chart
    const ctx = canvasRef.getContext("2d");
    const chart = new Chart(ctx, {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Completed Requests",
            data: counts,
            fill: true,
            backgroundColor:"rgba(255,110,78, 1)",
            borderColor: "rgba(255,110,78, 1)",
            tension: 0.1,
          },
        ],
      }
    });
  
    return chart;
  }

export default Stats;