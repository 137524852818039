
import laser from "./imgs/laser.svg"
import cnc from "./imgs/cnc.svg"
import threeD from "./imgs/3DPrinter.svg"
import './App.scss';
import Navbar from './Navbar';
import DarkLogo from "./imgs/CrowdforgeLong.svg"
import LightLogo from "./imgs/CrowdforgeLongLight.svg"



import { searchTeam, confirmTeam, newTeam, addrSearch, setNavigate} from './js/script.js';

import { useEffect } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
const auth = getAuth()

function Onboard() {
  const navigate = useNavigate()
  setNavigate(navigate)
  const [user, , ] = useAuthState(auth, {onUserChanged:(auth) => {
    if (auth === null) navigate("/login?redirect="+window.location.pathname)

   // window.location.href="/dashboard"
  }});
  console.log(auth)
  
  useEffect(() => {
    
    if (user!= null) {
    
     
    }
  })
  return (
    <div className="App">
      <Navbar user={auth.currentUser}></Navbar>
      <div className="px-4 pt-5 my-5 text-center">
        <h1 className="display-4 fw-bold">Welcome to <img src={DarkLogo} className="img-fluid dark-logo" alt="logo"/><img src={LightLogo} className="img-fluid light-logo" alt="logo"/></h1>
        <div className="col-lg-6 mx-auto" id="team">
          <p className="lead mb-4">Please input your team number to get started:</p>
          <input type="text" className="form-control mt-2" id="searchTeam" onKeyDown={searchTeam}/>
          <iframe title="teamlookup" id="teamlookup" style={{display:"none"}}  > </iframe>
           {/* eslint-disable-next-line */}
          <h2  className="mt-3" id="teamNumber"></h2>
          {/* eslint-disable-next-line */}
          <h3 id="teamName"></h3>
           {/* eslint-disable-next-line */}
          <h4 id="teamLoc" className="text-center"></h4>
          <iframe title="team" width="450" height="350" style={{display:"none"}} id="teamIframe"></iframe><br/>
          <button className="btn btn-yellow" id="confirmTeam" style={{display:"none"}} onClick={confirmTeam}>Confirm</button>
        </div>
        <form id="form" onSubmit={newTeam} onKeyDown={addrSearch}>
        <div className="container text-center" id="teamSurvey" style={{display:"none"}}>
            <input type="hidden" name="teamNumber" id="teamNumberSurvey"/>
            <input type="hidden" name="teamName" id="teamNameSurvey"/>
            <div className="row">
                <h2>Looks like you're new here.</h2>
                <p>Please fill out this survey about your team, so we can find the best teams to connect you with.</p>
            </div>
        <div className="row mt-2">
            <h4>Select all fabrication tools available to your team:</h4>
            <p>This is important so we can know what requests to send your way.</p>
            <div className="col">
                <input type="checkbox" className="btn-check" id="btn-check-1" name="3d" autoComplete="off"/>
<label className="btn btn-yellow-tool" htmlFor="btn-check-1">3D Printer<br/><img src={threeD} alt="3d printer" height="150"/></label>
            </div>
            <div className="col">
                <input type="checkbox" className="btn-check" id="btn-check-2" name="laser" autoComplete="off"/>
<label className="btn btn-yellow-tool" htmlFor="btn-check-2">Laser Cutter<br/><img height="150" src={laser} alt="laser"/></label>
            </div>
            <div className="col">
                <input type="checkbox" className="btn-check" id="btn-check-3" name="cnc" autoComplete="off"/>
<label className="btn btn-yellow-tool" htmlFor="btn-check-3">CNC<br/><img src={cnc} height="150" alt="cnc"/></label>
            </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            <h4>Address of Your Workshop</h4>
            <p>We aim to match requests to the closest team, making the process cheaper and faster for both teams</p>
            <input className="form-control mt-2" type="text" name="addr" id="teamAddr"/>
            <input className="form-control mt-2" type="hidden" name="lat" id="teamLat"/>
            <input className="form-control mt-2" type="hidden" name="long" id="teamLong"/>
            <iframe title="Team Location" width="90%" height="350" style={{display:"none"}} className="mt-2" id="teamAddrIframe"></iframe><br/>
            <button className="btn btn-yellow mt-2" style={{display:"none"}} id="teamSurveySubmit">Submit</button>


            </div>
            </div>
        </div>
        
    </form>
      </div>
      <Footer/>
      
    </div>
    
  );
}


export default Onboard;
