
import './App.scss';
import Navbar from './Navbar';
import Footer from './Footer';


import  './js/script.js';
import {useNavigate, useParams } from "react-router-dom";

import { useEffect, useState, lazy} from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { assignRequest, downloadUrl, getRequest, getUser, requestRecieved, deleteRequest} from './js/database';
import { materials } from './js/newRequest';

import {Helper} from 'dxf'

import $ from 'jquery';

const STLViewer = lazy(() => import('stl-viewer'));


const auth = getAuth()

function Request() {
  const {id} = useParams()
  const [request, setRequest] = useState()
  const navigate = useNavigate()

  
  const [user, , ] = useAuthState(auth, {onUserChanged:(aR) => {

      if (aR === null) navigate("/login?redirect="+window.location.pathname)
      
     // window.location.href="/dashboard"
   // window.location.href="/dashboard"
   
   getRequest(id).then(r => {

    downloadUrl(r.file).then((url) => {
        getUser(r.uid).then((requesterProfile) => {
            getUser(auth.currentUser.uid).then(userProfile => {
                auth.currentUser.team = userProfile.team
        r.url = url
        
        r.requester = requesterProfile
        if (r.url.includes("dxf") || r.url.includes("svg")) {
            $.ajax({
                type:"GET",
                url:r.url,
                success:(data) => {
                  if (r.url.includes("dxf")) {
                    r.svg = new Helper(data).toSVG()
                  } else {
                    r.svg = new XMLSerializer().serializeToString(data);
                  }
                  console.log(r.svg)
                    var encoded = r.svg.replace(/\s+/g, " ")
                    function escapeRegExp(str) {
                      // eslint-disable-next-line
                      return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
                    }
                    function replaceAll(str, find, replace) {
                      // eslint-disable-next-line
                      return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
                    }
    // According to Taylor Hunt, lowercase gzips better ... my tiny test confirms this
    encoded = replaceAll(encoded, "%", "%25"); 
    encoded = replaceAll(encoded, "> <", "><"); // normalise spaces elements
    encoded = replaceAll(encoded, "; }", ";}"); // normalise spaces css
    encoded = replaceAll(encoded, "<", "%3c");
    encoded = replaceAll(encoded, ">", "%3e");
    encoded = replaceAll(encoded, "\"", "'");
    encoded = replaceAll(encoded, "#", "%23"); // needed for ie and firefox
    encoded = replaceAll(encoded, "{", "%7b");
    encoded = replaceAll(encoded, "}", "%7d");     
    encoded = replaceAll(encoded, "|", "%7c");
    encoded = replaceAll(encoded, "^", "%5e");
    encoded = replaceAll(encoded, "`", "%60"); 
    encoded = replaceAll(encoded, "@", "%40"); 

    // charset reportedly not needed ... I need to test before implementing
    var uri = 'data:image/svg+xml;charset=UTF-8,' + encoded + '';
    r.svg = uri;
                    if (request !== r) {
                        setRequest(r)
                    }
                }
            })
            
        } else {
        if (request !== r) {
            setRequest(r)
        }
    }
    })
})
})
    
  })
  }});
 
  useEffect(() => {
    
    if (user!= null) {
    
     
    }
  })
 if (request === undefined) return
  return (
    <div className="App">
      <Navbar user={auth.currentUser}></Navbar>
      <div className="px-4 pt-5 my-5 text-center">
        <h1 className="display-4 fw-bold">{request.tool==="3d" && "3D Printing"}{request.tool==="laser" && "Laser Cutting"}{request.tool==="cnc" && "CNCing"}</h1>
        <div key={request.assigned} className="col-lg-6 mx-auto">
          <p className="lead mb-2">Team {request.team}</p>
          <p className='lead mb-4'>Created: {new Date(request.dateCreated).toLocaleDateString()}</p>
          {(request.url !== undefined && (request.url.includes("stl") || request.url.includes("3mf")))&& 
          <STLViewer
          model={request.url
        }
        className="mx-auto"
          modelColor={request.color !== undefined ? request.color.toLowerCase() :'#B92C2C'}
          backgroundColor='#EAEAEA'
          rotate={true}
          orbitControls={true}
          width={window.innerWidth > 600 ? 400 : window.innerWidth - 50}

      /> }
      {(request.url !== undefined && (request.url.includes("dxf") || request.url.includes("svg")) ) && 
          <div><img src={request.svg} className="p-4" style={{maxHeight:"400px", "backgroundColor":"white"}} alt="preview"></img></div> }
      {(auth.currentUser !== null && request.team !== auth.currentUser.team) ?
      !request.hasOwnProperty("assigned") ?
          <button className='btn btn-success mt-2' onClick={acceptRequest}>Accept</button>
       : <div className='mt-3 card'><div className='card-body'><h3>You have accepted this request.</h3>
       <p>Please download the file and fabricate as soon as possible.</p>
       <a href={request.url} target="_blank" rel="noreferrer"><button className='btn btn-yellow'>Download Now</button></a>
       <p className='mt-2'>For arranging delivery/pickup or questions you can contact the requesting team here:<br/><a href={"mailto:"+request.requester.email}>{request.requester.email}</a></p>
       <p className='mt-2'>If for any reason you are unable to complete this request within a reasonable time frame please unassign.</p>
       <button className='btn btn-success mt-2' onClick={unacceptRequest}>Unassign</button>
       </div></div> : request.hasOwnProperty("complete") ?  <div className='mt-3 card'><div className='card-body'><h3>Your request has been completed.</h3>
       <p>Good Luck!</p>


       </div></div> : request.hasOwnProperty("assigned") ? <div className='mt-3 card'><div className='card-body'><h3>Your request has been accepted.</h3>
       <p>The team fulfilling your request will contact you for questions and to arrange pickup/delivery. <b>When you receive your part click the Recieved button below.</b></p>
     <button className='btn btn-yellow' onClick={received}>Received</button>

       </div></div>
       :<div className='mt-3 card'><div className='card-body'><h3>Request Pending</h3>
       <p>Please wait while we find a team to fulfill your request.</p>
       <button onClick={() => {
        navigate("/editRequest/"+id)
       }} className='btn btn-yellow mt-2'>Edit Request</button>
       <button className='btn btn-danger mt-2 ms-2' onClick={del}>Delete Request</button>

       </div></div>
    }
        </div>
      </div>
      <div className="container mt-3">
        <div className="row">
          <h3>Material:</h3>
          <div className="card mt-2" id="matCard">
                <div className="card-header" id="matName">
                  {request.material}
                </div>
                <div className="card-body">
                  <p className="card-text " id="matDesc">{(request.tool !== undefined && materials[request.tool].hasOwnProperty(request.material)) ? materials[request.tool][request.material].desc : <p>This was a custom material requested by the team.</p>}</p>
                </div>
            </div>
            </div>
            {request.hasOwnProperty("color") &&
            <div className='row mt-2'>
                <h3>Color:</h3>
                <p>{request.color}</p>
    </div>}
    {request.hasOwnProperty("thickness") &&
    <div className='row mt-2'>
    <h3>Thickness:</h3>
    <p>{request.thickness}</p>
</div>}
{request.hasOwnProperty("size") &&
    <div className='row mt-2'>
    <h3>Size:</h3>
    <p>Width: {request.size.x} {request.size.unit}<br/>
    Length: {request.size.x} {request.size.unit}<br/>
    {request.size.hasOwnProperty("z") && <span>Length: {request.size.z} {request.size.unit}</span>}</p>
</div>}
{request.hasOwnProperty("notes") &&
    <div className='row mt-2'>
    <h3>Notes:</h3>
    <p>{request.notes}</p>
</div>}
    </div>
    <Footer></Footer>
    </div>
  )

  function acceptRequest() {
    getUser(auth.currentUser.uid).then((userProfile) => {
        assignRequest(id, userProfile.team).then(() => {
            var r = JSON.parse(JSON.stringify(request))
            r.assigned = userProfile.team
           // console.log(request)
            setRequest(r)
        })
    })
    
  }
  function unacceptRequest() {
    getUser(auth.currentUser.uid).then((userProfile) => {
        assignRequest(id, null).then(() => {
            var r = JSON.parse(JSON.stringify(request))

            delete r.assigned
            setRequest(r)
        //   /  console.log(request)
        })
    })
    
  }

  function del() {
    deleteRequest(id).then(() => {
      navigate("/")
    })
  }



  function received() {
    requestRecieved(id, request)
  }
  
  
  }


export default Request;
