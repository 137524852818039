import Navbar from "./Navbar";
import Footer from "./Footer";

import dark from "./imgs/404Dark.svg"
import light from "./imgs/404Light.svg"

function NotFound() {
    return (<div>
        <Navbar/>
        <img src={dark} className="dark-logo img-fluid" alt="dark"/>
        <img src={light} className="light-logo img-fluid" alt="dark"/>
        <Footer/>
    </div>)
}

export default NotFound;