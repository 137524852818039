
import { Link } from "react-router-dom";
import logo from "./imgs/CrowdforgeLong.svg"
import lightlogo from "./imgs/CrowdforgeLongLight.svg"
import { signOut, toggleTheme } from "./js/script";
function Navbar({user}) {
  
    return (<nav className="navbar navbar-expand-lg bg-body-tertiary">
    <div className="container-fluid">
    <Link className="navbar-brand" to="/">
      <img src={logo} alt="Bootstrap" className="dark-logo" height="24"/>
      <img src={lightlogo} alt="Bootstrap" className="light-logo" height="24"/>
    </Link>
      
      <div className="collapse navbar-collapse flex-row" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link active" aria-current="page" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">About Us</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/support">Support Us</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/help">Help</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="https://fwparkerrobotics.org">FWP Robotics</Link>
          </li>
          
        </ul>
        {user !== null &&
        <ul className="navbar-nav ms-auto">
            
            <li className="nav-item dropdown me-4">
               {/* eslint-disable-next-line */}
                <a className="nav-link dropdown-toggle"  id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  My Account
                </a>
                <ul className="dropdown-menu dropdown-menu-end me-2" aria-labelledby="navbarDropdownMenuLink">
                  <li><Link className="dropdown-item" to="/team/request">Your Team's Requests</Link></li>
                  
                    <li><Link className="dropdown-item" to="/team/stats">Stats</Link></li>
                    <li><Link className="dropdown-item" to="/settings">Settings</Link></li>
                    <li><button className="dropdown-item" onClick={toggleTheme}> Toggle Dark Mode</button></li>
                     {/* eslint-disable-next-line */}
                  <li><button className="dropdown-item" data-bs-toggle="modal" data-bs-target="#confirmSignout">Sign Out</button></li>
                  
                </ul>
              </li>
        </ul>
}
      </div>
      
        <button className="navbar-toggler justify-content-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
    </div>
    <div className="modal fade" id="confirmSignout" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Are you sure you want to sign out?</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        If you proceed you will be directed to the home page.
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">No</button>
        <button className="btn btn-primary" onClick={signOut}>Sign Out</button>
      </div>
    </div>
  </div>
</div>
  </nav>)
}

export default Navbar;