import Navbar from "./Navbar";
import { getAuth } from "firebase/auth";
import Footer from "./Footer";

function Support() {
  const auth = getAuth();
    return (<div className="App">
    <Navbar user={auth.currentUser}></Navbar>
    <div className="px-4 pt-5 my-5 text-center">
      <h1 className="display-4 fw-bold">Support CrowdForge + the FTC community</h1>
      <div className="col-lg-6 mx-auto">

        <p className="lead mb-4">Our goal is to make the process of participating in FTC robotics more accessible and affordable, and we can not achieve that goal without you! CrowdForge is only made possible due to our generous sponsors. If you or your team wishes to support CrowdForge monetarily, all contributions will go towards the development of our platform and towards the materials and equipment we provide for the CrowdForge community. To inquire about supporting CrowdForge, please contact our team at <a href="mailto:info@crowdforge.net">info@crowdforge.net</a>.</p>
      </div>
    </div>
    <div className="container mt-3 text-center">
      <div className="row">
        <h2>Our Sponsors</h2>
        <div className="col-sm m-3"><a href="https://fwparker.org"><img src="https://s3-prod.chicagobusiness.com/s3fs-public/inline-images/logo-francis1_0.png" alt="parker logo" className="img-fluid"/></a></div>
        <div className="col-sm m-3"><a href="https://www.shopbottools.com/"><img src="https://www.shopbottools.com/img/home/ShopBotLogo_White_180x41_v02-01.png" alt="shopbot logo" style={{backgroundColor:"#171b60"}} className="p-2 img-fluid"/></a></div>
      </div>
  </div>
  <Footer></Footer>
  </div>)
}

export default Support;