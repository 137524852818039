import { getAuth } from "firebase/auth";
import $ from "jquery";
import { createRequest, getUser, uploadFile, editRequest, deleteFile } from "./database";
const materials = {
    "3d":{
        PLA:{desc:"PLA (Polylactic Acid) is a biodegradable, plant-based thermoplastic with good strength and low warping, making it easy to print with excellent quality. It is the most common filament for 3D printing, used for prototypes, small parts, and really anything!", colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"]},
        ABS:{desc:"ABS (Acrylonitrile Butadiene Styrene) is a strong thermoplastic with high-temperature resistance, making it suitable for applications that require toughness. Its main drawbacks are its difficulty to print and the potential emission of toxic gases.", colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"]},
        PETG:{desc:"PETG (Polyethylene Terephthalate Glycol) is a strong and flexible thermoplastic with excellent impact resistance, making it ideal for objects that need to withstand wear and tear. It is commonly used as a stronger replacement to PLA.", colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"]},
        TPU:{desc:"TPU (Thermoplastic Polyurethane) is a flexible and rubber-like material that is resistant to abrasion and chemicals, making it suitable for producing soft and durable objects such as belts and treads.", colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"]},
        Nylon:{desc:"Nylon is a strong and durable thermoplastic with high melting point and good chemical resistance, making it suitable for making functional parts that require strength and durability such as gears, bearings, and hinges.", colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"]},
        PC:{desc:"PC (Polycarbonate) is a strong thermoplastic with high impact resistance and temperature resistance, making it suitable for producing objects that require high strength and durability.", colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"]},
        Other:{colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"]}
    },
    "laser":{
        Acrylic:{desc:"Acrylic, also known as Plexiglas, is a popular laser cutting material due to its versatility, affordability, and transparency. Acrylic is lightweight, brittle, and comes in a variety of colors, making it a great option for aesthetic parts.", colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"], thickness:["1/4in", "1/8in", "Other"]},
        Delrin:{desc:"Delrin is a thermoplastic material that is known for its strength, stiffness, and dimensional stability. It can be laser cut to create gears, sprockets, and other load bearing mechanical parts for robots.", colors:["Any", "White", "Black", "Other"], thickness:["1/4in", "1/8in", "Other"]},
        "Draftboard (MDF)":{desc:"Draftboard is a compressed recycled paper and resin material that's commonly used for rapid prototyping. It's affordable, versatile, and easy to cut and engrave to create parts such as gears, brackets, and frames.", colors:[], thickness:["1/4in", "1/8in", "Other"]},
        Plywood:{desc:"Plywood is a type of wood that is made by bonding several thin layers (or plies) of wood together. It is a popular laser cutter material due to its strength, stiffness, and natural look, and can be cut and engraved to create parts such as frames, panels, and other decorative elements.", colors:[], thickness:["1/4in", "1/8in", "Other"]},
        Other:{colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"], thickness:["1/4in", "1/8in", "Other"]}
    },
    "cnc":{
        Aluminum:{desc:"Aluminum is a lightweight and durable material commonly used for its high strength-to-weight ratio and machinability. It is ideal for structural components such as frames, chassis, and brackets, and can be anodized for corrosion resistance.", colors:[], thickness:["1/4in", "1/8in", "Other"]},
        Acrylic:{desc:"Acrylic is a clear, rigid plastic material commonly used for its transparency and ease of machining. It is often used for covers, light diffusers, and display panels.",colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"], thickness:["1/4in", "1/8in", "Other"]},
        Polycarbonate:{desc:"Polycarbonate is a strong, clear plastic material used for its impact resistance and durability. It is often used for protective covers, shields, and structural components.", colors:[], thickness:["1/4in", "1/8in", "Other"]},
        HDPE:{desc:"HDPE (High-Density Polyethylene) is a strong and lightweight plastic material used for its low coefficient of friction, making it ideal for slide rails, bearings, and other moving parts. It is also resistant to chemicals and moisture.", colors:[], thickness:["1/4in", "1/8in", "Other"]},
        Delrin:{desc:"Delrin (Acetal) is a strong and rigid plastic material used for its strength, stiffness, and low coefficient of friction, making it ideal for gears, bearings, and other mechanical components. It is also resistant to wear and moisture.",colors:["Any", "White", "Black", "Other"], thickness:["1/4in", "1/8in", "Other"]},
        Steel:{desc:"Steel is a strong and durable material commonly used for its strength and toughness, making it ideal for high-stress components such as axles, shafts, and gears. It can also be heat-treated for increased hardness and strength.", colors:[], thickness:["1/4in", "1/8in", "Other"]},
        Brass:{desc:"Brass is a corrosion-resistant metal commonly used for its low friction, making it ideal for bushings and bearings. It is also easily machined and has good electrical conductivity.", colors:[], thickness:["1/4in", "1/8in", "Other"]},
        "Carbon Fiber":{desc:"Carbon fiber is a strong and lightweight composite material used for its high strength-to-weight ratio, making it ideal for structural components such as frames and chassis. It is also resistant to corrosion and fatigue.", colors:[], thickness:["1/4in", "1/8in", "Other"]},
        Other:{colors:["Any", "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"], thickness:["1/4in", "1/8in", "Other"]}
    }
}

const auth = getAuth()
var tool;



function select3d(e) {
    
    tool = "3d"
    $("#tool").val(tool)
    $("#matSelect").html("<option selected disabled>Select</option>")
    $("#colorSelect").html("<option selected disabled>Select</option>")
    $("#thicknessSelect").html("<option selected disabled>Select</option>")
    $("#matCard").hide()
    $("#colorSelectDiv").hide()
    $("#thicknessSelectDiv").hide()
    $("#sizeSelectDiv").hide()
    $("#heightDiv").hide()
    $("xSizeText").val("")
    $("ySizeText").val("")
    $("zSizeText").val("")
    $(".active").removeClass("active")
    $("#select3d").addClass('active')
        Object.keys(materials[tool]).forEach((mat) => {
        var option = document.createElement("option")
        option.innerHTML = mat;
        $("#matSelect").append(option)
    })
    $("#3d-row").show()
    $("#fileInput").attr("accept", ".stl")
}
function selectLaser(e)  {
    tool = "laser"
    $("#tool").val(tool)
    $("#matSelect").html("<option selected disabled>Select</option>")
    $("#colorSelect").html("<option selected disabled>Select</option>")
    $("#thicknessSelect").html("<option selected disabled>Select</option>")
    $("#matCard").hide()
    $("#colorSelectDiv").hide()
    $("#thicknessSelectDiv").hide()
    $("#sizeSelectDiv").hide()
    $("#heightDiv").hide()
    $("xSizeText").val("")
    $("ySizeText").val("")
    $("zSizeText").val("")
    $(".active").removeClass("active")
    $("#selectLaser").addClass('active')  
      Object.keys(materials[tool]).forEach((mat) => {
        var option = document.createElement("option")
        option.innerHTML = mat;
        $("#matSelect").append(option)
    })
    $("#3d-row").show()
    $("#fileInput").attr("accept", ".dxf,.svg")
}
function selectCNC(e)  {
    tool = "cnc"
    $("#tool").val(tool)
    $("#matSelect").html("<option selected disabled>Select</option>")
    $("#colorSelect").html("<option selected disabled>Select</option>")
    $("#thicknessSelect").html("<option selected disabled>Select</option>")
    $("#matCard").hide()
    $("#colorSelectDiv").hide()
    $("#thicknessSelectDiv").hide()
    $("#sizeSelectDiv").hide()
    $("#heightDiv").hide()
    $("xSizeText").val("")
    $("ySizeText").val("")
    $("zSizeText").val("")
    $(".active").removeClass("active")
    $("#selectCNC").addClass('active')
    Object.keys(materials[tool]).forEach((mat) => {
        var option = document.createElement("option")
        option.innerHTML = mat;
        $("#matSelect").append(option)
    })
    $("#3d-row").show()
    $("#fileInput").attr("accept", ".dxf,.svg")
    
    
}





function materialChange() {
    var material = materials[tool][$("#matSelect").val()]
    if ($("#matSelect").val() !== "Other") {
        
        $("#matText").hide()
    $("#matName").text($("#matSelect").val())
    $("#matDesc").text(material.desc)
    $("#matCard").show()
    
    } else {
        $("#matCard").hide()
        $("#matText").show()
    }
    $("#colorSelect").html("<option selected disabled>Select</option>")
    $("#thicknessSelect").html("<option selected disabled>Select</option>")
    $("#thicknessSelectDiv").hide()
    $("#sizeSelectDiv").hide()

    if (material.colors.length > 0) {
        material.colors.forEach((color) => {
            var option = document.createElement("option")
            option.innerHTML = color;
            $("#colorSelect").append(option)
        })
        $("#colorSelectDiv").show()
    } else {
        console.log("ijxij")
        $("#colorSelectDiv").hide()
        colorChange()
    }

}

function colorChange () {
    var material = materials[tool][$("#matSelect").val()]
    $("#thicknessSelect").html("<option selected disabled>Select</option>")
        if (material.hasOwnProperty("thickness")) {
            console.log("hello")
            material.thickness.forEach((color) => {
                var option = document.createElement("option")
                option.innerHTML = color;
                $("#thicknessSelect").append(option)
            })
            $("#thicknessSelectDiv").show()
            $("#sizeSelectDiv").hide()
        } else {
        $("#sizeSelectDiv").show()
    if (tool === "3d") $("#heightDiv").show()
    if ($("#thicknessSelect").val()==="Other") {
        $("#thicknessText").show()
    }
        }
    if ($("#colorSelect").val() !== "Other") {
        

 } else {
        $("#colorText").show()
    }
    
    

}

function thicknessChange () {
    $("#sizeSelectDiv").show()
    if (tool === "3d") $("#heightDiv").show()
    if ($("#thicknessSelect").val()==="Other") {
        $("#thicknessText").show()
    }
}
function sizeChange () {
    $("#notesDiv").show()
    if ($("#thicknessSelect").val()==="Other") {
        $("#thicknessText").show()
    }
}

function newRequest(e, navigate) {
    e.preventDefault()
    getUser(auth.currentUser.uid).then(userProfile => {
        console.log($("#fileInput").val())
        var myFile = $('#fileInput').prop('files')[0]
        uploadFile( new Date().getTime()+"."+myFile.name.split(".").slice(-1)[0], myFile).then((fileUpload) => {

        const request = {
            team:userProfile.team,
            uid:auth.currentUser.uid,
            tool:$("#tool").val(),
            material: (($("#matSelect").val() !== "Other") ?$("#matSelect").val()  : $("#matText").val()),
            color: (($("#colorSelect").val() !== "Other") ?$("#colorSelect").val()  : $("#colorText").val()),
            thickness: (($("#thicknessSelect").val() !== "Other") ?$("#thicknessSelect").val()  : $("#thicknessText").val()),
            notes: $("#notes").val(),
            file:fileUpload.metadata.fullPath,
            dateCreated: new Date().getTime(),
            size: {
                x:$("#xSizeText").val(),
                y:$("#ySizeText").val(),
                z:$("#zSizeText").val() !== "" ? $("#zSizeText").val() : null,
                unit:$("#unitSelect").val()
            }
        }
        console.log(request)
    
        Object.entries(request).forEach(([key, val]) => {
            if (val==="" || val===undefined) {
                delete request[key]
            }
        })

        createRequest(request).then(() => {
            navigate("/")
        })
    })
})
    
}
function modRequest(e, navigate) {
    e.preventDefault()
    getUser(auth.currentUser.uid).then(userProfile => {
        if ($("#fileInput").prop("files").length > 0) {
            var myFile = $('#fileInput').prop('files')[0]
            deleteFile($("#fileInputDefault").val()).then(() => {
            uploadFile( new Date().getTime()+"."+myFile.name.split(".").slice(-1)[0], myFile).then((fileUpload) => {
    
            const request = {
                team:userProfile.team,
                uid:auth.currentUser.uid,
                tool:$("#tool").val(),
                material: (($("#matSelect").val() !== "Other") ?$("#matSelect").val()  : $("#matText").val()),
                color: (($("#colorSelect").val() !== "Other") ?$("#colorSelect").val()  : $("#colorText").val()),
                thickness: (($("#thicknessSelect").val() !== "Other") ?$("#thicknessSelect").val()  : $("#thicknessText").val()),
                notes: $("#notes").val(),
                file:fileUpload.metadata.fullPath,
                dateCreated: new Date().getTime(),
                size: {
                    x:$("#xSizeText").val(),
                    y:$("#ySizeText").val(),
                    z:$("#zSizeText").val() !== "" ? $("#zSizeText").val() : null,
                    unit:$("#unitSelect").val()
                }
            }
            console.log(request)
        
            Object.entries(request).forEach(([key, val]) => {
                if (val==="" || val===undefined) {
                    delete request[key]
                }
            })
    
            editRequest($("#id").val(), request).then(() => {
                navigate("/request/"+$("#id").val())
            })
        
        })
    })
    
        } else {
        const request = {
            team:userProfile.team,
            uid:auth.currentUser.uid,
            tool:$("#tool").val(),
            material: (($("#matSelect").val() !== "Other") ?$("#matSelect").val()  : $("#matText").val()),
            color: (($("#colorSelect").val() !== "Other") ?$("#colorSelect").val()  : $("#colorText").val()),
            thickness: (($("#thicknessSelect").val() !== "Other") ?$("#thicknessSelect").val()  : $("#thicknessText").val()),
            notes: $("#notes").val(),
            file: $("#fileInputDefault").val(),
            dateCreated: new Date().getTime(),
            size: {
                x:$("#xSizeText").val(),
                y:$("#ySizeText").val(),
                z:$("#zSizeText").val() !== "" ? $("#zSizeText").val() : null,
                unit:$("#unitSelect").val()
            }
        }
        console.log(request)
    
        Object.entries(request).forEach(([key, val]) => {
            if (val==="" || val===undefined) {
                delete request[key]
            }
        })

        editRequest($("#id").val(), request).then(() => {
            navigate("/request/"+$("#id").val())
        })
    }
    })
}
export {select3d, selectLaser, selectCNC, materialChange, colorChange, thicknessChange, materials, newRequest, modRequest, sizeChange}