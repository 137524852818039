import { getAuth } from "firebase/auth";
import Navbar from "./Navbar";
import Logo from "./3507logo"
import CFLogo from "./imgs/CrowdForgeLogo.svg"

import Footer from "./Footer";

function About() {
    const auth = getAuth();
    return (<div className="App">
    <Navbar user={auth.currentUser}></Navbar>
    <div className="px-4 pt-5 my-5 text-center">
      <h1 className="display-4 fw-bold">About Us</h1>
      <div className="col-lg-9 mx-auto">
      <div className="container px-5">
        <div className="row">
        <div className="col-sm"><img src={CFLogo} className="img-fluid" alt="CF Logo"/></div>
        <div className="col-sm"><Logo /></div>
        </div>
        </div>
        

        <p className="lead mb-4">Welcome to CrowdForge, created by FTC team 3507 Robotheosis from Francis W. Parker School in Chicago, Illinois! <br/><br/>CrowdForge’s mission is to give every FTC team all the resources they need, by hosting a platform that cultivates a network of fabrication support! Any team can request parts to be 3D printed, Laser-cut, or CNC milled to their specifications. CrowdForge then connects them to other teams on our platform nearby who have the tools and supplies to fabricate the custom parts. Every team is encouraged to use CrowdForge, whether they are sending or receiving parts. 
</p>
      </div>
    </div>
    <div className="container mt-3">
  </div>
  <Footer></Footer>
  </div>)
}

export default About;