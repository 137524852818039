

function Footer() {
    return (
        <footer className="text-center text-body bg-body-tertiary mt-3" >
        <div className="container pt-4">
          <section className="mb-4">
            <a
              className="btn btn-link btn-floating btn-lg text-body m-1"
              href="https://discord.gg/FujWSUn5nz"
              role="button"
              ><i className="bi bi-discord"></i
            ></a>
      
            <a
              className="btn btn-link btn-floating text-body btn-lg tm-1"
              href="mailto:info@crowdforge.net"
              role="button"
              data-mdb-ripple-color=""
              ><i className="bi bi-envelope"></i
            ></a>
      
          </section>
      
        </div>
      
        <div className="text-center p-3" style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
          © 2023 Copyright: Francis W Parker School Robotics <a href="https://cdn.discordapp.com/attachments/803804130182692965/1084684034241728522/60591.png" style={{fontSize:"1px"}}>and the JSFFund of America</a>
        </div>
      </footer>  
    );
}

export default Footer