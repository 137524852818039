
import { useEffect, useState, createElement } from 'react'
// import { NotionRenderer } from 'react-notion'


import ReactMarkdown from 'react-markdown'
import NotFound from './404'
import remarkGfm from 'remark-gfm'
import subsuper from "remark-supersub"

// // import "react-notion/src/styles.css";


// const notion = new NotionAPI()
function HelpPage({id, title, symbol, page}) {

    const [record, setRecord] = useState()

    useEffect(() => {
        // fetch("https://notion-api.splitbee.io/v1/page/"+id).then(resp => resp.json()).then(data  => {
        //     console.log(data)
        //     setRecord(data)
        // }).catch(e => {
        //     console.log(e)
        // })
        fetch(`/docs/${id}.md`).then(res => res.text()).then((restext) =>{
           // console.log(restext)
            if (restext.includes("<!DOCTYPE html>")) {
                setRecord("notfound")
            } else {
                setRecord(restext)
            }
        }
        )
        // notion.getPage(id).then(setRecord)

    }, [id])
    function Heading(props) {
        const text = props.children.reduce((acc, current) => {
            console.log(acc)
            return acc + current}
            , [])
            console.log(text)
        const slug = text
          .toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/[^a-z-0-9]/g, '')
          console.log(slug)
      
        return createElement(`h${props.level}`, {id: slug}, props.children)
      }

      function Imgs(props) {
        return createElement("img", {className:"img-fluid", ...props}, props.children)
      } 

      function Table(props) {
        return createElement("table", {className:"table", ...props}, props.children)
      }  
      function Blockquote(props) {
        return createElement("blockquote", {className:"m-4", ...props}, props.children)
      }  
    if (record === undefined) return
    if (record === "notfound") return <NotFound/>
    return (<div class="accordion-item">
    <h2 class="accordion-header">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+page} aria-expanded="true" aria-controls={"collapse"+page}>
        <h2>{symbol} {title}</h2>
      </button>
    </h2>
    <div id={"collapse"+page} class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <ReactMarkdown children={record} components={{h3: Heading,h4:Heading,  img:Imgs, table:Table, blockquote:Blockquote}} remarkPlugins={[remarkGfm, subsuper]}/>
      </div>
    </div>
  </div>)
}

export default HelpPage;