
import './App.scss';
import Navbar from './Navbar';



import  './js/script.js';

import { useEffect, useState } from 'react';


import { getAuth } from 'firebase/auth';
import { getRequests, getTeams, getUser } from './js/database';



import Table from './Table';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';


const auth = getAuth()

function Dashboard() {




  



  const [requests, setRequests] = useState(null)
  const [fabricator, setFabricator] = useState({})
  const navigate = useNavigate()
  useEffect(fetchRequests, [navigate])
  
  return (
    <div className="App ">
      <Navbar user={auth.currentUser}></Navbar>
      
      <div className="container-fluid">
      {requests &&
        <Table data={Object.values(Object.fromEntries(requests))} fabricator={fabricator}></Table>
}
           </div>
           <Footer></Footer>
    </div>
    
  );
  function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // radius of Earth in kilometers
    const dLat = (parseFloat(lat2) - parseFloat(lat1)) * Math.PI / 180;
    const dLon = (parseFloat(lon2) - parseFloat(lon1)) * Math.PI / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
       Math.cos(parseFloat(lat1) * Math.PI / 180) * Math.cos(parseFloat(lat2) * Math.PI / 180) *
       Math.sin(dLon / 2) * Math.sin(dLon / 2);
       console.log(a)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
   
    const d = R * c;
    return d * 0.621371; // convert to miles
  }
   function fetchRequests() {
      getUser(auth.currentUser.uid).then(userProfile => {
        if (userProfile ===null) {
          navigate("/onboard")
        }
        getTeams().then(teams => {
          getRequests().then(r => {
            if (r === null) r = {}
            var requestsEntries = Object.entries(r).filter(val => { 
              // console.log(val[1])
                if (val[1].hasOwnProperty("assigned")) return false;
                if (val[1].hasOwnProperty("complete")) return false;
                if (val[1].team === userProfile.team) return false;
              // //yeet = (filters.colors.hasOwnProperty(val[1].color) && filters.colors[val[1].color]===true) ? false : (!filters.colors.hasOwnProperty(val[1].color) && filters.colors["Other"]===true) ? false : false
              // console.log(yeet)
              return true;
            })
            console.log(requestsEntries)
            var fabricator = teams[userProfile.team]
            setFabricator()
                requestsEntries.sort((a, b) => {
                    
                    var aTeam = teams[a[1]["team"]]
                    var bTeam = teams[b[1]["team"]]
                    var dist = getDistance(aTeam.coords.lat, aTeam.coords.long, fabricator.coords.lat, fabricator.coords.long) - getDistance(bTeam.coords.lat, bTeam.coords.long, fabricator.coords.lat, fabricator.coords.long)
                    if (dist !== 0) return dist
                    return a[1].dateCreated-b[1].dateCreated
                })
                requestsEntries.forEach((entry) => {
                  entry[1].id = entry[0]
                  var team = teams[entry[1].team]
                  entry[1].milesAway = getDistance(team.coords.lat, team.coords.long, fabricator.coords.lat, fabricator.coords.long).toFixed(2)
                })
                setRequests(requestsEntries)
              
          })
        
         
        })
      })
      
    }

    
}

export default Dashboard;
