function Robotheosis() {
    return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6144.000000 6144.000000"
     preserveAspectRatio="xMidYMid meet" className="body-color img-fluid">
    
    <g transform="translate(0.000000,6144.000000) scale(0.100000,-0.100000)"
    stroke="none">
    <path d="M26603 59283 c-13 -14 -23 -32 -23 -39 0 -7 -6 -25 -14 -41 -8 -15
    -21 -46 -29 -68 -8 -22 -29 -76 -47 -120 -18 -44 -39 -98 -47 -120 -9 -22 -21
    -53 -28 -70 -7 -16 -20 -48 -28 -70 -8 -22 -29 -75 -46 -117 -17 -43 -45 -113
    -62 -155 -17 -43 -38 -96 -46 -118 -8 -22 -21 -53 -28 -70 -7 -16 -19 -48 -28
    -70 -29 -77 -50 -130 -62 -160 -7 -16 -20 -48 -28 -70 -8 -22 -29 -76 -47
    -120 -18 -44 -39 -98 -47 -120 -9 -22 -21 -53 -28 -70 -7 -16 -20 -48 -28 -70
    -15 -39 -41 -106 -107 -270 -18 -44 -39 -98 -47 -120 -8 -22 -21 -53 -28 -70
    -7 -16 -19 -48 -28 -70 -8 -22 -29 -76 -47 -120 -18 -44 -38 -96 -45 -115 -7
    -19 -27 -71 -45 -115 -18 -44 -39 -98 -47 -120 -9 -22 -21 -53 -28 -70 -7 -16
    -20 -48 -28 -70 -8 -22 -29 -76 -47 -120 -66 -164 -92 -231 -107 -270 -8 -22
    -21 -53 -28 -70 -7 -16 -19 -48 -28 -70 -8 -22 -29 -76 -47 -120 -18 -44 -39
    -98 -47 -120 -9 -22 -21 -53 -28 -70 -7 -16 -20 -48 -28 -70 -8 -22 -29 -76
    -47 -120 -18 -44 -39 -98 -47 -120 -9 -22 -21 -53 -28 -70 -7 -16 -20 -48 -28
    -70 -24 -64 -77 -196 -97 -241 -21 -46 -11 -43 -322 -120 -54 -13 -95 -24
    -238 -61 -90 -24 -233 -63 -320 -88 -25 -7 -74 -21 -110 -30 -36 -10 -83 -23
    -105 -30 -22 -7 -67 -20 -100 -30 -33 -9 -78 -23 -100 -30 -22 -6 -67 -20
    -100 -30 -33 -9 -78 -23 -100 -30 -22 -7 -67 -20 -100 -30 -33 -10 -76 -23
    -95 -30 -19 -7 -82 -27 -140 -45 -58 -18 -121 -38 -140 -45 -76 -27 -106 -37
    -135 -45 -16 -5 -95 -32 -175 -60 -80 -28 -176 -62 -215 -75 -38 -13 -95 -33
    -125 -45 -65 -25 -171 -64 -220 -81 -76 -26 -108 -38 -185 -69 -44 -18 -97
    -38 -117 -46 -21 -8 -55 -20 -75 -28 -21 -8 -73 -28 -115 -45 -43 -17 -113
    -45 -155 -62 -43 -17 -91 -37 -108 -44 -16 -7 -82 -34 -145 -60 -119 -49 -130
    -54 -243 -104 -37 -17 -73 -31 -80 -31 -7 0 -17 -4 -22 -9 -6 -5 -55 -28 -109
    -51 -54 -23 -106 -46 -115 -50 -9 -5 -36 -18 -61 -28 -25 -11 -76 -35 -115
    -52 -38 -18 -95 -44 -125 -57 -322 -147 -945 -457 -1220 -606 -36 -20 -87 -47
    -115 -61 -27 -14 -79 -42 -115 -62 -36 -20 -85 -47 -110 -61 -25 -13 -63 -34
    -85 -47 -181 -101 -255 -143 -275 -156 -8 -6 -22 -13 -30 -17 -8 -4 -69 -39
    -135 -78 -66 -39 -127 -74 -135 -78 -8 -4 -28 -16 -45 -27 -16 -11 -39 -24
    -50 -30 -11 -6 -33 -19 -50 -30 -16 -11 -39 -24 -50 -30 -11 -6 -33 -19 -50
    -30 -72 -47 -86 -47 -195 2 -84 38 -127 57 -167 74 -18 8 -50 22 -70 31 -21 9
    -54 23 -73 31 -19 8 -42 18 -51 22 -8 4 -123 54 -254 110 -131 56 -246 106
    -254 110 -9 4 -34 15 -56 25 -22 9 -56 24 -75 32 -19 9 -48 21 -65 28 -43 18
    -107 47 -126 55 -8 4 -107 47 -219 95 -112 48 -211 91 -219 95 -9 4 -32 14
    -51 22 -19 8 -53 23 -75 32 -22 10 -55 24 -72 32 -53 23 -107 46 -124 54 -8 4
    -123 54 -254 110 -131 56 -246 106 -254 110 -16 7 -68 30 -128 56 -18 8 -49
    21 -68 29 -19 9 -52 23 -72 32 -21 9 -45 19 -53 23 -9 4 -139 60 -290 125
    -151 65 -281 121 -290 125 -8 4 -32 14 -52 24 -55 24 -95 41 -171 75 -37 17
    -73 31 -80 31 -7 0 -17 4 -23 9 -5 5 -101 48 -213 96 -223 95 -221 94 -269
    118 -19 9 -43 17 -55 17 -31 0 -5797 -5766 -5797 -5797 0 -12 8 -36 17 -55 24
    -48 23 -46 118 -269 48 -112 91 -208 96 -213 5 -6 9 -16 9 -23 0 -7 14 -43 31
    -80 34 -76 51 -116 75 -170 10 -21 20 -45 24 -53 4 -9 60 -139 125 -290 65
    -151 121 -281 125 -290 4 -8 14 -32 23 -53 9 -20 23 -53 32 -72 8 -19 22 -51
    30 -70 9 -19 23 -52 32 -72 9 -21 19 -45 23 -54 4 -8 54 -123 110 -254 56
    -131 106 -246 110 -254 9 -21 58 -132 86 -196 36 -85 47 -111 54 -126 4 -8 47
    -107 95 -219 48 -112 91 -211 95 -219 4 -9 14 -33 23 -54 9 -20 23 -53 32 -72
    8 -19 22 -51 30 -70 9 -19 23 -52 32 -72 9 -21 19 -45 23 -54 4 -8 54 -123
    110 -254 56 -131 106 -246 110 -254 4 -9 14 -33 23 -54 9 -20 23 -53 32 -72 8
    -19 22 -51 30 -70 9 -19 22 -49 30 -67 8 -18 27 -62 43 -98 49 -109 49 -123 2
    -195 -11 -16 -24 -39 -30 -50 -6 -11 -19 -33 -30 -50 -11 -16 -24 -39 -30 -50
    -6 -11 -19 -33 -30 -50 -11 -16 -23 -37 -27 -45 -8 -17 -148 -258 -163 -280
    -6 -8 -13 -22 -17 -30 -4 -8 -19 -36 -34 -61 -32 -55 -112 -197 -162 -289 -14
    -25 -41 -74 -61 -110 -20 -36 -48 -87 -62 -115 -14 -27 -41 -79 -61 -115 -149
    -275 -469 -918 -606 -1220 -14 -30 -39 -86 -57 -125 -17 -38 -41 -90 -52 -115
    -10 -25 -23 -52 -28 -61 -4 -9 -27 -61 -50 -115 -23 -54 -46 -103 -51 -108 -5
    -6 -9 -16 -9 -23 0 -7 -14 -43 -31 -80 -50 -113 -55 -124 -104 -243 -26 -63
    -53 -128 -60 -145 -7 -16 -27 -65 -44 -107 -17 -43 -45 -113 -62 -155 -17 -43
    -37 -95 -45 -115 -8 -21 -20 -55 -28 -75 -18 -48 -74 -188 -92 -231 -8 -18
    -14 -41 -14 -50 0 -10 -4 -22 -9 -27 -4 -6 -20 -44 -34 -85 -14 -41 -35 -100
    -47 -130 -12 -30 -32 -86 -45 -125 -13 -38 -47 -135 -75 -215 -28 -80 -55
    -158 -60 -175 -8 -29 -18 -59 -45 -135 -7 -19 -27 -82 -45 -140 -18 -58 -38
    -121 -45 -140 -7 -19 -20 -62 -30 -95 -10 -33 -23 -78 -30 -100 -7 -22 -21
    -67 -30 -100 -10 -33 -24 -78 -30 -100 -7 -22 -21 -67 -30 -100 -10 -33 -23
    -78 -30 -100 -7 -22 -20 -69 -30 -105 -9 -36 -23 -85 -30 -110 -7 -25 -20 -70
    -28 -100 -9 -30 -22 -80 -31 -110 -8 -30 -22 -84 -32 -120 -9 -36 -22 -87 -30
    -115 -7 -27 -19 -77 -28 -110 -27 -112 -47 -190 -64 -249 -18 -64 -12 -61
    -172 -124 -41 -16 -111 -44 -155 -62 -44 -18 -98 -39 -120 -47 -22 -8 -53 -21
    -70 -28 -16 -7 -48 -19 -70 -28 -22 -8 -76 -29 -120 -47 -44 -18 -98 -39 -120
    -47 -22 -8 -53 -21 -70 -28 -16 -7 -48 -19 -70 -28 -22 -8 -76 -29 -120 -47
    -44 -18 -96 -38 -115 -45 -19 -7 -70 -27 -112 -44 -43 -17 -113 -45 -155 -62
    -43 -17 -96 -38 -118 -46 -22 -8 -53 -21 -70 -28 -16 -7 -48 -19 -70 -28 -22
    -8 -76 -29 -120 -47 -44 -18 -98 -39 -120 -47 -22 -8 -53 -21 -70 -28 -16 -7
    -48 -19 -70 -28 -22 -8 -76 -29 -120 -47 -44 -18 -98 -39 -120 -47 -22 -8 -53
    -21 -70 -28 -16 -7 -48 -19 -70 -28 -22 -8 -76 -29 -120 -47 -44 -18 -98 -39
    -120 -47 -22 -8 -53 -21 -70 -28 -16 -7 -48 -19 -70 -28 -22 -8 -76 -29 -120
    -47 -44 -18 -98 -39 -120 -47 -22 -8 -53 -21 -70 -28 -16 -7 -48 -19 -70 -28
    -22 -8 -76 -29 -120 -47 -44 -18 -98 -39 -120 -47 -22 -8 -53 -21 -70 -28 -16
    -7 -48 -19 -70 -28 -22 -8 -76 -29 -120 -47 -44 -18 -98 -39 -120 -47 -22 -9
    -53 -21 -70 -28 -16 -7 -48 -20 -70 -28 -22 -8 -76 -29 -120 -47 -44 -18 -98
    -39 -120 -47 -22 -8 -53 -21 -68 -29 -16 -8 -34 -14 -41 -14 -7 0 -25 -11 -40
    -25 l-26 -24 0 -4076 c0 -3868 1 -4077 17 -4094 16 -17 55 -35 168 -78 22 -9
    54 -21 70 -28 17 -7 48 -20 70 -28 22 -8 76 -29 120 -47 44 -18 98 -39 120
    -47 22 -9 54 -21 70 -28 17 -7 48 -20 70 -28 22 -8 76 -29 120 -47 44 -18 98
    -39 120 -47 22 -9 54 -21 70 -28 17 -7 48 -20 70 -28 22 -8 76 -29 120 -47 44
    -18 98 -39 120 -47 22 -9 54 -21 70 -28 17 -7 48 -20 70 -28 22 -8 76 -29 120
    -47 44 -18 98 -39 120 -47 22 -9 54 -21 70 -28 17 -7 48 -20 70 -28 22 -8 76
    -29 120 -47 44 -18 98 -39 120 -47 22 -9 54 -21 70 -28 17 -7 48 -20 70 -28
    22 -8 76 -29 120 -47 44 -18 98 -39 120 -47 22 -9 54 -21 70 -28 17 -7 48 -20
    70 -28 22 -8 76 -29 120 -47 44 -18 98 -39 120 -47 22 -9 54 -21 70 -28 17 -7
    48 -20 70 -28 22 -8 76 -29 120 -47 44 -18 98 -39 120 -47 22 -9 54 -21 70
    -28 17 -7 48 -20 70 -28 22 -8 76 -29 120 -47 44 -18 98 -39 120 -47 22 -9 54
    -21 70 -28 17 -7 48 -20 70 -28 22 -8 76 -29 120 -47 44 -18 98 -39 120 -47
    22 -9 54 -21 70 -28 17 -7 48 -20 70 -28 64 -24 196 -77 241 -97 43 -19 45
    -22 89 -200 8 -33 22 -87 30 -120 14 -56 25 -100 62 -240 24 -90 63 -233 88
    -320 7 -25 21 -74 30 -110 10 -36 23 -83 30 -105 7 -22 20 -67 30 -100 9 -33
    23 -78 30 -100 6 -22 20 -67 30 -100 9 -33 23 -78 30 -100 7 -22 20 -67 30
    -100 10 -33 23 -76 30 -95 7 -19 27 -82 45 -140 18 -58 38 -121 45 -140 27
    -76 37 -106 45 -135 5 -16 32 -95 60 -175 28 -80 62 -176 75 -215 13 -38 33
    -95 45 -125 25 -65 64 -171 81 -220 26 -76 38 -108 69 -185 18 -44 38 -96 45
    -115 25 -69 43 -116 75 -195 18 -44 46 -114 62 -155 16 -41 36 -88 43 -105 7
    -16 34 -82 60 -145 49 -119 54 -130 104 -243 17 -37 31 -73 31 -80 0 -7 4 -17
    9 -23 5 -5 28 -54 51 -108 23 -54 46 -106 50 -115 5 -9 18 -36 28 -61 11 -25
    35 -76 52 -115 18 -38 44 -95 57 -125 147 -322 457 -945 606 -1220 20 -36 47
    -87 61 -115 14 -27 42 -79 62 -115 20 -36 47 -85 61 -110 13 -25 34 -63 47
    -85 101 -181 143 -255 156 -275 6 -8 13 -22 17 -30 4 -8 39 -69 78 -135 39
    -66 74 -127 78 -135 4 -8 16 -28 27 -45 11 -16 24 -39 30 -50 6 -11 19 -33 30
    -50 11 -16 24 -39 30 -50 6 -11 19 -33 30 -50 47 -72 47 -86 -2 -195 -50 -112
    -84 -190 -103 -235 -18 -43 -47 -107 -55 -126 -4 -8 -54 -123 -110 -254 -56
    -131 -106 -246 -110 -254 -8 -19 -37 -83 -55 -126 -7 -16 -20 -48 -30 -70 -10
    -22 -25 -56 -33 -75 -8 -19 -18 -42 -22 -51 -4 -8 -47 -107 -95 -219 -48 -112
    -91 -211 -95 -219 -4 -9 -14 -32 -22 -51 -25 -59 -44 -103 -61 -142 -9 -21
    -24 -55 -33 -75 -10 -21 -20 -45 -24 -54 -4 -8 -54 -123 -110 -254 -56 -131
    -106 -246 -110 -254 -8 -19 -37 -83 -55 -126 -7 -16 -20 -48 -30 -70 -10 -22
    -25 -56 -33 -75 -8 -19 -18 -42 -22 -50 -4 -9 -60 -139 -125 -290 -65 -151
    -121 -281 -125 -290 -4 -8 -14 -31 -22 -50 -9 -19 -22 -51 -31 -70 -9 -19 -30
    -65 -46 -103 -17 -37 -31 -73 -31 -80 0 -7 -4 -17 -9 -23 -5 -5 -48 -101 -96
    -213 -95 -223 -94 -221 -118 -269 -9 -19 -17 -43 -17 -55 0 -31 5766 -5797
    5797 -5797 12 0 36 8 55 17 48 24 46 23 269 118 112 48 208 91 214 96 5 5 15
    9 22 9 7 0 43 14 80 31 76 34 116 51 171 75 20 10 44 20 52 24 9 4 139 60 290
    125 151 65 281 121 290 125 8 4 31 14 50 22 19 8 52 22 73 31 20 9 54 24 75
    33 20 10 44 20 52 24 9 4 139 60 290 125 151 65 281 121 290 125 8 4 31 14 50
    22 19 8 52 22 73 31 20 9 52 23 70 31 61 26 112 49 128 56 8 4 107 47 219 95
    112 48 211 91 219 95 16 7 67 30 129 56 17 8 47 20 65 28 60 26 112 49 128 56
    8 4 123 54 254 110 131 56 246 106 254 110 9 4 32 14 51 22 19 8 52 22 73 31
    20 9 54 25 75 34 20 9 51 22 67 29 17 7 59 26 95 42 109 49 123 49 195 2 17
    -11 39 -24 50 -30 11 -6 34 -19 50 -30 17 -11 39 -24 50 -30 11 -6 34 -19 50
    -30 17 -11 37 -23 45 -27 17 -8 258 -148 280 -163 8 -6 22 -13 30 -17 8 -4 36
    -19 61 -34 55 -32 197 -112 289 -162 25 -14 74 -41 110 -61 36 -20 88 -48 115
    -62 28 -14 79 -41 115 -61 275 -149 918 -469 1220 -606 30 -14 87 -39 125 -57
    39 -17 90 -41 115 -52 25 -10 52 -23 61 -28 9 -4 61 -27 115 -50 54 -23 103
    -46 109 -51 5 -5 15 -9 22 -9 7 0 43 -14 80 -31 113 -50 124 -55 243 -104 63
    -26 129 -53 145 -60 17 -7 66 -28 110 -45 44 -18 114 -46 155 -62 75 -30 124
    -49 190 -73 43 -16 183 -72 228 -91 18 -8 41 -14 50 -14 10 0 22 -4 28 -9 5
    -4 43 -20 84 -34 41 -14 100 -35 130 -47 30 -12 87 -32 125 -45 39 -13 135
    -47 215 -75 80 -28 159 -55 175 -60 29 -8 59 -18 135 -45 19 -7 82 -27 140
    -45 58 -18 121 -38 140 -45 19 -7 62 -20 95 -30 33 -10 78 -23 100 -30 22 -7
    67 -21 100 -30 33 -10 78 -24 100 -30 22 -7 67 -21 100 -30 33 -10 78 -23 100
    -30 22 -7 69 -20 105 -30 36 -9 85 -23 110 -30 25 -7 70 -20 100 -28 30 -9 80
    -22 110 -31 30 -8 84 -22 120 -32 36 -9 88 -22 115 -30 47 -12 90 -23 238 -60
    34 -8 89 -23 122 -33 l59 -17 34 -79 c19 -44 40 -96 47 -115 13 -34 38 -97
    105 -265 18 -44 39 -98 47 -120 8 -22 21 -53 28 -70 7 -16 20 -49 29 -72 9
    -24 23 -62 32 -85 9 -24 22 -56 29 -73 7 -16 20 -48 28 -70 8 -22 29 -76 47
    -120 66 -163 92 -231 105 -265 7 -19 27 -70 44 -112 17 -43 45 -113 62 -155
    17 -43 38 -96 46 -118 8 -22 21 -53 28 -70 12 -30 33 -83 62 -160 9 -22 21
    -53 28 -70 7 -16 20 -48 28 -70 8 -22 29 -75 46 -117 17 -43 45 -113 62 -155
    17 -43 37 -94 44 -113 7 -19 27 -71 45 -115 66 -164 92 -231 107 -270 8 -22
    21 -53 28 -70 12 -30 33 -83 62 -160 9 -22 21 -53 28 -70 7 -16 20 -48 28 -70
    8 -22 29 -75 46 -117 17 -43 45 -113 62 -155 17 -43 37 -94 44 -113 13 -34 39
    -102 105 -265 18 -44 39 -98 47 -120 9 -22 21 -53 28 -70 7 -16 20 -48 28 -70
    8 -22 29 -73 46 -114 17 -40 31 -79 31 -86 0 -7 11 -24 25 -39 l24 -26 4076 0
    4076 0 24 26 c14 15 25 33 25 40 0 7 6 25 14 41 8 15 21 46 29 68 8 22 29 76
    47 120 18 44 39 98 47 120 8 22 21 54 28 70 7 17 19 48 28 70 8 22 29 76 47
    120 18 44 39 98 47 120 9 22 21 54 28 70 7 17 20 48 28 70 8 22 29 76 47 120
    18 44 39 98 47 120 9 22 21 54 28 70 7 17 20 48 28 70 8 22 29 76 47 120 18
    44 39 98 47 120 9 22 21 54 28 70 7 17 20 48 28 70 8 22 29 75 46 118 17 42
    45 112 62 155 17 42 37 93 44 112 7 19 27 71 45 115 18 44 39 98 47 120 9 22
    21 54 28 70 7 17 20 48 28 70 8 22 29 76 47 120 18 44 39 98 47 120 9 22 21
    54 28 70 7 17 20 48 28 70 8 22 29 75 46 118 17 42 45 112 62 155 17 42 37 93
    44 112 7 19 27 71 45 115 18 44 39 98 47 120 9 22 21 54 28 70 7 17 20 48 28
    70 8 22 29 76 47 120 18 44 39 98 47 120 9 22 21 54 28 70 7 17 20 48 28 70 8
    22 29 75 46 118 17 42 45 112 62 155 65 163 60 156 128 175 57 16 101 27 239
    61 31 8 86 22 122 32 36 9 90 23 120 31 30 8 80 21 110 30 30 8 80 21 110 30
    30 8 75 21 100 28 25 7 74 21 110 30 36 10 83 23 105 30 22 7 67 20 100 30 33
    9 78 23 100 30 22 6 67 20 100 30 33 9 78 23 100 30 22 7 67 20 100 30 33 10
    76 23 95 30 19 7 82 27 140 45 58 18 121 38 140 45 76 27 106 37 135 45 17 5
    95 32 175 60 80 28 177 62 215 75 39 13 95 33 125 45 30 12 89 33 130 47 41
    14 79 30 84 34 6 5 18 9 28 9 9 0 32 6 50 14 45 19 185 75 228 91 66 24 115
    43 190 73 41 16 111 44 155 62 44 17 94 38 110 45 17 7 82 34 145 60 119 49
    130 54 243 104 37 17 73 31 80 31 7 0 17 4 22 9 6 5 55 28 109 51 54 23 106
    46 115 50 9 5 36 18 61 28 25 11 77 35 115 52 39 18 95 43 125 57 302 137 945
    457 1220 606 36 20 88 47 115 61 28 14 79 42 115 62 36 20 85 47 110 61 25 13
    63 34 85 47 181 101 255 143 275 156 8 6 22 13 30 17 8 4 69 39 135 78 66 39
    127 74 135 78 8 4 29 16 45 27 17 11 39 24 50 30 11 6 34 19 50 30 17 11 39
    24 50 30 11 6 34 19 50 30 72 47 86 47 195 -2 36 -16 80 -35 98 -43 18 -8 48
    -21 67 -30 19 -8 51 -22 70 -30 19 -9 52 -23 72 -32 21 -9 45 -19 54 -23 8 -4
    123 -54 254 -110 131 -56 246 -106 254 -110 9 -4 33 -14 54 -23 20 -9 53 -23
    72 -32 19 -8 51 -22 70 -30 19 -9 52 -23 72 -32 21 -9 45 -19 54 -23 8 -4 107
    -47 219 -95 112 -48 211 -91 219 -95 9 -4 33 -14 54 -23 20 -9 53 -23 72 -32
    19 -8 51 -22 70 -30 19 -9 52 -23 72 -32 21 -9 45 -19 53 -23 9 -4 139 -60
    290 -125 151 -65 281 -121 290 -125 19 -9 126 -56 197 -87 21 -9 45 -19 53
    -23 9 -4 139 -60 290 -125 151 -65 281 -121 290 -125 8 -4 32 -14 53 -24 54
    -24 94 -41 170 -75 37 -17 73 -31 80 -31 7 0 17 -4 22 -9 6 -5 102 -48 214
    -96 223 -95 221 -94 269 -118 19 -9 43 -17 55 -17 31 0 5797 5766 5797 5797 0
    12 -8 36 -17 55 -24 48 -23 46 -118 269 -48 112 -91 208 -96 214 -5 5 -9 15
    -9 22 0 7 -14 43 -31 80 -16 38 -36 82 -43 98 -21 48 -48 109 -56 125 -4 9
    -60 139 -125 290 -65 151 -121 281 -125 290 -7 15 -30 67 -56 128 -8 17 -22
    49 -31 70 -9 20 -23 53 -31 72 -8 19 -18 42 -22 51 -4 8 -54 123 -110 254 -56
    131 -106 246 -110 254 -11 24 -41 92 -55 126 -33 78 -74 172 -85 196 -4 8 -47
    107 -95 219 -48 112 -91 211 -95 219 -7 16 -30 67 -56 129 -8 17 -22 49 -31
    70 -9 20 -23 53 -31 72 -8 19 -18 42 -22 51 -4 8 -54 123 -110 254 -56 131
    -106 246 -110 254 -4 9 -14 32 -22 51 -8 19 -23 53 -33 75 -10 22 -23 54 -30
    70 -15 34 -31 71 -73 165 -49 109 -49 123 -2 195 11 17 24 39 30 50 6 11 19
    34 30 50 11 17 24 39 30 50 6 11 19 34 30 50 11 17 23 37 27 45 8 17 148 258
    163 280 6 8 13 22 17 30 4 8 19 36 34 61 32 55 112 197 162 289 14 25 41 74
    61 110 20 36 48 88 62 115 14 28 41 79 61 115 149 275 459 898 606 1220 13 30
    39 87 57 125 17 39 41 90 52 115 10 25 23 52 28 61 4 9 27 61 50 115 23 54 46
    103 51 109 5 5 9 15 9 22 0 7 14 43 31 80 50 113 55 124 104 243 26 63 53 129
    60 145 7 17 27 64 43 105 16 41 44 111 62 155 18 44 38 96 45 115 25 68 43
    116 75 195 31 77 43 109 69 185 17 49 56 155 81 220 12 30 32 87 45 125 13 39
    47 135 75 215 28 80 55 159 60 175 8 29 18 59 45 135 7 19 27 82 45 140 18 58
    38 121 45 140 7 19 20 62 30 95 10 33 23 78 30 100 7 22 21 67 30 100 10 33
    24 78 30 100 7 22 21 67 30 100 10 33 23 78 30 100 7 22 20 69 30 105 9 36 23
    85 30 110 27 94 69 246 91 330 9 36 22 88 30 115 7 28 20 78 28 113 81 320 74
    301 120 322 45 20 177 73 241 97 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76
    29 120 47 44 18 98 39 120 47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29
    120 47 44 18 98 39 120 47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29 120
    47 44 18 98 39 120 47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29 120 47
    44 18 98 39 120 47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29 120 47 44
    18 98 39 120 47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29 120 47 44 18
    98 39 120 47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29 120 47 44 18 98
    39 120 47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29 120 47 44 18 98 39
    120 47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29 120 47 44 18 98 39 120
    47 22 8 54 21 70 28 17 7 48 19 70 28 22 8 76 29 120 47 44 18 98 39 120 47
    22 8 54 21 70 28 17 7 48 19 70 28 113 43 152 61 168 78 16 17 17 226 17 4094
    l0 4076 -26 24 c-15 14 -33 25 -40 25 -7 0 -25 6 -41 14 -15 8 -46 21 -68 29
    -22 8 -76 29 -120 47 -44 18 -98 39 -120 47 -22 8 -53 21 -70 28 -16 7 -48 19
    -70 28 -22 8 -76 29 -120 47 -44 18 -98 39 -120 47 -22 9 -53 21 -70 28 -16 7
    -48 20 -70 28 -22 8 -76 29 -120 47 -44 18 -98 39 -120 47 -22 9 -53 21 -70
    28 -16 7 -48 20 -70 28 -22 8 -76 29 -120 47 -44 18 -98 39 -120 47 -22 9 -53
    21 -70 28 -16 7 -48 20 -70 28 -22 8 -76 29 -120 47 -44 18 -98 39 -120 47
    -22 9 -53 21 -70 28 -16 7 -48 20 -70 28 -22 8 -76 29 -120 47 -44 18 -98 39
    -120 47 -22 9 -53 21 -70 28 -16 7 -48 20 -70 28 -22 8 -76 29 -120 47 -44 18
    -98 39 -120 47 -22 9 -53 21 -70 28 -16 7 -48 20 -70 28 -22 8 -75 29 -117 46
    -43 17 -113 45 -155 62 -43 17 -94 37 -113 44 -19 7 -71 27 -115 45 -44 18
    -98 39 -120 47 -22 9 -53 21 -70 28 -16 7 -48 20 -70 28 -22 8 -76 29 -120 47
    -44 18 -98 39 -120 47 -22 9 -53 21 -70 28 -16 7 -48 20 -70 28 -22 8 -74 28
    -115 45 -350 140 -313 118 -334 193 -16 60 -27 103 -62 242 -13 53 -24 94 -61
    237 -24 90 -63 233 -88 320 -7 25 -21 74 -30 110 -10 36 -23 83 -30 105 -7 22
    -20 67 -30 100 -9 33 -23 78 -30 100 -6 22 -20 67 -30 100 -9 33 -23 78 -30
    100 -7 22 -20 67 -30 100 -10 33 -23 76 -30 95 -7 19 -27 82 -45 140 -18 58
    -38 121 -45 140 -27 76 -37 106 -45 135 -5 17 -32 95 -60 175 -28 80 -62 177
    -75 215 -13 39 -33 95 -45 125 -12 30 -33 89 -47 130 -14 41 -30 79 -34 85 -5
    5 -9 17 -9 27 0 9 -6 32 -14 50 -18 43 -74 183 -92 231 -8 20 -20 54 -28 75
    -8 20 -28 72 -45 115 -17 42 -45 112 -62 155 -17 42 -37 91 -44 107 -7 17 -34
    82 -60 145 -49 119 -54 130 -104 243 -17 37 -31 73 -31 80 0 7 -4 17 -9 22 -5
    6 -28 55 -51 109 -23 54 -46 106 -50 115 -5 9 -18 36 -28 61 -11 25 -35 77
    -52 115 -18 39 -43 95 -57 125 -137 302 -457 945 -606 1220 -20 36 -47 88 -61
    115 -14 28 -42 79 -62 115 -20 36 -47 85 -61 110 -13 25 -34 63 -47 85 -101
    181 -143 255 -156 275 -6 8 -13 22 -17 30 -4 8 -39 69 -78 135 -39 66 -74 127
    -78 135 -4 8 -16 29 -27 45 -11 17 -24 39 -30 50 -6 11 -19 34 -30 50 -11 17
    -24 39 -30 50 -6 11 -19 34 -30 50 -47 72 -47 86 2 195 16 36 35 79 42 95 7
    17 20 46 28 65 8 19 22 51 31 70 8 19 23 53 32 75 10 22 21 47 25 56 4 8 54
    123 110 254 56 131 106 246 110 254 4 9 14 32 22 51 8 19 22 52 31 73 9 20 23
    52 31 70 26 61 49 112 56 128 4 8 47 107 95 219 48 112 91 211 95 219 10 22
    40 90 54 126 7 17 21 48 31 70 10 22 24 56 33 75 8 19 18 42 22 51 4 8 54 123
    110 254 56 131 106 246 110 254 4 9 14 32 22 51 8 19 22 52 31 73 9 20 23 52
    31 70 26 60 49 112 56 127 4 9 60 139 125 290 65 151 121 281 125 290 8 16 35
    77 56 125 7 17 27 60 43 98 17 37 31 73 31 80 0 7 4 17 9 22 5 6 48 102 96
    214 95 223 94 221 118 269 9 19 17 43 17 55 0 31 -5766 5797 -5797 5797 -12 0
    -36 -8 -55 -17 -48 -24 -46 -23 -269 -118 -112 -48 -208 -91 -214 -96 -5 -5
    -15 -9 -22 -9 -7 0 -43 -14 -80 -31 -76 -34 -116 -51 -170 -75 -21 -10 -45
    -20 -53 -24 -9 -4 -139 -60 -290 -125 -151 -65 -281 -121 -290 -125 -8 -4 -32
    -14 -53 -23 -20 -9 -53 -23 -72 -32 -19 -8 -50 -21 -68 -29 -60 -26 -112 -49
    -128 -56 -8 -4 -123 -54 -254 -110 -131 -56 -246 -106 -254 -110 -24 -11 -89
    -39 -191 -83 -57 -25 -114 -49 -131 -57 -8 -4 -107 -47 -219 -95 -112 -48
    -211 -91 -219 -95 -9 -4 -34 -15 -56 -25 -22 -9 -56 -24 -75 -32 -19 -9 -51
    -23 -70 -31 -19 -8 -51 -22 -70 -30 -19 -8 -42 -18 -51 -22 -8 -4 -123 -54
    -254 -110 -131 -56 -246 -106 -254 -110 -9 -4 -32 -14 -51 -22 -19 -8 -52 -22
    -72 -31 -21 -9 -55 -25 -75 -34 -21 -9 -51 -22 -68 -29 -16 -7 -59 -26 -95
    -42 -109 -49 -123 -49 -195 -2 -16 11 -39 24 -50 30 -11 6 -33 19 -50 30 -16
    11 -39 24 -50 30 -11 6 -33 19 -50 30 -16 11 -37 23 -45 27 -17 8 -258 148
    -280 163 -8 6 -22 13 -30 17 -8 4 -36 19 -61 34 -55 32 -197 112 -289 162 -25
    14 -74 41 -110 61 -36 20 -87 48 -115 62 -27 14 -79 41 -115 61 -275 149 -898
    459 -1220 606 -30 13 -86 39 -125 57 -38 17 -90 41 -115 52 -25 10 -52 23 -61
    28 -9 4 -61 27 -115 50 -54 23 -103 46 -109 51 -5 5 -15 9 -22 9 -7 0 -43 14
    -80 31 -113 50 -124 55 -243 104 -63 26 -128 53 -145 60 -16 7 -65 27 -107 44
    -43 17 -113 45 -155 62 -43 17 -95 37 -115 45 -21 8 -55 20 -75 28 -21 8 -74
    28 -118 46 -77 31 -109 43 -185 69 -49 17 -155 56 -220 81 -30 12 -86 32 -125
    45 -38 13 -135 47 -215 75 -80 28 -158 55 -175 60 -29 8 -59 18 -135 45 -19 7
    -82 27 -140 45 -58 18 -121 38 -140 45 -19 7 -62 20 -95 30 -33 10 -78 23
    -100 30 -22 7 -67 21 -100 30 -33 10 -78 24 -100 30 -22 7 -67 21 -100 30 -33
    10 -78 23 -100 30 -22 7 -69 20 -105 30 -36 9 -85 23 -110 30 -94 27 -246 69
    -330 91 -36 9 -87 22 -115 30 -27 7 -78 20 -112 28 -321 81 -302 74 -323 120
    -20 45 -73 177 -97 241 -8 22 -21 54 -28 70 -7 17 -19 48 -28 70 -8 22 -29 76
    -47 120 -18 44 -39 98 -47 120 -8 22 -21 54 -28 70 -7 17 -19 48 -28 70 -8 22
    -29 76 -47 120 -18 44 -39 98 -47 120 -9 22 -21 54 -28 70 -7 17 -20 48 -28
    70 -15 39 -41 106 -107 270 -18 44 -39 98 -47 120 -8 22 -21 54 -28 70 -7 17
    -19 48 -28 70 -8 22 -29 76 -47 120 -18 44 -38 96 -45 115 -7 19 -27 71 -45
    115 -18 44 -39 98 -47 120 -9 22 -21 54 -28 70 -7 17 -20 48 -28 70 -8 22 -29
    75 -46 118 -17 42 -45 112 -62 155 -17 42 -38 95 -46 117 -8 22 -21 54 -28 70
    -7 17 -19 48 -28 70 -8 22 -29 76 -47 120 -18 44 -39 98 -47 120 -8 22 -21 54
    -28 70 -7 17 -20 49 -29 73 -9 23 -23 61 -32 85 -9 23 -22 56 -29 72 -7 17
    -20 48 -28 70 -15 39 -41 106 -107 270 -18 44 -39 98 -47 120 -8 22 -21 54
    -28 70 -7 17 -19 48 -28 70 -8 22 -29 76 -47 120 -18 44 -39 98 -47 120 -8 22
    -21 53 -29 68 -8 16 -14 34 -14 41 0 7 -11 25 -25 40 l-24 26 -4078 -1 -4078
    0 -22 -26z m5167 -5008 c55 -10 150 -15 270 -15 120 0 215 -5 270 -15 47 -8
    135 -15 195 -15 61 0 148 -7 195 -15 47 -8 121 -15 165 -15 44 0 118 -7 165
    -15 47 -8 112 -15 145 -15 33 0 107 -6 165 -15 101 -14 213 -28 475 -61 549
    -67 1528 -240 2045 -360 655 -153 1328 -332 1797 -479 348 -109 811 -262 973
    -321 96 -36 249 -92 340 -125 341 -126 1063 -421 1310 -537 36 -16 94 -42 130
    -57 36 -15 124 -56 195 -90 72 -35 159 -75 195 -90 87 -36 823 -404 880 -440
    25 -16 113 -63 196 -106 82 -42 180 -95 217 -118 37 -23 69 -41 72 -41 3 0 50
    -27 106 -60 55 -33 103 -60 105 -60 2 0 38 -21 81 -46 43 -25 107 -62 143 -82
    208 -114 709 -428 1174 -733 476 -313 1166 -820 1701 -1249 324 -260 453 -365
    500 -410 28 -26 84 -74 125 -107 41 -34 86 -73 100 -88 14 -15 68 -62 120
    -105 52 -43 120 -104 150 -135 30 -31 98 -92 150 -135 52 -43 160 -144 240
    -225 80 -81 179 -174 220 -207 108 -87 498 -476 590 -588 43 -52 144 -160 225
    -240 81 -80 182 -188 225 -240 43 -52 104 -120 135 -150 31 -30 92 -98 135
    -150 43 -52 90 -106 105 -120 15 -14 54 -59 88 -100 33 -41 81 -97 107 -125
    45 -47 150 -176 410 -500 429 -535 936 -1225 1249 -1701 305 -465 619 -966
    733 -1174 20 -36 57 -100 82 -143 25 -43 46 -79 46 -81 0 -2 27 -50 60 -105
    33 -56 60 -103 60 -106 0 -3 18 -35 41 -72 23 -37 76 -135 118 -217 43 -83 90
    -171 106 -196 36 -57 404 -793 440 -880 15 -36 55 -123 90 -195 34 -71 75
    -159 90 -195 15 -36 41 -94 57 -130 116 -247 411 -969 537 -1310 33 -91 89
    -244 125 -340 59 -162 212 -625 321 -973 147 -469 326 -1142 479 -1797 120
    -518 292 -1491 361 -2047 38 -305 46 -375 60 -473 9 -58 15 -132 15 -165 0
    -33 7 -98 15 -145 8 -47 15 -121 15 -165 0 -44 7 -118 15 -165 8 -47 15 -134
    15 -195 0 -60 7 -148 15 -195 10 -55 15 -150 15 -270 0 -120 5 -215 15 -270
    22 -127 22 -2003 0 -2130 -10 -55 -15 -150 -15 -270 0 -120 -5 -215 -15 -270
    -8 -47 -15 -134 -15 -195 0 -60 -7 -148 -15 -195 -8 -47 -15 -121 -15 -165 0
    -44 -7 -118 -15 -165 -8 -47 -15 -112 -15 -145 0 -33 -6 -107 -15 -165 -14
    -101 -28 -213 -61 -475 -67 -549 -240 -1528 -360 -2045 -153 -655 -332 -1328
    -479 -1797 -109 -348 -262 -811 -321 -973 -36 -96 -92 -249 -125 -340 -126
    -341 -421 -1063 -537 -1310 -16 -36 -42 -94 -57 -130 -15 -36 -56 -123 -90
    -195 -35 -71 -75 -159 -90 -195 -36 -87 -404 -823 -440 -880 -16 -25 -63 -113
    -106 -196 -42 -82 -95 -180 -118 -217 -23 -37 -41 -69 -41 -72 0 -3 -27 -50
    -60 -106 -33 -55 -60 -103 -60 -105 0 -2 -21 -38 -46 -81 -25 -43 -62 -107
    -82 -143 -114 -208 -428 -709 -733 -1174 -313 -476 -820 -1166 -1249 -1701
    -260 -324 -365 -453 -410 -500 -26 -27 -74 -84 -107 -125 -34 -41 -73 -86 -88
    -100 -15 -14 -62 -68 -105 -120 -43 -52 -104 -120 -135 -150 -31 -30 -92 -98
    -135 -150 -43 -52 -144 -160 -225 -240 -81 -80 -174 -179 -207 -220 -87 -108
    -476 -498 -588 -590 -52 -43 -160 -144 -240 -225 -80 -81 -188 -182 -240 -225
    -52 -43 -120 -104 -150 -135 -30 -31 -98 -92 -150 -135 -52 -43 -106 -90 -120
    -105 -14 -15 -59 -54 -100 -88 -41 -33 -97 -81 -125 -107 -47 -45 -176 -150
    -500 -410 -535 -429 -1225 -936 -1701 -1249 -465 -305 -966 -619 -1174 -733
    -36 -20 -100 -57 -143 -82 -43 -25 -79 -46 -81 -46 -2 0 -50 -27 -105 -60 -56
    -33 -103 -60 -106 -60 -3 0 -35 -18 -72 -41 -37 -23 -135 -76 -217 -118 -83
    -43 -171 -90 -196 -106 -57 -36 -793 -404 -880 -440 -36 -15 -123 -55 -195
    -90 -71 -34 -159 -75 -195 -90 -36 -15 -94 -41 -130 -57 -247 -116 -969 -411
    -1310 -537 -91 -33 -244 -89 -340 -125 -162 -59 -625 -212 -973 -321 -469
    -147 -1142 -326 -1797 -479 -517 -120 -1496 -293 -2045 -360 -262 -33 -374
    -47 -475 -61 -58 -9 -132 -15 -165 -15 -33 0 -98 -7 -145 -15 -47 -8 -121 -15
    -165 -15 -44 0 -118 -7 -165 -15 -47 -8 -134 -15 -195 -15 -60 0 -148 -7 -195
    -15 -55 -10 -150 -15 -270 -15 -120 0 -215 -5 -270 -15 -127 -22 -2003 -22
    -2130 0 -55 10 -150 15 -270 15 -120 0 -215 5 -270 15 -47 8 -134 15 -195 15
    -60 0 -148 7 -195 15 -47 8 -121 15 -165 15 -44 0 -118 7 -165 15 -47 8 -112
    15 -145 15 -33 0 -107 6 -165 15 -58 8 -159 21 -225 29 -66 9 -176 22 -245 31
    -564 70 -1531 241 -2050 361 -655 153 -1328 332 -1797 479 -348 109 -811 262
    -973 321 -96 36 -249 92 -340 125 -341 126 -1063 421 -1310 537 -36 16 -94 42
    -130 57 -36 15 -123 56 -195 90 -71 35 -159 75 -195 90 -87 36 -823 404 -880
    440 -25 16 -113 63 -196 106 -82 42 -180 95 -217 118 -37 23 -69 41 -72 41 -3
    0 -50 27 -106 60 -55 33 -103 60 -105 60 -2 0 -38 21 -81 46 -43 25 -107 62
    -143 82 -208 114 -709 428 -1174 733 -476 313 -1166 820 -1701 1249 -324 260
    -453 365 -500 410 -27 26 -84 74 -125 107 -41 34 -86 73 -100 88 -14 15 -68
    62 -120 105 -52 43 -120 104 -150 135 -30 31 -98 92 -150 135 -52 43 -160 144
    -240 225 -80 81 -188 182 -240 225 -112 92 -501 482 -588 590 -33 41 -126 140
    -207 220 -81 80 -182 188 -225 240 -43 52 -104 120 -135 150 -31 30 -92 98
    -135 150 -43 52 -90 106 -105 120 -15 14 -54 59 -88 100 -33 41 -81 98 -107
    125 -45 47 -150 176 -410 500 -429 535 -936 1225 -1249 1701 -305 465 -619
    966 -733 1174 -20 36 -57 100 -82 143 -25 43 -46 79 -46 81 0 2 -27 50 -60
    105 -33 56 -60 103 -60 106 0 3 -18 35 -41 72 -23 37 -76 135 -118 217 -43 83
    -90 171 -106 196 -36 57 -404 793 -440 880 -15 36 -55 124 -90 195 -34 72 -75
    159 -90 195 -15 36 -41 94 -57 130 -116 247 -411 969 -537 1310 -33 91 -89
    244 -125 340 -59 162 -212 625 -321 973 -147 469 -326 1142 -479 1797 -120
    518 -292 1491 -361 2048 -38 304 -46 374 -60 472 -9 58 -15 132 -15 165 0 33
    -7 98 -15 145 -8 47 -15 121 -15 165 0 44 -7 118 -15 165 -8 47 -15 135 -15
    195 0 61 -7 148 -15 195 -10 55 -15 150 -15 270 0 120 -5 215 -15 270 -22 127
    -22 2003 0 2130 10 55 15 150 15 270 0 120 5 215 15 270 8 47 15 135 15 195 0
    61 7 148 15 195 8 47 15 121 15 165 0 44 7 118 15 165 8 47 15 112 15 145 0
    33 6 107 15 165 14 98 22 168 60 473 69 556 241 1529 361 2047 153 655 332
    1328 479 1797 109 348 262 811 321 973 36 96 92 249 125 340 126 341 421 1063
    537 1310 16 36 42 94 57 130 15 36 56 124 90 195 35 72 75 159 90 195 36 87
    404 823 440 880 16 25 63 113 106 196 42 82 95 180 118 217 23 37 41 69 41 72
    0 3 27 50 60 106 33 55 60 103 60 105 0 2 21 38 46 81 25 43 62 107 82 143
    114 208 428 709 733 1174 313 476 820 1166 1249 1701 260 324 365 453 410 500
    26 28 74 84 107 125 34 41 73 86 88 100 15 14 62 68 105 120 43 52 104 120
    135 150 31 30 92 98 135 150 43 52 144 160 225 240 81 80 182 188 225 240 92
    112 482 501 590 588 41 33 140 126 220 207 80 81 188 182 240 225 52 43 120
    104 150 135 30 31 98 92 150 135 52 43 106 90 120 105 14 15 59 54 100 88 41
    33 98 81 125 107 47 45 176 150 500 410 535 429 1225 936 1701 1249 465 305
    966 619 1174 733 36 20 100 57 143 82 43 25 79 46 81 46 2 0 50 27 105 60 56
    33 103 60 106 60 3 0 35 18 72 41 37 23 135 76 217 118 83 43 171 90 196 106
    57 36 793 404 880 440 36 15 124 55 195 90 72 34 159 75 195 90 36 15 94 41
    130 57 247 116 969 411 1310 537 91 33 244 89 340 125 162 59 625 212 973 321
    469 147 1142 326 1797 479 519 120 1486 291 2050 361 69 9 179 22 245 31 66 8
    167 21 225 29 58 9 132 15 165 15 33 0 98 7 145 15 47 8 121 15 165 15 44 0
    118 7 165 15 47 8 135 15 195 15 61 0 148 7 195 15 54 10 154 15 275 16 113 0
    218 6 260 13 110 21 2017 22 2135 1z"/>
    <path d="M30175 53694 c-48 -9 -167 -13 -380 -13 -228 -1 -332 -5 -395 -16
    -48 -9 -145 -15 -225 -15 -80 0 -177 -6 -225 -15 -47 -8 -128 -15 -180 -15
    -52 0 -133 -7 -180 -15 -47 -8 -114 -15 -150 -15 -36 0 -103 -7 -150 -15 -47
    -8 -107 -15 -135 -15 -27 0 -88 -7 -135 -15 -47 -8 -107 -15 -134 -15 -27 0
    -90 -6 -140 -14 -91 -15 -199 -30 -421 -62 -409 -57 -1161 -196 -1630 -300
    -425 -94 -1191 -295 -1675 -439 -269 -80 -1021 -334 -1320 -446 -518 -193
    -1149 -452 -1415 -579 -55 -26 -143 -66 -195 -88 -128 -55 -1305 -646 -1403
    -705 -43 -26 -80 -47 -82 -47 -3 0 -34 -18 -69 -40 -36 -21 -102 -59 -148 -83
    -46 -24 -156 -87 -245 -140 -90 -53 -165 -97 -168 -97 -5 0 -184 -109 -385
    -235 -69 -43 -172 -107 -230 -143 -312 -193 -1031 -691 -1439 -997 -238 -178
    -807 -630 -998 -792 -51 -43 -127 -107 -170 -143 -43 -36 -109 -95 -148 -131
    -38 -36 -104 -93 -145 -126 -41 -34 -100 -87 -130 -118 -30 -31 -89 -84 -130
    -117 -41 -34 -201 -186 -355 -338 -154 -152 -309 -299 -345 -327 -36 -28 -88
    -80 -115 -115 -26 -34 -173 -189 -325 -343 -152 -154 -304 -314 -338 -355 -33
    -41 -86 -100 -117 -130 -31 -30 -84 -89 -118 -130 -33 -41 -90 -106 -126 -145
    -36 -38 -95 -105 -131 -148 -36 -43 -100 -119 -143 -170 -162 -191 -614 -760
    -792 -998 -306 -408 -804 -1127 -997 -1439 -36 -58 -100 -161 -143 -230 -151
    -241 -202 -326 -240 -395 -21 -38 -76 -132 -122 -208 -46 -76 -83 -140 -83
    -142 0 -3 -21 -39 -46 -82 -26 -43 -69 -121 -97 -173 -27 -52 -62 -113 -76
    -135 -37 -58 -635 -1253 -683 -1365 -22 -52 -62 -140 -88 -195 -127 -266 -386
    -897 -579 -1415 -112 -299 -366 -1051 -446 -1320 -144 -484 -345 -1250 -439
    -1675 -104 -469 -243 -1221 -300 -1630 -32 -222 -47 -330 -62 -421 -8 -50 -14
    -113 -14 -140 0 -27 -7 -87 -15 -134 -8 -47 -15 -107 -15 -135 0 -27 -7 -88
    -15 -135 -8 -47 -15 -114 -15 -150 0 -36 -7 -103 -15 -150 -8 -47 -15 -134
    -15 -195 0 -60 -7 -148 -15 -195 -9 -51 -15 -147 -15 -240 0 -93 -6 -189 -15
    -240 -11 -62 -15 -160 -15 -360 0 -200 -4 -298 -15 -360 -22 -123 -22 -927 0
    -1050 11 -63 15 -164 15 -390 0 -226 4 -327 15 -390 9 -48 15 -145 15 -225 0
    -80 6 -177 15 -225 8 -47 15 -128 15 -180 0 -52 7 -133 15 -180 8 -47 15 -114
    15 -150 0 -36 7 -103 15 -150 8 -47 15 -107 15 -135 0 -27 7 -88 15 -135 8
    -47 15 -107 15 -134 0 -27 6 -90 14 -140 15 -91 30 -199 62 -421 57 -409 196
    -1161 300 -1630 94 -425 295 -1191 439 -1675 80 -269 334 -1021 446 -1320 193
    -518 452 -1149 579 -1415 26 -55 66 -143 88 -195 55 -128 646 -1305 705 -1403
    26 -43 47 -80 47 -82 0 -3 18 -34 40 -69 21 -36 59 -102 83 -148 24 -46 87
    -156 140 -245 53 -90 97 -165 97 -168 0 -5 109 -184 235 -385 43 -69 107 -172
    143 -230 193 -312 691 -1031 997 -1439 178 -238 630 -807 792 -998 43 -51 107
    -127 143 -170 36 -43 95 -109 131 -148 36 -38 93 -104 126 -145 34 -41 87
    -100 118 -130 31 -30 84 -89 117 -130 34 -41 186 -201 338 -355 152 -154 299
    -309 325 -343 27 -35 79 -87 115 -115 36 -28 191 -175 345 -327 154 -152 314
    -304 355 -338 41 -33 100 -86 130 -117 30 -31 89 -84 130 -118 41 -33 107 -90
    145 -126 39 -36 105 -95 148 -131 43 -36 119 -100 170 -143 191 -162 760 -614
    998 -792 408 -306 1127 -804 1439 -997 58 -36 161 -100 230 -143 241 -151 326
    -202 395 -240 39 -21 132 -76 208 -122 76 -46 140 -83 142 -83 3 0 39 -21 82
    -46 43 -26 121 -69 173 -97 52 -27 113 -62 135 -76 58 -37 1253 -635 1365
    -683 52 -22 140 -62 195 -88 266 -127 897 -386 1415 -579 299 -112 1051 -366
    1320 -446 484 -144 1250 -345 1675 -439 469 -104 1221 -243 1630 -300 222 -32
    330 -47 421 -62 50 -8 113 -14 140 -14 27 0 87 -7 134 -15 47 -8 108 -15 135
    -15 28 0 88 -7 135 -15 47 -8 114 -15 150 -15 36 0 103 -7 150 -15 47 -8 128
    -15 180 -15 52 0 133 -7 180 -15 48 -9 145 -15 225 -15 80 0 177 -6 225 -15
    63 -11 164 -15 390 -15 226 0 327 -4 390 -15 123 -22 927 -22 1050 0 63 11
    164 15 390 15 226 0 327 4 390 15 48 9 145 15 225 15 80 0 177 6 225 15 47 8
    128 15 180 15 52 0 133 7 180 15 47 8 114 15 150 15 36 0 103 7 150 15 47 8
    108 15 135 15 28 0 88 7 135 15 47 8 107 15 134 15 27 0 90 6 140 14 91 15
    199 30 421 62 409 57 1161 196 1630 300 425 94 1191 295 1675 439 269 80 1021
    334 1320 446 518 193 1149 452 1415 579 55 26 143 66 195 88 112 48 1307 646
    1365 683 22 14 83 49 135 76 52 28 130 71 173 97 43 25 79 46 82 46 2 0 66 37
    142 83 76 46 170 101 208 122 69 38 154 89 395 240 69 43 172 107 230 143 312
    193 1031 691 1439 997 238 178 807 630 998 792 51 43 127 107 170 143 43 36
    110 95 148 131 39 36 104 93 145 126 41 34 100 87 130 118 30 31 89 84 130
    117 41 34 201 186 355 338 154 152 309 299 345 327 36 28 88 80 115 115 26 34
    173 189 325 343 152 154 304 314 338 355 33 41 86 100 117 130 31 30 84 89
    118 130 33 41 90 107 126 145 36 39 95 105 131 148 36 43 100 119 143 170 162
    191 614 760 792 998 306 408 804 1127 997 1439 36 58 100 161 143 230 126 201
    235 380 235 385 0 3 44 78 97 168 53 89 116 199 140 245 24 46 62 112 83 148
    22 35 40 66 40 69 0 2 21 39 47 82 59 98 650 1275 705 1403 22 52 62 140 88
    195 127 266 386 897 579 1415 112 299 366 1051 446 1320 144 484 345 1250 439
    1675 100 452 221 1097 270 1450 17 121 36 250 61 425 8 52 21 149 30 215 9 66
    22 167 30 225 9 58 15 125 15 150 0 25 7 83 15 130 8 47 15 114 15 150 0 36 7
    103 15 150 8 47 15 128 15 180 0 52 7 133 15 180 9 48 15 145 15 225 0 80 6
    177 15 225 12 66 15 172 15 465 0 293 3 399 15 465 21 120 21 630 0 750 -12
    66 -15 172 -15 465 0 293 -3 399 -15 465 -9 48 -15 145 -15 225 0 80 -6 177
    -15 225 -8 47 -15 128 -15 180 0 52 -7 133 -15 180 -8 47 -15 114 -15 150 0
    36 -7 103 -15 150 -8 47 -15 108 -15 135 0 28 -7 88 -15 135 -8 47 -15 107
    -15 134 0 27 -6 90 -14 140 -15 91 -30 199 -62 421 -57 409 -196 1161 -300
    1630 -94 425 -295 1191 -439 1675 -80 269 -334 1021 -446 1320 -193 518 -452
    1149 -579 1415 -26 55 -66 143 -88 195 -48 112 -646 1307 -683 1365 -14 22
    -49 83 -76 135 -28 52 -71 130 -97 173 -25 43 -46 79 -46 82 0 2 -37 66 -83
    142 -46 76 -101 170 -122 208 -38 69 -89 154 -240 395 -43 69 -107 172 -143
    230 -193 312 -691 1031 -997 1439 -178 238 -630 807 -792 998 -43 51 -107 127
    -143 170 -36 43 -95 110 -131 148 -36 39 -93 104 -126 145 -34 41 -87 100
    -118 130 -31 30 -84 89 -117 130 -34 41 -186 201 -338 355 -152 154 -299 309
    -325 343 -27 35 -79 87 -115 115 -36 28 -191 175 -345 327 -154 152 -314 304
    -355 338 -41 33 -100 86 -130 117 -30 31 -89 84 -130 118 -41 33 -106 90 -145
    126 -38 36 -105 95 -148 131 -43 36 -119 100 -170 143 -191 162 -760 614 -998
    792 -408 306 -1127 804 -1439 997 -58 36 -161 100 -230 143 -201 126 -380 235
    -385 235 -3 0 -78 44 -168 97 -89 53 -199 116 -245 140 -46 24 -112 62 -148
    83 -35 22 -66 40 -69 40 -2 0 -39 21 -82 47 -98 59 -1275 650 -1403 705 -52
    22 -140 62 -195 88 -266 127 -897 386 -1415 579 -299 112 -1051 366 -1320 446
    -484 144 -1250 345 -1675 439 -469 104 -1221 243 -1630 300 -222 32 -330 47
    -421 62 -50 8 -113 14 -140 14 -27 0 -87 7 -134 15 -47 8 -107 15 -135 15 -27
    0 -88 7 -135 15 -47 8 -114 15 -150 15 -36 0 -103 7 -150 15 -47 8 -128 15
    -180 15 -52 0 -133 7 -180 15 -48 9 -145 15 -225 15 -80 0 -177 6 -225 15 -63
    11 -164 15 -390 15 -226 0 -327 4 -390 15 -115 20 -948 20 -1055 -1z m1115
    -1569 c102 -8 264 -22 360 -30 96 -9 198 -15 225 -15 99 1 466 -47 595 -77
    182 -43 309 -109 413 -215 143 -147 205 -305 193 -493 -9 -148 -25 -207 -91
    -319 -47 -80 -120 -158 -195 -207 -64 -42 -183 -99 -206 -99 -8 0 -24 -5 -35
    -11 -11 -5 -44 -14 -73 -19 -29 -5 -69 -15 -89 -24 l-36 -15 62 -101 c34 -55
    70 -113 79 -128 10 -15 22 -36 28 -47 6 -11 18 -32 28 -47 23 -37 254 -413
    270 -441 7 -12 21 -34 30 -49 9 -15 35 -55 56 -90 94 -150 220 -292 295 -332
    131 -69 187 -91 283 -111 29 -6 46 -40 34 -70 -7 -19 -45 -19 -261 0 -93 8
    -231 19 -305 25 -74 6 -145 13 -156 16 -15 4 -59 67 -140 200 -66 107 -126
    206 -134 219 -8 13 -62 101 -120 195 -58 94 -113 183 -122 198 -9 15 -23 37
    -30 49 -15 26 -185 301 -210 341 -10 15 -22 36 -28 47 -58 110 -54 107 -207
    122 -75 7 -140 10 -144 6 -7 -7 -15 -83 -54 -553 -9 -102 -15 -258 -15 -348 0
    -182 5 -201 67 -255 49 -43 81 -56 193 -75 l95 -17 0 -30 c0 -28 -3 -30 -40
    -33 -22 -2 -128 3 -235 12 -218 18 -487 40 -720 57 -157 12 -190 19 -190 43 0
    29 30 39 111 37 132 -4 240 46 276 128 22 51 52 214 52 286 1 33 7 137 16 230
    8 94 22 261 30 373 26 343 45 599 60 797 37 486 39 520 25 602 -12 70 -17 82
    -52 115 -54 53 -85 66 -197 87 -54 10 -102 21 -108 24 -6 4 -8 18 -5 32 4 24
    8 25 71 25 36 0 149 -6 251 -15z m-2512 -47 c11 -18 44 -79 73 -136 91 -174
    143 -273 204 -387 32 -60 78 -148 103 -195 56 -108 123 -235 197 -375 32 -60
    79 -148 103 -195 53 -103 119 -228 197 -375 32 -60 78 -148 103 -195 24 -47
    58 -112 76 -145 18 -33 50 -94 71 -135 178 -339 264 -425 455 -451 41 -6 79
    -14 83 -19 5 -4 7 -20 5 -36 l-3 -29 -125 -12 c-69 -7 -206 -19 -305 -28 -99
    -9 -245 -22 -325 -30 -230 -23 -310 -23 -324 -2 -18 29 5 50 75 67 84 22 119
    41 149 81 20 28 24 44 23 99 -1 80 -24 134 -173 415 -29 55 -65 123 -78 150
    -47 91 -46 91 -164 82 -57 -4 -164 -14 -238 -22 -74 -9 -160 -15 -190 -15 -30
    0 -84 -5 -120 -10 -36 -6 -134 -15 -218 -20 -84 -6 -163 -15 -175 -20 -18 -7
    -35 -50 -100 -252 -43 -133 -85 -263 -93 -288 -8 -25 -14 -81 -14 -124 l0 -79
    40 -37 c43 -39 85 -52 220 -68 64 -7 65 -8 65 -36 0 -36 -16 -42 -142 -50
    -124 -9 -403 -34 -567 -51 -81 -9 -143 -12 -149 -6 -4 4 -7 23 -5 42 2 28 8
    34 33 40 167 40 222 106 341 409 7 19 34 100 60 180 25 80 52 160 60 178 8 18
    14 39 14 47 0 8 6 29 14 47 8 18 35 101 61 183 26 83 53 165 61 183 8 18 14
    39 14 47 0 8 6 29 14 47 8 18 35 101 61 183 26 83 53 165 61 183 8 18 14 39
    14 47 0 8 6 29 14 47 8 18 35 101 61 183 26 83 53 165 61 183 8 18 14 39 14
    47 0 8 6 29 14 47 8 18 35 101 61 183 26 83 53 165 61 183 8 18 14 39 14 47 0
    8 6 29 14 47 8 18 22 60 33 92 10 33 24 65 31 73 24 24 59 15 80 -19z m5736
    -252 c56 -16 139 -37 361 -92 33 -9 83 -22 111 -30 28 -8 59 -14 69 -14 10 0
    41 -6 69 -14 28 -8 78 -21 111 -30 259 -64 295 -75 302 -93 3 -8 -3 -23 -13
    -33 -18 -16 -24 -16 -99 -3 -88 16 -184 6 -235 -23 -45 -27 -95 -113 -116
    -199 -3 -11 -11 -42 -19 -70 -8 -27 -21 -79 -30 -115 -9 -36 -23 -88 -31 -116
    -8 -28 -14 -59 -14 -69 0 -10 -6 -41 -14 -69 -8 -28 -22 -79 -30 -113 -9 -35
    -23 -90 -31 -123 -8 -33 -22 -86 -30 -117 -25 -103 -27 -123 -7 -122 9 0 31 9
    47 19 17 10 48 28 70 40 22 12 64 35 93 51 28 16 77 43 107 59 115 63 169 93
    217 121 27 16 51 29 53 29 9 0 365 215 453 274 182 121 242 187 242 266 0 38
    -5 52 -31 78 -17 17 -45 36 -62 41 -93 28 -112 39 -109 62 5 42 32 45 147 14
    49 -13 118 -31 240 -62 116 -29 171 -42 240 -58 39 -9 96 -23 128 -30 31 -8
    85 -22 120 -31 110 -27 112 -28 115 -53 6 -40 -11 -46 -146 -47 -126 0 -188
    -12 -264 -50 -14 -8 -31 -14 -37 -14 -10 0 -186 -85 -236 -113 -45 -27 -139
    -83 -164 -99 -14 -10 -35 -22 -46 -28 -11 -6 -32 -18 -47 -28 -57 -36 -272
    -168 -311 -191 -23 -13 -60 -36 -82 -50 -22 -14 -56 -34 -75 -44 -19 -11 -54
    -32 -78 -48 -24 -16 -45 -29 -47 -29 -9 0 -111 -64 -132 -83 -18 -16 -20 -23
    -11 -35 12 -14 28 -40 48 -77 6 -11 18 -32 28 -47 22 -35 343 -560 360 -591 7
    -12 22 -35 32 -52 11 -16 24 -39 30 -50 6 -11 19 -33 30 -50 11 -16 24 -39 30
    -50 6 -11 18 -32 28 -47 9 -15 50 -80 90 -145 183 -299 246 -386 357 -495 80
    -80 168 -140 290 -200 55 -27 69 -39 67 -54 -5 -31 -28 -42 -70 -34 -51 10
    -128 30 -181 46 -25 8 -53 14 -64 14 -11 0 -43 6 -71 14 -49 14 -118 32 -238
    62 -32 8 -85 21 -118 29 -33 8 -87 22 -120 30 -33 8 -86 22 -117 29 -32 8 -92
    23 -133 32 -99 22 -169 44 -175 54 -3 5 -1 20 5 34 9 26 12 26 72 21 54 -6 70
    -3 108 17 58 30 72 57 67 134 -4 57 -12 72 -133 272 -71 117 -135 222 -142
    235 -7 12 -22 36 -32 52 -11 17 -24 39 -30 50 -6 11 -18 32 -28 47 -17 26
    -151 244 -175 283 -7 11 -23 38 -37 60 -14 22 -29 47 -33 55 -4 8 -15 27 -25
    42 -9 15 -43 70 -75 122 -32 53 -62 96 -66 96 -15 0 -31 -26 -36 -61 -3 -19
    -13 -60 -20 -91 -39 -152 -48 -187 -61 -240 -8 -32 -21 -85 -29 -118 -8 -33
    -22 -87 -30 -120 -8 -33 -21 -86 -29 -117 -8 -32 -19 -78 -25 -103 -39 -154
    -44 -257 -17 -313 32 -65 139 -135 237 -156 49 -11 59 -18 59 -47 0 -43 -26
    -42 -205 2 -33 9 -86 22 -117 30 -74 18 -161 40 -240 60 -35 8 -90 22 -123 30
    -33 9 -83 22 -111 30 -28 8 -59 14 -69 14 -10 0 -42 7 -72 15 -76 21 -154 42
    -185 49 -21 5 -28 12 -28 29 0 44 13 52 65 37 77 -21 212 -18 260 7 89 47 116
    108 210 493 7 30 21 84 30 120 26 101 42 169 60 245 9 39 22 95 30 125 7 30
    20 82 28 115 9 33 23 88 31 123 9 34 23 90 32 125 8 34 22 85 30 113 8 28 14
    59 14 69 0 10 6 41 14 69 8 28 21 78 30 111 8 33 22 88 30 123 19 74 41 161
    60 240 8 31 22 101 32 154 17 93 17 98 -1 138 -10 23 -36 59 -58 81 -40 40
    -125 89 -155 89 -35 1 -102 39 -102 58 0 10 3 22 7 25 11 12 73 8 127 -7z
    m-7979 -191 c258 -47 467 -202 561 -415 32 -72 54 -145 54 -181 0 -19 5 -53
    10 -76 17 -73 -12 -276 -50 -354 -62 -125 -120 -198 -219 -277 -65 -53 -207
    -126 -296 -154 -133 -42 -345 -78 -461 -78 -49 0 -95 -4 -103 -9 -15 -10 -14
    -22 25 -181 13 -54 28 -120 59 -255 16 -67 29 -122 55 -230 7 -27 16 -71 21
    -97 4 -26 12 -56 18 -67 6 -11 11 -29 11 -40 0 -31 58 -161 87 -197 10 -12 37
    -31 60 -43 51 -25 174 -29 245 -7 55 16 78 6 78 -34 0 -26 -4 -30 -37 -35 -21
    -3 -63 -12 -93 -20 -30 -7 -84 -20 -120 -29 -159 -36 -235 -55 -365 -86 -27
    -7 -68 -16 -90 -20 -22 -4 -62 -13 -90 -20 -27 -6 -75 -18 -105 -25 -30 -7
    -77 -19 -105 -25 -135 -33 -155 -31 -155 17 0 18 10 24 54 37 30 9 59 16 65
    16 21 0 89 39 127 72 43 38 74 104 74 156 0 20 -7 75 -14 122 -14 81 -21 117
    -51 240 -8 30 -20 84 -28 120 -9 36 -23 97 -32 135 -9 39 -23 99 -31 135 -15
    64 -30 126 -54 225 -7 28 -16 68 -20 90 -4 22 -13 63 -20 90 -6 28 -18 75 -25
    105 -7 30 -18 78 -25 105 -7 28 -19 79 -27 115 -8 36 -18 81 -23 100 -18 78
    -41 178 -54 235 -25 109 -38 160 -49 195 -5 19 -14 51 -18 70 -4 19 -14 54
    -23 76 -56 153 -139 193 -337 164 -77 -11 -84 -10 -96 6 -33 45 -34 45 337
    130 64 15 124 30 225 54 28 7 68 16 90 20 22 4 63 13 90 20 28 6 75 18 105 25
    58 15 146 34 270 59 171 36 369 44 495 21z m11463 -768 c15 -8 44 -21 65 -30
    61 -27 105 -46 140 -60 17 -7 48 -21 67 -29 19 -9 166 -71 325 -138 160 -67
    304 -128 320 -136 17 -7 60 -27 98 -43 37 -17 71 -31 76 -31 5 0 22 -6 38 -14
    26 -13 51 -24 138 -61 19 -8 51 -21 70 -29 20 -9 51 -22 70 -30 20 -9 50 -22
    68 -30 17 -8 121 -52 230 -97 112 -48 199 -90 202 -99 5 -17 -3 -48 -35 -130
    -12 -30 -25 -66 -30 -80 -5 -14 -18 -50 -30 -80 -11 -30 -32 -86 -45 -125 -29
    -86 -53 -145 -61 -145 -3 0 -19 7 -36 16 -25 13 -29 20 -24 42 14 62 26 221
    20 266 -14 107 -75 192 -182 253 -37 21 -182 87 -322 147 -140 59 -270 115
    -287 122 -18 8 -48 21 -68 30 -19 8 -50 21 -70 30 -19 8 -51 22 -70 32 -36 17
    -36 17 -56 -8 -10 -14 -22 -36 -25 -50 -4 -14 -12 -36 -19 -50 -7 -14 -19 -41
    -27 -60 -15 -37 -38 -89 -62 -143 -110 -249 -286 -679 -281 -687 3 -5 13 -10
    22 -10 8 0 27 -6 41 -13 15 -8 44 -21 65 -30 20 -9 52 -23 70 -31 66 -29 403
    -168 437 -181 143 -52 230 -46 321 22 43 32 119 122 119 140 0 5 10 21 23 37
    17 21 28 27 44 22 41 -13 34 -45 -53 -243 -8 -18 -21 -47 -28 -65 -24 -59 -42
    -102 -60 -143 -10 -22 -25 -56 -33 -75 -8 -19 -21 -48 -28 -65 -7 -16 -36 -86
    -65 -155 -59 -141 -74 -162 -107 -149 -28 10 -30 43 -7 118 22 75 21 201 -2
    243 -60 107 -128 151 -434 278 -69 29 -140 59 -157 66 -41 18 -93 40 -135 58
    -18 8 -45 20 -60 26 -31 15 -53 3 -63 -33 -4 -12 -14 -38 -22 -57 -9 -19 -23
    -51 -31 -70 -8 -19 -21 -51 -29 -70 -32 -75 -43 -101 -57 -133 -11 -26 -186
    -442 -244 -581 -9 -21 -16 -65 -17 -97 0 -95 15 -105 440 -284 371 -156 383
    -160 515 -160 84 0 118 5 169 23 114 41 247 120 362 214 45 38 63 44 93 32 41
    -16 9 -62 -194 -279 -25 -27 -85 -94 -132 -147 -47 -53 -95 -106 -106 -118
    l-20 -22 -219 92 c-120 51 -234 99 -253 108 -37 16 -84 36 -137 59 -18 7 -48
    20 -68 29 -19 8 -51 21 -70 29 -35 15 -80 34 -145 62 -19 8 -50 21 -68 29 -18
    7 -47 20 -65 28 -17 8 -210 89 -427 181 -217 92 -407 173 -422 181 -14 8 -32
    14 -39 14 -19 0 -49 31 -49 51 0 24 35 43 58 30 101 -54 248 -67 317 -28 69
    39 101 97 250 452 37 88 73 174 80 190 7 17 20 46 28 65 8 19 22 52 31 72 27
    62 71 165 93 218 41 97 63 149 87 203 8 17 75 178 151 357 75 179 141 334 147
    344 6 11 14 31 18 45 5 14 18 51 30 81 12 30 28 85 35 123 12 58 12 73 -1 109
    -8 23 -25 53 -37 66 -36 40 -142 104 -192 116 -38 10 -50 41 -25 66 24 24 24
    24 58 7z m-15587 -339 c6 -23 2 -30 -32 -53 -41 -29 -75 -69 -105 -125 -15
    -29 -18 -57 -18 -175 0 -87 7 -170 16 -220 9 -44 24 -124 32 -177 9 -54 23
    -142 32 -195 8 -54 22 -134 29 -178 7 -44 21 -127 30 -185 9 -58 23 -141 30
    -185 7 -44 21 -125 30 -180 9 -55 22 -138 30 -185 7 -47 21 -130 30 -185 9
    -55 22 -138 30 -185 7 -47 21 -130 30 -185 35 -220 44 -274 60 -370 9 -55 22
    -133 29 -173 l13 -72 -34 -21 c-28 -18 -36 -19 -51 -8 -15 12 -160 138 -188
    164 -24 23 -196 178 -299 271 -60 54 -112 101 -115 105 -3 3 -50 45 -105 94
    -55 49 -105 93 -111 99 -26 25 -101 93 -192 174 -53 47 -119 107 -147 133
    -121 113 -209 188 -217 183 -12 -7 -10 -38 7 -129 8 -44 21 -123 29 -175 8
    -52 22 -142 31 -200 8 -58 22 -145 30 -195 8 -49 21 -137 30 -195 9 -58 22
    -147 30 -197 8 -51 22 -135 30 -185 8 -51 21 -140 30 -198 9 -58 22 -143 31
    -190 22 -128 22 -170 -1 -186 -37 -27 -27 -34 -215 136 -192 174 -298 269
    -344 311 -28 24 -95 85 -150 135 -56 50 -123 111 -150 135 -28 24 -95 85 -150
    135 -56 50 -123 111 -150 135 -28 24 -95 85 -150 135 -56 50 -123 111 -150
    135 -28 24 -95 85 -150 135 -56 50 -121 109 -146 131 -25 22 -99 89 -165 148
    -142 127 -164 145 -229 179 -63 34 -146 35 -233 4 -52 -19 -63 -20 -77 -8 -40
    32 -4 62 199 166 54 27 125 66 250 134 44 24 105 58 137 74 31 17 80 44 110
    60 172 94 176 95 192 77 26 -29 19 -46 -43 -90 -57 -42 -92 -89 -105 -144 -17
    -71 71 -176 384 -457 63 -57 120 -109 126 -114 67 -63 136 -126 219 -201 55
    -49 105 -94 111 -100 6 -5 36 -33 65 -61 30 -27 99 -90 154 -140 55 -49 105
    -93 111 -99 290 -268 282 -262 290 -240 4 10 -1 63 -10 119 -10 55 -24 140
    -32 189 -8 48 -20 126 -28 175 -8 48 -22 134 -31 192 -16 100 -28 175 -59 368
    -8 48 -23 136 -33 195 l-18 109 -105 98 c-209 194 -251 229 -337 284 -100 62
    -208 75 -319 37 -51 -17 -60 -18 -67 -5 -17 30 -2 56 48 83 28 15 86 47 130
    71 44 24 96 52 115 63 19 11 78 42 130 69 89 47 129 69 255 138 143 79 227
    120 245 120 32 0 38 -46 9 -70 -13 -10 -28 -19 -33 -20 -17 0 -85 -62 -105
    -94 -26 -41 -28 -114 -5 -159 43 -83 98 -141 338 -362 60 -55 114 -105 120
    -111 6 -5 56 -50 111 -99 55 -49 105 -93 111 -99 11 -12 161 -148 258 -235 34
    -30 63 -57 66 -61 3 -3 77 -71 165 -150 88 -79 177 -160 198 -180 22 -19 44
    -35 50 -35 15 0 12 59 -8 175 -8 47 -21 130 -30 185 -9 55 -23 136 -30 180 -7
    44 -21 127 -30 185 -9 58 -23 141 -30 185 -7 44 -21 124 -29 178 -9 53 -23
    141 -32 195 -87 541 -106 615 -170 672 -50 44 -91 51 -166 31 -35 -10 -81 -26
    -103 -35 l-39 -18 -23 27 c-13 14 -21 30 -19 34 3 4 38 24 78 45 40 21 100 53
    133 71 33 19 83 45 110 59 28 14 91 48 140 76 50 28 113 62 140 76 28 14 61
    32 75 40 36 20 62 14 71 -18z m18495 -990 c47 -29 96 -60 109 -68 13 -8 79
    -49 145 -90 67 -41 131 -81 143 -88 12 -7 34 -21 49 -30 28 -18 249 -155 282
    -175 149 -91 385 -261 480 -347 45 -40 156 -186 156 -204 0 -7 5 -17 12 -24
    34 -34 78 -216 78 -322 0 -126 -31 -223 -112 -350 -162 -257 -476 -358 -814
    -264 -49 14 -96 26 -104 27 -28 4 -28 -7 25 -678 9 -104 15 -215 15 -245 0
    -74 28 -293 46 -356 8 -28 14 -58 14 -67 0 -16 14 -52 48 -127 26 -56 100
    -150 171 -217 l65 -61 -14 -30 -15 -30 -35 18 c-19 11 -44 24 -55 30 -11 5
    -32 18 -47 28 -25 16 -127 79 -368 228 -58 36 -111 71 -119 79 -9 9 -16 58
    -21 162 -5 81 -13 207 -19 278 -6 72 -18 229 -26 350 -8 121 -22 301 -30 400
    -9 99 -16 209 -16 245 -1 147 -13 173 -104 221 -22 12 -64 37 -93 56 -50 34
    -54 35 -71 19 -16 -14 -89 -127 -149 -229 -7 -12 -21 -34 -30 -49 -9 -14 -44
    -70 -77 -123 -33 -53 -68 -108 -77 -123 -10 -15 -21 -34 -25 -42 -4 -8 -19
    -33 -34 -55 -86 -129 -149 -269 -149 -329 0 -50 34 -116 82 -161 49 -46 103
    -85 117 -85 5 0 17 -10 25 -22 14 -20 14 -24 -5 -45 l-22 -22 -38 22 c-32 19
    -84 51 -254 157 -91 57 -270 167 -288 178 -12 7 -34 21 -49 30 -15 9 -81 51
    -148 92 -67 41 -133 83 -148 92 -15 10 -37 23 -49 29 -28 15 -29 30 -5 59 l18
    23 40 -25 c59 -37 123 -66 176 -79 40 -9 56 -9 100 6 29 9 68 31 86 48 34 32
    122 141 122 151 0 2 19 33 43 68 24 35 46 70 50 78 4 8 16 29 27 45 11 17 24
    39 30 50 6 11 46 76 89 145 44 69 112 178 153 243 40 65 81 131 90 145 10 15
    22 36 28 47 6 11 18 32 28 47 9 14 44 70 77 123 33 53 67 107 75 120 57 91
    167 270 178 288 7 12 21 34 30 49 14 22 60 97 129 213 7 11 15 27 18 35 4 8
    12 26 20 40 7 14 18 48 25 76 11 43 11 57 -4 101 -21 65 -78 129 -156 179 -43
    27 -60 44 -60 59 0 51 32 47 136 -17z m-16856 -1053 c57 -29 91 -71 114 -138
    20 -60 20 -71 -1 -130 -67 -186 -295 -210 -405 -44 -24 36 -28 52 -28 110 0
    99 37 159 125 206 56 30 132 28 195 -4z m7600 -690 c51 -9 147 -15 240 -15 93
    0 189 -6 240 -15 47 -8 114 -15 150 -15 36 0 103 -7 150 -15 47 -8 112 -15
    145 -15 33 0 107 -6 165 -15 58 -8 159 -21 225 -30 66 -9 203 -29 305 -45 102
    -16 235 -36 295 -45 468 -70 1501 -309 2005 -464 578 -178 681 -213 1180 -399
    526 -195 1013 -408 1560 -681 261 -131 495 -250 520 -266 25 -16 97 -55 160
    -88 63 -32 300 -170 525 -305 380 -228 631 -390 995 -643 178 -124 864 -638
    941 -705 63 -55 213 -180 425 -354 43 -36 124 -108 179 -161 55 -52 134 -123
    175 -156 41 -34 107 -94 145 -133 39 -39 104 -99 145 -132 108 -87 468 -446
    560 -558 43 -52 110 -126 150 -165 39 -38 99 -104 133 -145 33 -41 104 -120
    156 -175 53 -55 125 -136 161 -179 174 -212 299 -362 354 -425 67 -77 581
    -763 705 -941 253 -364 415 -615 643 -995 135 -225 273 -462 305 -525 33 -63
    72 -135 88 -160 16 -25 135 -259 266 -520 273 -547 486 -1034 681 -1560 186
    -499 221 -602 399 -1180 155 -504 394 -1537 464 -2005 9 -60 29 -193 45 -295
    16 -102 36 -239 45 -305 9 -66 22 -167 30 -225 9 -58 15 -132 15 -165 0 -33 7
    -98 15 -145 8 -47 15 -114 15 -150 0 -36 7 -103 15 -150 9 -51 15 -147 15
    -240 0 -93 6 -189 15 -240 13 -71 15 -226 15 -945 0 -719 -2 -874 -15 -945 -9
    -51 -15 -147 -15 -240 0 -93 -6 -189 -15 -240 -8 -47 -15 -114 -15 -150 0 -36
    -7 -103 -15 -150 -8 -47 -15 -112 -15 -145 0 -33 -6 -107 -15 -165 -8 -58 -21
    -159 -30 -225 -9 -66 -29 -203 -45 -305 -16 -102 -36 -234 -45 -295 -70 -468
    -309 -1501 -464 -2005 -178 -578 -213 -681 -399 -1180 -195 -526 -408 -1013
    -681 -1560 -131 -261 -250 -495 -266 -520 -16 -25 -55 -97 -88 -160 -32 -63
    -170 -299 -305 -525 -228 -380 -390 -631 -643 -995 -124 -178 -638 -864 -705
    -941 -55 -63 -180 -213 -354 -425 -36 -43 -108 -124 -161 -179 -52 -55 -123
    -134 -156 -175 -34 -41 -94 -106 -133 -145 -40 -38 -107 -113 -150 -165 -92
    -112 -452 -471 -560 -558 -41 -33 -106 -93 -145 -132 -38 -39 -104 -99 -145
    -133 -41 -33 -120 -104 -175 -156 -55 -53 -136 -125 -179 -161 -212 -174 -362
    -299 -425 -354 -77 -67 -763 -581 -941 -705 -364 -253 -615 -415 -995 -643
    -225 -135 -462 -273 -525 -305 -63 -33 -135 -72 -160 -88 -25 -16 -259 -135
    -520 -266 -547 -273 -1034 -486 -1560 -681 -499 -186 -602 -221 -1180 -399
    -504 -155 -1537 -394 -2005 -464 -60 -9 -193 -29 -295 -45 -102 -16 -239 -36
    -305 -45 -66 -9 -167 -22 -225 -30 -58 -9 -132 -15 -165 -15 -33 0 -98 -7
    -145 -15 -47 -8 -114 -15 -150 -15 -36 0 -103 -7 -150 -15 -51 -9 -147 -15
    -240 -15 -93 0 -189 -6 -240 -15 -126 -22 -1764 -22 -1890 0 -51 9 -147 15
    -240 15 -93 0 -189 6 -240 15 -47 8 -114 15 -150 15 -36 0 -103 7 -150 15 -47
    8 -112 15 -145 15 -33 0 -107 6 -165 15 -58 8 -159 21 -225 30 -66 9 -203 29
    -305 45 -102 16 -234 36 -295 45 -468 70 -1501 309 -2005 464 -578 178 -681
    213 -1180 399 -526 195 -1013 408 -1560 681 -261 131 -495 250 -520 266 -25
    16 -97 55 -160 88 -63 32 -299 170 -525 305 -380 228 -631 390 -995 643 -178
    124 -864 638 -941 705 -63 55 -213 180 -425 354 -43 36 -124 108 -179 161 -55
    52 -134 123 -175 156 -41 34 -106 94 -145 133 -38 39 -104 99 -145 132 -108
    87 -468 446 -560 558 -43 52 -110 127 -150 165 -39 39 -99 104 -133 145 -33
    41 -104 120 -156 175 -53 55 -125 136 -161 179 -174 212 -299 362 -354 425
    -67 77 -581 763 -705 941 -253 364 -415 615 -643 995 -135 226 -273 462 -305
    525 -33 63 -72 135 -88 160 -16 25 -135 259 -266 520 -273 547 -486 1034 -681
    1560 -186 499 -221 602 -399 1180 -155 504 -394 1537 -464 2005 -9 61 -29 193
    -45 295 -16 102 -36 239 -45 305 -9 66 -22 167 -30 225 -9 58 -15 132 -15 165
    0 33 -7 98 -15 145 -8 47 -15 114 -15 150 0 36 -7 103 -15 150 -9 51 -15 147
    -15 240 0 93 -6 189 -15 240 -13 71 -15 226 -15 945 0 719 2 874 15 945 9 51
    15 147 15 240 0 93 6 189 15 240 8 47 15 114 15 150 0 36 7 103 15 150 8 47
    15 112 15 145 0 33 6 107 15 165 8 58 21 159 30 225 9 66 29 203 45 305 16
    102 36 235 45 295 70 468 309 1501 464 2005 178 578 213 681 399 1180 195 526
    408 1013 681 1560 131 261 250 495 266 520 16 25 55 97 88 160 32 63 170 300
    305 525 228 380 390 631 643 995 124 178 638 864 705 941 55 63 180 213 354
    425 36 43 108 124 161 179 52 55 123 134 156 175 34 41 94 107 133 145 40 39
    107 113 150 165 92 112 452 471 560 558 41 33 107 93 145 132 39 39 104 99
    145 133 41 33 120 104 175 156 55 53 136 125 179 161 212 174 362 299 425 354
    77 67 763 581 941 705 364 253 615 415 995 643 226 135 462 273 525 305 63 33
    135 72 160 88 25 16 259 135 520 266 547 273 1034 486 1560 681 499 186 602
    221 1180 399 504 155 1537 394 2005 464 61 9 193 29 295 45 102 16 239 36 305
    45 66 9 167 22 225 30 58 9 132 15 165 15 33 0 98 7 145 15 47 8 114 15 150
    15 36 0 103 7 150 15 50 9 150 15 245 16 88 0 191 6 230 13 110 21 1777 22
    1895 1z m-14419 -337 c9 -7 40 -42 70 -78 30 -36 56 -67 60 -70 3 -3 101 -117
    219 -255 118 -137 217 -252 220 -255 14 -10 27 -45 23 -58 -3 -7 -13 -17 -23
    -22 -16 -9 -37 3 -124 68 -104 78 -223 143 -322 175 -61 20 -295 24 -341 6
    -15 -6 -41 -15 -58 -19 -72 -20 -124 -45 -199 -95 -182 -120 -256 -246 -256
    -437 0 -88 3 -104 30 -161 33 -71 57 -100 114 -143 82 -61 162 -77 341 -69
    169 7 230 17 675 110 76 16 187 37 316 59 235 41 407 13 617 -101 83 -44 219
    -203 261 -303 10 -25 23 -54 28 -64 12 -23 26 -106 35 -205 14 -166 -49 -371
    -164 -531 -45 -64 -186 -210 -202 -210 -4 0 -41 -25 -82 -56 -64 -48 -159 -99
    -274 -148 -156 -66 -253 -117 -284 -148 -37 -38 -40 -70 -11 -134 22 -48 17
    -75 -17 -88 -19 -8 -33 5 -150 144 -39 47 -81 95 -93 108 -12 13 -44 51 -72
    85 -28 34 -56 67 -62 72 -7 6 -50 55 -96 110 -47 55 -101 118 -121 139 -36 38
    -37 41 -21 63 24 33 46 28 110 -20 58 -44 126 -90 172 -115 14 -8 39 -21 55
    -30 261 -142 639 -65 884 179 72 72 120 142 153 221 18 44 21 73 22 168 0 109
    -2 118 -32 181 -96 199 -339 289 -627 233 -44 -8 -115 -22 -157 -30 -104 -19
    -229 -43 -308 -59 -99 -20 -242 -43 -375 -61 -122 -16 -256 -14 -337 7 -21 5
    -57 14 -80 20 -157 39 -332 185 -408 342 -70 146 -96 310 -66 426 9 34 16 72
    16 83 0 31 60 167 103 232 96 146 274 294 429 357 29 11 67 27 83 34 17 7 48
    20 70 28 126 48 160 64 186 91 24 25 29 38 29 81 0 30 -6 59 -15 71 -20 26
    -19 40 5 64 23 23 31 24 51 8z m27588 -799 c83 -14 171 -43 236 -77 63 -33
    199 -135 243 -181 85 -92 181 -235 227 -341 66 -152 115 -210 178 -210 16 0
    51 11 78 24 l48 25 25 -20 c33 -26 28 -38 -58 -128 -39 -40 -76 -78 -81 -85
    -6 -6 -71 -76 -145 -156 -118 -128 -173 -186 -297 -316 -37 -39 -45 -41 -72
    -18 -21 19 -19 33 13 79 43 60 146 265 146 288 0 8 7 31 15 50 11 26 15 74 15
    169 0 154 -7 183 -96 373 -19 40 -65 97 -140 172 -43 43 -85 73 -140 99 -79
    38 -79 38 -204 38 -106 -1 -132 -4 -171 -22 -84 -39 -135 -90 -194 -192 -17
    -31 -20 -53 -19 -175 0 -100 5 -159 18 -205 9 -36 24 -92 33 -125 8 -33 21
    -78 27 -100 7 -22 21 -67 31 -100 9 -33 23 -76 30 -95 14 -38 62 -190 90 -285
    10 -33 24 -78 31 -100 64 -206 83 -367 58 -500 -53 -288 -238 -495 -519 -582
    -82 -25 -350 -25 -409 0 -21 10 -46 17 -55 17 -9 0 -62 23 -119 52 -123 62
    -209 130 -326 258 -51 55 -146 182 -146 194 0 3 -19 37 -43 78 -24 40 -46 80
    -50 88 -4 8 -16 29 -27 45 -11 17 -24 40 -29 51 -5 12 -27 40 -49 63 -34 36
    -44 41 -83 41 -31 0 -56 -8 -82 -26 -36 -24 -40 -25 -63 -10 -28 19 -31 45 -6
    67 24 22 126 130 282 299 156 169 249 267 305 322 l39 38 24 -23 23 -24 -21
    -39 c-12 -21 -31 -55 -42 -74 -95 -160 -148 -317 -148 -437 0 -218 149 -503
    340 -647 203 -153 425 -168 598 -39 141 105 203 323 151 528 -6 22 -17 67 -26
    100 -8 33 -21 78 -27 100 -7 22 -20 65 -29 95 -9 30 -30 98 -47 150 -57 182
    -98 328 -134 480 -23 94 -37 286 -26 335 5 22 10 57 10 77 0 21 6 52 14 70 8
    18 22 53 32 78 25 62 120 185 187 243 31 26 58 47 61 47 3 0 27 12 53 28 121
    70 304 98 462 71z m-29286 -873 c-4 -6 -25 -33 -48 -61 -84 -100 -99 -132 -99
    -215 -1 -66 3 -80 27 -119 41 -62 201 -219 667 -656 47 -44 129 -123 184 -175
    54 -52 104 -100 110 -105 48 -44 377 -355 441 -416 102 -99 115 -110 203 -177
    63 -48 81 -56 127 -60 45 -4 64 1 118 27 35 17 91 56 124 86 34 30 67 55 75
    55 19 0 40 -38 31 -55 -4 -7 -51 -58 -103 -112 -53 -54 -163 -170 -245 -258
    -82 -88 -191 -203 -241 -255 -51 -52 -116 -121 -144 -152 -52 -58 -75 -69 -98
    -46 -20 20 -14 38 34 91 78 87 104 141 104 218 0 64 -2 70 -46 130 -54 72
    -103 122 -338 344 -93 88 -181 171 -195 185 -14 14 -98 92 -186 175 -88 82
    -204 192 -259 245 -54 52 -104 100 -110 105 -6 6 -87 82 -181 170 -93 88 -190
    178 -214 200 -24 22 -54 50 -68 63 -39 36 -96 69 -141 83 -74 22 -172 -18
    -264 -106 -61 -59 -86 -67 -106 -34 -16 25 -37 0 238 288 14 14 93 98 175 186
    172 184 169 181 294 309 l94 96 23 -21 c13 -12 20 -27 17 -33z m31151 -1312
    c113 -19 284 -67 334 -93 9 -5 49 -24 87 -42 187 -89 389 -264 515 -448 49
    -70 140 -249 140 -275 0 -8 4 -17 9 -21 5 -3 12 -20 16 -38 3 -17 12 -52 20
    -77 8 -25 21 -74 31 -110 18 -69 42 -103 86 -126 35 -18 96 -18 141 1 53 22
    76 19 89 -11 14 -29 23 -19 -206 -229 -60 -55 -114 -105 -120 -111 -6 -5 -63
    -57 -126 -114 -63 -57 -137 -124 -163 -149 -58 -55 -62 -57 -82 -38 -22 23
    -19 37 24 103 68 104 135 246 164 343 38 132 44 368 11 450 -8 18 -14 41 -14
    51 0 10 -13 45 -30 78 -16 34 -30 63 -30 66 0 21 -118 175 -185 241 -70 71
    -164 145 -182 145 -4 0 -14 6 -22 14 -9 7 -52 27 -96 44 -205 79 -357 82 -605
    12 -103 -29 -128 -39 -256 -102 -380 -185 -733 -509 -870 -798 -82 -171 -97
    -237 -97 -410 1 -181 23 -279 101 -435 105 -213 268 -383 457 -478 139 -69
    244 -89 495 -96 l205 -6 3 -33 c5 -50 -20 -55 -273 -56 -185 -1 -232 2 -295
    18 -84 21 -182 52 -208 65 -9 5 -35 17 -57 27 -248 116 -520 416 -648 714
    -114 266 -128 574 -38 845 55 165 73 204 143 320 10 17 22 39 27 50 5 11 26
    40 48 65 22 25 55 65 74 91 59 78 290 276 391 334 44 26 228 115 266 129 99
    37 119 44 162 55 25 6 90 18 145 27 55 8 105 17 110 19 27 10 229 3 309 -11z
    m-32491 -89 c38 -25 71 -45 73 -45 2 0 33 -20 70 -45 36 -25 67 -45 69 -45 1
    0 53 -34 115 -75 62 -41 114 -75 115 -75 2 0 33 -20 69 -45 37 -25 68 -45 70
    -45 2 0 28 -16 57 -35 62 -40 78 -69 49 -85 -20 -11 -22 -11 -185 33 -352 94
    -610 49 -856 -150 -60 -48 -176 -175 -189 -206 -3 -7 -16 -30 -30 -52 -39 -62
    -90 -175 -90 -198 0 -8 -7 -32 -17 -53 -26 -62 -23 -327 5 -414 51 -158 97
    -251 192 -384 69 -97 241 -280 322 -341 28 -22 56 -44 62 -50 39 -38 159 -121
    291 -202 51 -32 192 -95 264 -120 139 -47 195 -56 346 -57 117 0 163 4 210 18
    198 59 321 132 462 273 240 239 337 503 299 812 -9 67 -18 126 -20 130 -3 4
    -10 32 -15 62 -5 30 -19 85 -30 124 -18 64 -18 71 -4 87 21 23 52 23 59 1 3
    -10 19 -49 34 -88 16 -38 31 -81 35 -95 3 -14 17 -67 30 -119 50 -190 44 -438
    -15 -620 -31 -99 -106 -255 -146 -308 -13 -17 -24 -35 -24 -38 0 -13 -93 -127
    -173 -211 -72 -77 -205 -188 -247 -207 -8 -4 -28 -16 -45 -27 -16 -11 -40 -24
    -53 -30 -13 -5 -46 -20 -75 -33 -66 -30 -108 -44 -210 -70 -70 -17 -112 -20
    -287 -20 -155 0 -218 4 -260 16 -30 9 -84 23 -120 32 -66 18 -126 40 -215 80
    -27 12 -57 25 -65 29 -21 9 -45 24 -118 74 -35 23 -66 42 -68 42 -15 0 -141
    106 -221 187 -159 160 -279 337 -347 513 -10 25 -24 59 -31 75 -12 28 -21 60
    -55 200 -7 28 -15 124 -18 214 -5 176 6 257 58 436 28 98 107 265 167 353 24
    35 43 67 43 72 0 5 51 60 113 123 113 113 210 185 352 262 103 56 130 78 145
    124 16 48 4 96 -42 171 l-27 45 21 22 c12 13 24 23 27 23 3 0 37 -20 74 -45z
    m34530 -2075 c11 -19 30 -55 44 -80 13 -25 32 -58 42 -75 10 -16 35 -61 56
    -100 21 -38 49 -88 61 -110 13 -22 32 -58 44 -80 12 -22 36 -65 53 -95 62
    -111 76 -136 100 -180 14 -25 34 -61 46 -80 11 -19 24 -42 28 -50 4 -8 19 -37
    35 -63 33 -57 35 -78 7 -95 -27 -17 -39 -7 -80 65 -56 98 -156 173 -230 173
    -59 0 -159 -42 -329 -137 -14 -8 -43 -24 -65 -37 -94 -53 -153 -85 -190 -106
    -22 -12 -53 -29 -70 -39 -16 -10 -66 -37 -110 -61 -44 -24 -93 -52 -110 -62
    -16 -10 -40 -22 -52 -27 -50 -22 -47 -42 26 -166 10 -16 37 -66 61 -110 23
    -44 46 -84 50 -90 7 -9 30 -51 90 -160 21 -39 32 -59 97 -175 17 -30 41 -73
    53 -95 12 -22 29 -53 39 -70 18 -31 94 -170 118 -215 7 -14 21 -35 31 -48 l18
    -23 69 37 c78 41 123 65 175 96 19 11 55 31 80 45 25 13 63 35 85 47 22 12 72
    40 110 61 39 21 84 46 100 56 17 10 51 29 77 43 153 83 233 150 249 210 19 69
    3 169 -40 257 l-37 77 27 16 c15 9 29 16 32 16 6 0 50 -74 124 -209 24 -45 37
    -69 100 -181 17 -30 41 -73 53 -95 12 -22 29 -53 39 -70 15 -26 41 -73 118
    -215 8 -14 22 -38 32 -55 10 -16 39 -69 65 -117 l47 -87 -32 -16 c-17 -9 -32
    -15 -32 -13 -1 2 -12 17 -25 35 -12 18 -22 37 -22 43 0 19 -139 150 -159 150
    -11 0 -23 5 -26 10 -12 20 -118 4 -181 -28 -11 -5 -32 -16 -49 -24 -16 -9 -56
    -29 -87 -47 -32 -17 -84 -45 -115 -62 -32 -18 -62 -35 -68 -39 -5 -4 -46 -27
    -90 -50 -44 -24 -93 -51 -110 -61 -16 -10 -48 -27 -70 -39 -105 -57 -151 -83
    -160 -90 -5 -4 -46 -27 -90 -51 -124 -66 -128 -69 -165 -91 -19 -11 -55 -31
    -80 -45 -44 -24 -69 -38 -180 -100 -30 -17 -73 -41 -95 -53 -22 -12 -53 -29
    -70 -39 -16 -10 -66 -37 -110 -61 -44 -24 -93 -51 -110 -61 -16 -10 -48 -27
    -70 -39 -22 -12 -59 -35 -83 -51 -24 -16 -45 -29 -48 -29 -15 0 -80 -59 -100
    -92 -20 -31 -24 -51 -24 -112 0 -77 6 -97 62 -208 l29 -58 -26 -12 c-17 -8
    -32 -9 -42 -2 -13 8 -72 105 -109 179 -8 17 -24 46 -35 65 -12 19 -32 55 -46
    80 -13 25 -32 59 -42 75 -15 26 -41 73 -118 215 -8 14 -23 41 -35 60 -11 19
    -31 55 -45 80 -13 25 -32 59 -42 75 -10 17 -36 65 -59 107 l-42 76 27 22 27
    21 22 -28 c13 -15 23 -32 24 -36 0 -11 40 -67 75 -105 54 -60 92 -77 170 -76
    63 1 80 6 175 52 58 29 141 72 185 97 44 25 94 52 110 61 17 8 44 23 60 33 17
    9 62 35 100 55 39 21 75 42 80 46 6 4 46 27 90 50 44 24 94 52 110 62 17 10
    39 22 50 27 34 15 50 40 38 59 -10 17 -53 93 -93 167 -12 22 -29 54 -39 70
    -10 17 -35 62 -56 100 -21 39 -49 88 -61 110 -13 22 -32 58 -44 80 -60 109
    -83 151 -90 160 -4 6 -27 46 -51 90 -66 124 -69 128 -91 165 -11 19 -32 57
    -45 83 -30 57 -51 70 -81 54 -11 -7 -40 -23 -64 -36 -133 -72 -154 -84 -163
    -91 -5 -4 -46 -27 -90 -50 -44 -24 -93 -51 -110 -61 -16 -10 -48 -27 -70 -39
    -77 -42 -148 -82 -197 -111 -27 -16 -51 -29 -53 -29 -9 0 -141 -93 -167 -118
    -15 -14 -33 -41 -39 -60 -15 -44 -5 -183 15 -220 8 -15 25 -49 37 -76 21 -47
    22 -49 4 -62 -34 -25 -50 -14 -90 61 -53 97 -101 185 -116 210 -10 17 -27 48
    -39 70 -12 22 -36 65 -53 95 -62 111 -76 136 -100 180 -14 25 -34 61 -45 80
    -11 19 -30 53 -42 75 -21 37 -47 86 -106 192 -21 39 -22 42 -5 57 30 27 48 18
    82 -41 71 -124 140 -176 233 -177 73 -1 146 27 321 124 28 15 68 38 90 50 22
    12 56 31 75 42 19 11 55 31 80 45 44 24 69 38 180 100 30 17 73 41 95 53 22
    12 54 29 70 39 17 10 66 37 110 61 44 24 94 51 110 61 17 10 48 27 70 39 22
    12 65 36 95 53 116 65 136 76 175 97 79 43 148 82 197 111 27 16 51 29 53 29
    4 0 148 82 175 100 8 6 24 14 35 20 51 24 132 89 157 125 24 35 27 50 28 113
    0 76 -7 98 -60 203 -27 53 -27 57 -12 73 25 25 53 16 75 -24z m-36397 -561 c2
    -5 -7 -34 -21 -66 -59 -128 -70 -183 -49 -249 31 -99 86 -140 409 -305 77 -39
    208 -106 290 -149 83 -42 238 -122 345 -177 107 -55 272 -140 365 -188 387
    -199 490 -252 685 -352 113 -58 213 -113 223 -122 14 -14 16 -23 8 -44 -11
    -28 -21 -32 -166 -62 -44 -9 -107 -22 -140 -30 -33 -7 -96 -20 -140 -30 -44
    -9 -107 -22 -140 -30 -33 -7 -96 -20 -140 -30 -44 -9 -107 -22 -140 -30 -33
    -7 -96 -20 -140 -30 -44 -9 -107 -22 -140 -30 -33 -7 -96 -20 -140 -30 -44 -9
    -107 -22 -140 -30 -33 -7 -96 -20 -140 -30 -44 -9 -107 -22 -140 -30 -33 -7
    -96 -20 -140 -30 -91 -19 -192 -41 -280 -60 -33 -7 -96 -20 -140 -29 -44 -9
    -100 -22 -125 -28 -25 -6 -65 -14 -90 -18 -25 -4 -66 -12 -92 -19 -27 -6 -69
    -16 -95 -22 -27 -7 -69 -15 -95 -19 -26 -3 -50 -9 -53 -12 -11 -10 36 -40 176
    -111 156 -80 436 -224 702 -361 92 -47 242 -125 335 -172 92 -48 212 -109 267
    -136 55 -28 108 -54 119 -60 37 -19 140 -48 173 -48 72 0 166 76 216 174 15
    29 35 60 45 69 16 14 19 14 42 -3 l25 -19 -59 -113 c-32 -62 -107 -207 -166
    -323 -59 -115 -131 -255 -159 -310 -54 -104 -65 -112 -103 -78 -17 15 -18 14
    49 145 18 35 17 171 -1 206 -8 15 -32 43 -53 63 -33 31 -62 50 -158 106 -11 6
    -72 38 -135 71 -63 32 -205 105 -315 162 -110 57 -265 137 -345 178 -80 41
    -212 109 -295 151 -82 43 -253 131 -380 195 -126 65 -236 123 -243 129 -7 6
    -32 10 -55 10 -43 0 -184 -38 -202 -54 -5 -5 -15 -9 -22 -9 -15 0 -102 -62
    -150 -105 -15 -15 -49 -57 -74 -93 -48 -72 -80 -88 -98 -54 -9 17 4 48 73 184
    46 89 115 223 152 297 38 74 77 146 86 162 18 30 56 44 203 74 121 24 301 62
    430 90 33 7 96 21 140 30 44 9 107 22 140 30 33 7 95 20 138 29 42 9 112 23
    155 32 42 9 104 22 137 29 33 8 96 21 140 30 44 9 107 23 140 30 33 7 96 20
    140 29 164 34 323 68 430 91 33 7 96 21 140 30 44 9 107 23 140 30 33 8 79 17
    102 20 37 6 42 9 33 25 -5 10 -60 44 -122 75 -62 32 -178 92 -258 133 -80 41
    -212 109 -295 151 -82 43 -249 128 -370 191 -453 234 -501 256 -578 265 -115
    15 -193 -41 -272 -192 -27 -53 -49 -65 -73 -41 -20 20 -15 37 45 153 31 60 91
    178 134 260 42 83 110 214 150 293 39 78 79 146 87 151 16 9 49 3 58 -10z
    m37555 -3103 c103 -16 196 -38 228 -55 11 -6 27 -11 35 -11 29 0 204 -90 303
    -157 180 -119 368 -328 445 -493 92 -194 125 -293 148 -443 41 -272 -33 -614
    -182 -840 -21 -32 -38 -60 -38 -62 0 -2 -14 -26 -32 -52 -55 -82 -220 -252
    -293 -302 -38 -26 -88 -60 -110 -75 -37 -25 -95 -55 -215 -108 -25 -10 -53
    -23 -62 -28 -21 -12 -114 -42 -193 -63 -134 -36 -226 -47 -393 -47 -164 0
    -247 10 -362 45 -25 7 -60 16 -77 20 -18 4 -35 11 -38 16 -4 5 -12 9 -19 9
    -18 0 -108 42 -171 79 -27 16 -72 42 -100 57 -27 14 -78 51 -111 80 -34 30
    -79 69 -99 86 -33 29 -215 259 -215 272 0 3 -17 42 -39 88 -71 152 -95 221
    -125 368 -22 101 -22 368 -1 459 44 190 100 328 182 449 24 35 43 65 43 68 0
    28 222 259 300 314 107 75 185 125 210 136 8 4 38 17 65 29 146 66 265 110
    370 136 154 38 390 48 546 25z m-36634 -1021 c18 -12 20 -20 13 -51 -5 -19
    -13 -48 -18 -62 -5 -15 -18 -54 -27 -87 -10 -33 -23 -76 -30 -95 -12 -34 -22
    -69 -60 -195 -10 -33 -23 -76 -30 -95 -7 -19 -20 -62 -30 -95 -38 -127 -48
    -161 -60 -195 -7 -19 -22 -64 -33 -100 -22 -71 -44 -87 -76 -51 -15 16 -16 25
    -7 58 31 112 0 210 -79 250 -39 20 -61 23 -170 23 -69 0 -152 -5 -185 -10 -33
    -6 -123 -15 -200 -21 -77 -5 -151 -16 -164 -22 -23 -12 -43 -64 -106 -272 -8
    -27 -29 -93 -45 -145 -17 -52 -37 -117 -45 -145 -8 -27 -29 -93 -45 -145 -17
    -52 -36 -115 -44 -140 -7 -25 -22 -71 -32 -102 -12 -39 -15 -63 -8 -76 5 -11
    63 -66 129 -122 66 -56 145 -124 175 -150 131 -113 238 -180 290 -180 72 0
    140 67 200 198 26 54 42 62 80 42 18 -10 19 -14 6 -48 -7 -20 -20 -62 -29 -92
    -9 -30 -30 -98 -47 -150 -16 -52 -37 -117 -45 -145 -8 -27 -28 -93 -45 -145
    -16 -52 -37 -120 -46 -150 -31 -106 -39 -114 -85 -90 -17 10 -19 22 -19 117 0
    103 -1 107 -34 162 -49 80 -178 209 -369 369 -54 45 -110 93 -125 106 -15 13
    -81 70 -147 126 -66 56 -132 113 -147 126 -15 13 -71 60 -125 105 -54 45 -125
    106 -158 135 -33 28 -159 136 -280 239 -121 103 -231 197 -245 209 -14 12 -83
    71 -155 131 -71 60 -141 119 -155 131 -14 12 -79 68 -145 124 -66 57 -123 108
    -127 115 -12 21 3 53 30 64 15 5 79 15 142 21 63 6 183 17 265 25 83 8 224 21
    315 30 91 9 233 22 315 30 156 15 342 33 625 60 368 35 471 45 610 60 80 8
    163 15 184 15 91 0 318 47 391 80 60 28 131 95 161 153 27 53 52 87 63 87 4 0
    16 -7 28 -15z m37523 -2361 c283 -63 486 -172 693 -371 90 -86 213 -246 250
    -325 82 -175 97 -210 97 -225 0 -15 7 -41 46 -180 25 -86 24 -392 0 -488 -47
    -187 -109 -332 -200 -468 -72 -109 -223 -264 -347 -358 -65 -49 -122 -89 -126
    -89 -4 0 -35 -13 -68 -29 -130 -63 -196 -91 -212 -91 -9 0 -39 -6 -67 -14
    -155 -44 -181 -49 -380 -66 -116 -10 -345 10 -437 38 -19 6 -61 18 -94 28 -33
    10 -87 30 -121 46 -34 15 -64 28 -68 28 -3 0 -41 21 -84 47 -186 112 -361 273
    -457 419 -30 45 -55 86 -55 90 0 4 -6 15 -13 23 -8 9 -27 50 -44 91 -77 185
    -113 366 -113 561 0 96 20 263 39 322 36 112 52 160 60 175 5 9 19 37 31 62
    114 241 376 505 607 611 191 88 197 90 368 132 33 8 83 21 110 27 123 31 456
    33 585 4z m-38407 -729 c-2 -44 -9 -125 -17 -180 -8 -55 -22 -154 -31 -220
    -35 -259 -33 -249 -59 -268 -14 -10 -53 -33 -88 -51 -137 -72 -245 -130 -295
    -157 -29 -16 -77 -42 -105 -58 -29 -16 -98 -52 -153 -81 -55 -30 -127 -68
    -160 -86 -33 -18 -82 -44 -110 -58 -27 -14 -90 -48 -140 -76 -49 -28 -112 -62
    -140 -76 -202 -104 -205 -106 -205 -163 0 -25 -7 -83 -16 -128 -20 -100 -16
    -113 33 -113 21 0 83 -6 138 -15 55 -8 154 -22 220 -30 66 -9 165 -23 220 -30
    373 -53 471 -49 534 21 34 37 71 101 71 124 0 8 7 33 15 55 8 22 14 54 15 72
    0 38 12 47 47 38 25 -6 25 -6 19 -83 -4 -42 -13 -122 -21 -177 -8 -55 -21
    -149 -29 -210 -8 -60 -22 -164 -31 -230 -9 -66 -23 -165 -30 -220 -8 -55 -17
    -127 -21 -160 -7 -77 -26 -107 -57 -96 -21 8 -22 13 -22 132 -1 108 -3 129
    -23 166 -53 101 -129 128 -507 178 -66 9 -168 23 -227 31 -60 7 -156 21 -215
    28 -276 37 -355 48 -448 61 -103 14 -246 33 -452 61 -65 8 -159 22 -209 30
    -122 19 -345 19 -383 -1 -29 -15 -77 -70 -101 -114 -7 -14 -23 -67 -36 -119
    -13 -52 -25 -96 -27 -98 -1 -2 -14 3 -27 10 -23 11 -25 17 -23 72 2 32 9 104
    17 159 8 55 22 154 31 220 100 757 116 854 181 1070 61 207 212 390 394 476
    98 46 133 53 265 54 131 0 229 -18 280 -52 11 -7 25 -13 31 -13 18 0 107 -62
    159 -111 50 -47 124 -153 150 -215 37 -89 85 -267 85 -320 0 -55 11 -57 81
    -21 141 75 246 131 294 157 28 15 89 48 138 74 219 116 266 141 327 174 36 20
    79 44 95 54 17 10 39 22 50 27 11 5 37 21 58 35 21 14 40 26 43 26 7 0 110 80
    145 111 27 26 89 103 89 112 0 3 13 25 28 49 28 44 48 90 61 143 4 17 16 45
    25 63 14 27 22 33 44 30 l27 -3 -2 -80z m39697 -2565 c13 -8 15 -91 18 -625 4
    -672 7 -636 -52 -621 -22 5 -23 11 -30 117 -8 128 -25 179 -80 245 -77 92 20
    85 -1193 82 -978 -3 -1072 -5 -1118 -20 -28 -10 -63 -30 -77 -45 -48 -51 -53
    -92 -53 -413 1 -505 29 -631 177 -794 57 -63 170 -141 283 -194 143 -68 136
    -64 133 -91 -4 -32 -36 -39 -87 -18 -20 8 -63 22 -96 32 -33 10 -78 23 -100
    30 -22 7 -67 21 -100 30 -33 10 -76 23 -95 30 -19 7 -57 19 -85 27 -27 9 -82
    25 -122 38 l-72 22 -5 62 c-3 33 -7 516 -8 1071 l-3 1010 35 0 35 0 7 -103 c9
    -115 26 -159 83 -215 27 -27 52 -38 112 -52 73 -17 132 -18 1077 -11 1252 8
    1227 6 1304 129 33 54 36 64 41 163 4 77 10 107 21 114 19 12 31 12 50 0z
    m-42222 -522 c3 -23 -3 -30 -37 -47 -22 -12 -45 -21 -51 -21 -6 0 -49 -18 -94
    -40 -135 -64 -216 -163 -250 -305 -14 -60 -16 -124 -14 -460 l3 -390 530 -2
    c302 -2 536 1 544 6 12 8 14 50 13 233 -3 346 -8 391 -48 464 -47 86 -87 111
    -239 148 -82 20 -90 24 -93 47 -2 14 3 30 10 36 8 6 178 13 459 16 463 7 469
    6 469 -34 0 -32 -29 -49 -84 -49 -73 0 -178 -40 -221 -84 -29 -30 -53 -77 -68
    -132 -14 -50 -17 -110 -17 -340 0 -158 4 -285 10 -294 8 -13 64 -15 417 -12
    458 3 680 15 731 39 78 37 122 129 131 273 6 92 7 95 31 98 14 1 30 -1 37 -6
    9 -6 14 -152 19 -589 l7 -581 -21 -11 c-27 -14 -51 -5 -56 23 -3 11 -7 59 -11
    105 -6 85 -18 120 -62 179 -31 42 -80 62 -173 72 -123 13 -2124 -6 -2195 -21
    -50 -11 -67 -21 -105 -59 -55 -57 -71 -99 -79 -215 -7 -101 -12 -115 -46 -115
    -13 0 -27 6 -32 13 -11 17 -23 2029 -11 2044 11 14 106 24 328 33 261 11 264
    11 268 -22z m40717 -3332 c181 -32 334 -167 397 -351 23 -66 24 -223 2 -291
    -59 -184 -205 -323 -378 -360 -264 -57 -515 86 -605 342 -39 108 -27 270 27
    384 63 132 211 247 357 276 78 16 115 16 200 0z m-39535 -265 c187 -55 296
    -154 362 -329 29 -75 32 -266 6 -327 -9 -22 -19 -47 -23 -55 -34 -82 -137
    -192 -225 -238 -79 -43 -135 -55 -246 -55 -116 -1 -134 3 -242 57 -166 82
    -285 298 -269 486 28 316 346 546 637 461z m1520 -2316 c314 -45 516 -136 682
    -309 56 -59 158 -213 158 -239 0 -7 6 -21 13 -32 13 -19 24 -53 63 -190 24
    -87 25 -319 1 -399 -83 -272 -279 -470 -517 -523 -141 -32 -332 -12 -500 52
    -68 25 -195 79 -205 85 -25 17 -127 70 -135 70 -5 0 -12 -21 -15 -47 -31 -221
    -73 -357 -145 -468 -11 -16 -24 -39 -29 -51 -5 -11 -47 -59 -93 -105 -84 -86
    -181 -157 -273 -199 -27 -12 -61 -28 -75 -35 -14 -6 -54 -19 -90 -29 -36 -10
    -90 -24 -120 -33 -41 -12 -103 -16 -245 -16 -194 -1 -217 2 -350 49 -23 8 -46
    14 -53 14 -6 0 -40 13 -74 29 -35 16 -70 31 -78 35 -72 32 -207 134 -295 222
    -135 135 -263 337 -340 534 -9 25 -22 56 -27 70 -51 128 -98 332 -98 430 0
    244 186 371 341 233 58 -52 72 -102 71 -253 0 -84 -6 -162 -16 -208 -20 -94
    -21 -291 -1 -347 8 -22 15 -46 15 -54 0 -8 14 -43 31 -79 97 -206 288 -341
    537 -381 74 -12 223 1 292 25 57 20 227 102 243 118 7 6 39 32 72 58 121 92
    208 206 245 320 11 34 25 77 30 93 18 53 32 237 27 350 -4 104 -23 203 -53
    288 -18 49 -5 77 35 77 23 0 34 -10 62 -52 18 -29 36 -57 38 -63 16 -38 174
    -201 257 -265 64 -49 218 -130 248 -130 8 0 41 -7 73 -15 107 -28 264 -14 398
    35 199 73 323 195 385 380 27 81 28 250 1 335 -77 242 -254 412 -524 503 -120
    40 -128 48 -113 108 7 28 9 28 146 9z m35530 -516 c30 -11 66 -24 80 -29 62
    -22 112 -41 128 -49 9 -5 36 -16 60 -25 23 -9 61 -23 85 -32 23 -9 58 -22 77
    -29 19 -7 71 -27 115 -45 75 -30 100 -40 193 -74 85 -32 128 -49 225 -90 21
    -9 44 -16 51 -16 7 0 25 -9 39 -20 23 -18 25 -24 16 -48 -17 -45 -33 -52 -92
    -37 -294 71 -573 52 -772 -53 -114 -60 -259 -180 -330 -273 -40 -51 -65 -88
    -65 -95 0 -4 -6 -15 -13 -23 -17 -20 -70 -149 -91 -222 -22 -75 -22 -244 0
    -313 71 -222 215 -364 402 -397 173 -30 344 44 666 285 72 54 158 121 191 150
    33 28 83 69 110 91 28 22 66 54 85 71 19 18 73 63 120 100 47 38 91 75 99 82
    30 29 250 191 316 233 95 61 259 139 293 139 9 0 37 7 62 16 71 25 322 22 423
    -5 178 -47 367 -168 481 -308 66 -81 142 -217 170 -303 40 -126 46 -152 57
    -254 13 -116 1 -299 -25 -381 -7 -22 -21 -67 -31 -100 -66 -225 -173 -409
    -390 -670 -63 -76 -135 -173 -135 -181 0 -4 -6 -15 -13 -23 -7 -9 -18 -33 -24
    -54 -20 -69 12 -130 100 -190 31 -21 34 -38 11 -72 -19 -28 -39 -31 -86 -11
    -18 8 -51 21 -73 29 -64 24 -128 49 -160 62 -16 7 -49 20 -72 29 -24 9 -62 23
    -85 32 -24 9 -61 23 -83 31 -22 8 -53 21 -70 28 -16 7 -49 20 -72 29 -41 15
    -63 23 -165 62 -24 9 -56 22 -73 29 -16 7 -48 19 -70 28 -129 49 -140 55 -137
    79 1 12 9 32 16 43 13 20 20 21 73 14 32 -4 82 -12 112 -18 29 -6 137 -11 240
    -11 188 0 220 4 319 46 18 8 37 14 42 14 5 0 48 23 95 50 121 72 287 235 362
    355 33 52 106 211 119 257 76 270 51 464 -84 643 -102 137 -231 199 -412 200
    -126 0 -195 -17 -330 -83 -77 -38 -239 -145 -260 -172 -3 -3 -45 -37 -93 -75
    -49 -39 -108 -86 -131 -105 -24 -19 -76 -62 -117 -94 -41 -33 -76 -63 -79 -66
    -3 -3 -36 -30 -75 -60 -38 -30 -72 -57 -75 -60 -9 -10 -162 -124 -215 -160
    -137 -92 -165 -110 -226 -142 -37 -19 -75 -38 -85 -41 -11 -3 -44 -15 -74 -26
    -135 -52 -330 -70 -450 -43 -229 53 -416 168 -539 332 -60 79 -135 219 -151
    279 -5 20 -16 60 -24 88 -8 29 -20 97 -26 151 -10 82 -9 117 5 213 9 63 22
    134 30 159 44 138 58 175 86 232 34 70 41 82 83 148 48 75 109 149 211 260
    116 126 143 166 151 224 8 59 -22 124 -76 161 -22 15 -42 36 -45 46 -6 17 22
    79 35 79 3 0 30 -9 60 -21z m-920 -2484 c0 -6 -16 -43 -36 -81 -77 -146 -95
    -264 -55 -355 28 -62 137 -161 222 -203 8 -3 28 -15 44 -25 17 -11 48 -29 70
    -41 22 -12 63 -35 90 -50 28 -15 82 -44 120 -65 39 -21 84 -46 100 -56 17 -10
    53 -30 80 -44 28 -14 77 -41 110 -60 33 -18 83 -45 110 -60 28 -14 64 -34 80
    -44 17 -10 62 -35 100 -56 76 -41 139 -75 225 -122 30 -17 87 -47 125 -68 39
    -21 84 -46 100 -56 17 -10 53 -30 80 -44 28 -15 77 -42 110 -60 33 -19 83 -46
    110 -60 28 -14 64 -34 80 -44 17 -10 59 -33 95 -53 126 -69 196 -107 260 -142
    104 -58 240 -123 305 -145 70 -25 110 -26 149 -6 15 8 33 15 38 15 5 0 35 20
    67 44 61 47 121 121 161 198 14 26 31 50 38 53 16 6 43 -7 61 -29 14 -16 0
    -49 -72 -178 -18 -31 -43 -76 -56 -100 -13 -23 -36 -65 -51 -93 -26 -48 -54
    -99 -120 -220 -16 -30 -43 -79 -59 -107 -16 -29 -39 -73 -52 -96 -13 -24 -38
    -69 -56 -100 -17 -32 -43 -79 -58 -107 -14 -27 -41 -76 -58 -107 -18 -31 -44
    -78 -58 -105 -58 -106 -66 -118 -82 -121 -9 -2 -27 4 -41 13 -21 14 -25 22
    -20 44 4 14 12 33 18 41 6 8 20 33 30 55 10 22 26 54 34 70 12 22 17 58 17
    136 0 99 -2 108 -28 147 -38 59 -206 183 -317 235 -8 4 -33 18 -55 30 -66 38
    -148 83 -320 177 -27 15 -77 42 -110 60 -66 36 -142 77 -222 121 -29 16 -77
    42 -105 58 -29 16 -71 39 -93 51 -22 12 -58 31 -80 44 -74 41 -155 86 -210
    116 -30 17 -80 44 -110 60 -30 17 -80 44 -110 60 -30 17 -79 43 -107 59 -29
    15 -84 46 -123 66 -38 21 -92 51 -120 67 -27 16 -63 36 -80 44 -16 8 -57 30
    -90 49 -91 52 -294 152 -345 169 -151 52 -289 -28 -414 -241 -40 -67 -56 -75
    -93 -44 -24 20 -29 40 -14 56 4 6 25 42 46 80 21 39 50 93 65 120 59 109 94
    172 120 220 15 28 38 68 50 90 12 22 31 58 44 80 58 104 99 178 176 320 15 28
    41 75 57 105 67 123 106 194 117 214 6 12 19 33 29 48 l18 27 35 -21 c19 -11
    34 -26 34 -33z m-32862 17 c9 -10 48 -78 86 -152 37 -74 96 -189 130 -255 35
    -66 105 -201 156 -300 51 -99 114 -222 141 -272 27 -51 49 -103 49 -117 0 -23
    -3 -24 -97 -30 -87 -5 -322 -10 -364 -7 -9 1 -42 52 -83 134 -38 72 -96 186
    -130 252 -34 66 -109 210 -165 320 -57 110 -111 208 -120 219 -15 18 -29 18
    -270 12 -139 -3 -257 -8 -261 -11 -4 -2 5 -42 21 -87 16 -46 29 -92 29 -102 0
    -11 6 -42 14 -70 38 -135 50 -240 50 -446 -1 -207 -10 -284 -50 -411 -7 -26
    -14 -53 -14 -61 0 -34 -108 -261 -166 -348 -68 -103 -197 -237 -283 -294 -29
    -20 -55 -36 -57 -36 -2 0 -20 -12 -41 -26 -113 -77 -286 -137 -463 -159 -121
    -16 -339 -5 -450 21 -110 26 -184 47 -210 59 -14 7 -41 19 -60 27 -56 24 -123
    58 -152 76 -15 9 -54 34 -88 54 -158 97 -324 273 -443 471 -26 43 -47 80 -47
    84 0 3 -12 31 -26 61 -60 128 -88 265 -73 367 24 171 101 259 223 258 73 -1
    124 -30 168 -95 45 -66 48 -95 42 -343 -6 -214 -5 -234 14 -295 28 -91 40
    -116 94 -196 73 -110 193 -210 308 -258 25 -10 59 -25 77 -32 17 -8 40 -14 51
    -14 11 0 39 -7 62 -15 87 -31 309 -13 425 35 166 68 310 175 416 309 45 57
    139 221 139 243 0 9 4 19 9 22 5 3 18 36 29 73 62 205 78 346 62 548 -6 77
    -17 163 -25 190 -7 28 -21 79 -30 115 -9 36 -23 82 -31 103 -8 20 -20 54 -28
    75 -8 20 -35 88 -61 149 -26 62 -45 119 -42 127 7 18 211 26 857 36 261 3 517
    7 568 8 76 2 95 -1 110 -16z m31801 -1956 c75 -55 165 -121 200 -146 34 -25
    157 -115 272 -200 116 -85 241 -177 278 -204 66 -48 68 -51 59 -79 -19 -54
    -45 -55 -150 -4 -194 95 -324 134 -478 143 -140 8 -261 -10 -390 -58 -19 -7
    -84 -42 -144 -78 -171 -102 -317 -261 -378 -413 -65 -159 -63 -312 6 -477 24
    -59 45 -89 96 -140 163 -163 361 -178 703 -54 187 69 266 102 557 236 148 69
    238 109 405 182 54 24 90 38 115 46 14 5 50 18 80 30 127 49 280 80 405 80 82
    0 221 -22 255 -40 10 -5 28 -10 40 -10 19 0 110 -41 181 -81 215 -124 383
    -365 430 -619 20 -107 25 -281 11 -365 -15 -92 -37 -177 -57 -220 -7 -16 -23
    -54 -35 -84 -28 -68 -118 -216 -184 -301 -96 -124 -172 -194 -391 -358 -243
    -182 -289 -222 -312 -270 -28 -58 -19 -98 39 -173 33 -44 35 -65 7 -95 -27
    -28 -32 -26 -149 60 -52 38 -115 85 -140 102 -45 33 -501 368 -575 424 -22 16
    -59 43 -83 60 -52 38 -59 53 -38 86 26 38 41 39 114 4 139 -67 188 -85 364
    -135 45 -13 88 -16 210 -12 143 4 216 16 283 46 11 5 40 17 65 27 25 10 52 24
    61 31 8 7 19 13 23 13 26 0 166 107 246 189 167 168 252 326 281 516 20 139
    -23 316 -103 418 -158 202 -394 263 -698 180 -49 -13 -145 -49 -238 -89 -93
    -39 -117 -50 -212 -92 -128 -57 -168 -75 -195 -87 -78 -36 -147 -66 -230 -100
    -49 -20 -103 -43 -120 -50 -46 -20 -127 -50 -225 -84 -49 -17 -169 -46 -272
    -65 -141 -26 -320 -9 -462 46 -129 50 -185 87 -312 209 -53 51 -154 188 -154
    209 0 7 -6 24 -14 38 -30 57 -45 100 -62 177 -26 109 -26 361 -1 455 17 62 24
    82 64 183 9 23 46 91 83 151 93 156 285 346 445 443 220 132 254 161 278 233
    13 40 13 49 -1 85 -8 22 -28 56 -44 76 -34 44 -34 51 -6 89 16 22 27 28 41 24
    11 -3 81 -52 156 -108z m-30799 -182 c245 -57 373 -120 500 -245 161 -159 230
    -330 230 -575 0 -210 -51 -409 -161 -629 -70 -139 -68 -136 -137 -237 -121
    -178 -306 -371 -517 -541 -113 -91 -348 -251 -445 -304 -30 -17 -85 -47 -121
    -66 -37 -20 -120 -60 -185 -88 -526 -225 -1003 -231 -1357 -17 -258 156 -400
    406 -399 700 1 152 50 355 126 523 8 17 17 37 20 45 3 8 14 29 24 45 10 17 38
    66 62 110 91 170 361 488 522 615 31 25 95 75 142 112 84 66 243 178 252 178
    3 0 20 11 37 24 18 13 68 43 112 66 44 24 94 51 111 61 71 39 314 136 409 163
    33 10 80 24 105 32 25 8 59 14 75 14 17 0 46 5 65 11 41 13 259 25 380 21 47
    -1 114 -9 150 -18z m2004 -2168 c138 -142 269 -277 291 -300 51 -52 517 -536
    610 -634 39 -40 73 -82 77 -92 10 -26 -38 -66 -132 -112 -99 -47 -283 -135
    -395 -188 -49 -23 -141 -67 -202 -96 -62 -30 -164 -78 -225 -108 -62 -29 -153
    -73 -203 -96 -49 -23 -141 -67 -202 -96 -62 -30 -164 -78 -225 -108 -62 -29
    -153 -73 -203 -96 -49 -23 -139 -66 -200 -95 -60 -29 -144 -68 -185 -88 -41
    -19 -136 -64 -210 -100 -74 -35 -173 -83 -220 -105 -248 -117 -315 -149 -425
    -202 -66 -32 -165 -79 -220 -105 -55 -26 -119 -56 -143 -68 l-43 -21 -99 101
    -99 100 22 19 c12 11 38 27 57 36 79 37 294 140 425 203 77 37 192 92 255 122
    63 31 174 84 245 118 72 35 186 90 255 123 69 33 181 87 250 120 320 154 414
    199 530 254 69 33 184 88 255 123 72 34 177 85 235 113 302 144 467 223 480
    231 27 15 22 39 -17 78 -21 21 -146 151 -278 288 -359 374 -442 456 -530 521
    -83 61 -122 81 -216 108 -79 23 -307 22 -390 -1 l-62 -17 -18 23 c-32 39 -24
    55 39 80 31 13 90 36 130 52 39 16 107 43 150 60 42 17 91 36 107 44 17 7 50
    20 75 30 25 10 59 23 75 30 85 37 184 75 215 84 33 8 37 7 74 -33 22 -22 153
    -158 290 -300z m27274 -296 c167 -19 360 -65 477 -114 72 -31 111 -49 125 -57
    8 -4 35 -18 60 -30 110 -54 316 -205 438 -323 63 -59 224 -243 258 -294 138
    -206 144 -216 214 -367 46 -98 70 -168 104 -300 68 -262 66 -625 -4 -910 -20
    -79 -58 -181 -108 -285 -115 -242 -300 -462 -542 -645 -55 -41 -101 -75 -104
    -75 -2 0 -37 -19 -78 -43 -95 -56 -244 -118 -358 -150 -163 -46 -209 -51 -455
    -51 -193 0 -256 3 -320 18 -228 50 -401 116 -583 221 -235 135 -533 414 -694
    649 -75 109 -96 145 -149 256 -22 47 -46 96 -53 110 -14 28 -68 200 -86 275
    -61 253 -49 634 27 890 38 129 109 294 163 380 13 22 29 48 35 57 63 104 126
    180 254 308 117 116 128 125 236 204 113 82 311 180 414 205 20 5 50 14 66 20
    147 50 451 73 663 51z m-24826 -1988 c15 -9 82 -51 148 -92 67 -41 134 -83
    148 -92 15 -10 36 -22 47 -28 11 -6 32 -18 47 -28 15 -9 82 -51 148 -92 67
    -41 134 -83 148 -92 15 -10 36 -22 47 -28 11 -6 32 -18 47 -28 15 -9 67 -41
    115 -71 210 -130 528 -341 543 -360 3 -4 37 -32 75 -63 164 -130 293 -297 351
    -453 47 -127 57 -188 57 -340 0 -118 -3 -158 -16 -189 -10 -21 -17 -45 -17
    -53 0 -27 -87 -190 -136 -254 -137 -179 -311 -284 -535 -324 -147 -26 -272
    -17 -464 31 -147 38 -175 40 -175 18 0 -30 39 -575 65 -894 8 -107 15 -219 15
    -249 0 -112 48 -461 76 -550 8 -25 14 -50 14 -58 0 -20 59 -142 98 -201 44
    -68 142 -175 209 -231 29 -24 53 -51 53 -60 -1 -9 -11 -28 -23 -44 l-23 -27
    -127 77 c-70 43 -137 84 -150 91 -12 7 -101 62 -197 122 -96 60 -207 128 -247
    152 -39 23 -76 50 -82 59 -5 10 -13 59 -16 111 -7 113 -30 418 -50 663 -9 99
    -15 198 -15 220 0 22 -6 119 -15 215 -26 301 -36 440 -46 606 -14 247 -4 227
    -140 309 -63 39 -129 80 -146 93 -40 28 -59 28 -75 -3 -12 -23 -143 -236 -180
    -293 -10 -15 -22 -36 -28 -47 -6 -11 -18 -32 -28 -47 -9 -14 -51 -81 -92 -148
    -41 -66 -83 -133 -92 -148 -10 -15 -22 -36 -28 -47 -12 -23 -25 -44 -61 -100
    -14 -22 -29 -47 -32 -55 -4 -8 -16 -28 -27 -45 -11 -16 -24 -40 -30 -52 -5
    -13 -19 -42 -30 -65 -24 -47 -50 -137 -50 -169 0 -50 33 -129 71 -173 41 -46
    150 -136 166 -136 4 -1 21 -12 38 -25 25 -21 28 -28 20 -51 -18 -50 -39 -54
    -97 -16 -15 9 -82 51 -148 92 -67 41 -134 83 -148 92 -15 10 -36 22 -47 28
    -11 6 -32 18 -47 28 -15 9 -82 51 -148 92 -67 41 -134 83 -148 92 -15 10 -36
    22 -47 28 -11 6 -32 18 -47 28 -15 9 -82 51 -148 92 -67 41 -134 83 -148 92
    -15 10 -36 22 -47 28 -11 6 -33 19 -50 30 -16 11 -36 23 -42 26 -20 10 -15 40
    10 70 l23 27 45 -29 c145 -93 246 -124 343 -103 81 17 122 44 186 124 52 65
    149 205 163 235 4 8 15 27 25 42 24 39 194 314 210 341 7 12 21 34 30 49 9 15
    51 82 92 148 41 67 83 134 92 148 10 15 22 36 28 47 6 11 18 32 28 47 9 15 51
    82 92 148 41 67 83 134 92 148 10 15 22 36 28 47 6 11 18 32 28 47 9 15 51 82
    92 148 41 67 83 134 92 148 10 15 22 36 28 47 6 11 18 32 28 47 9 15 51 82 92
    148 41 67 83 134 92 148 96 152 178 337 178 404 0 42 -54 148 -100 196 -23 23
    -73 63 -113 89 -90 58 -100 69 -85 97 26 49 39 48 120 -2z m21918 -3 c36 -52
    146 -204 308 -424 50 -70 92 -135 92 -146 0 -12 -14 -26 -38 -38 l-39 -20 -59
    61 c-73 74 -196 168 -249 190 -22 9 -64 22 -93 29 -101 24 -253 -16 -387 -101
    -16 -11 -37 -23 -45 -27 -8 -4 -26 -14 -40 -24 -49 -34 -325 -214 -334 -218
    -4 -2 -37 -23 -72 -47 -35 -24 -66 -44 -68 -44 -3 0 -44 -26 -93 -58 -48 -33
    -127 -84 -175 -116 -47 -31 -88 -62 -91 -69 -2 -6 6 -25 18 -42 12 -16 24 -34
    26 -40 2 -5 31 -50 63 -100 33 -49 84 -128 115 -175 31 -47 58 -89 61 -95 3
    -5 37 -57 75 -115 39 -58 72 -109 75 -115 3 -5 31 -49 63 -97 31 -49 82 -127
    113 -175 129 -200 138 -213 157 -213 10 0 77 39 150 88 73 48 137 89 142 92 6
    3 37 23 70 45 33 22 68 44 77 50 123 72 307 201 382 266 77 67 136 182 136
    263 0 53 -36 178 -74 259 -20 42 -36 81 -36 85 0 5 14 18 31 31 25 19 33 20
    46 9 14 -11 201 -288 212 -312 2 -4 23 -37 47 -72 24 -35 44 -66 44 -69 0 -2
    22 -37 49 -77 44 -66 138 -210 264 -405 26 -40 47 -77 47 -83 0 -16 -41 -49
    -60 -50 -9 0 -54 39 -100 88 -141 145 -186 172 -302 179 -52 4 -83 0 -130 -17
    -109 -37 -157 -64 -433 -246 -49 -32 -94 -61 -100 -63 -5 -2 -38 -24 -73 -48
    -35 -23 -65 -43 -66 -43 -14 0 -202 -135 -208 -149 -4 -12 2 -31 17 -52 13
    -19 49 -74 81 -124 82 -129 218 -338 275 -423 27 -40 49 -75 49 -77 0 -3 20
    -34 44 -69 24 -35 45 -68 47 -73 17 -40 227 -338 264 -373 l46 -45 74 0 c55 1
    79 5 93 18 10 9 23 17 28 17 6 0 40 20 77 44 38 25 71 46 75 48 4 2 48 30 97
    62 50 33 122 79 160 104 228 146 413 278 470 336 96 97 155 223 190 411 15 74
    17 128 13 302 -5 210 -5 212 17 233 28 26 69 23 73 -5 1 -11 9 -47 18 -80 9
    -33 22 -85 29 -115 7 -30 21 -84 30 -120 9 -36 23 -90 30 -120 7 -30 19 -75
    26 -100 7 -25 16 -63 20 -85 10 -50 26 -115 47 -183 18 -57 12 -78 -24 -97
    -11 -5 -51 -31 -89 -56 -39 -25 -137 -90 -220 -144 -82 -53 -183 -119 -223
    -146 -40 -27 -75 -49 -77 -49 -3 0 -34 -20 -69 -44 -35 -24 -68 -45 -72 -47
    -5 -2 -67 -43 -139 -90 -71 -47 -161 -106 -200 -131 -38 -24 -110 -71 -160
    -104 -49 -32 -94 -61 -100 -64 -5 -3 -68 -44 -140 -91 -71 -47 -161 -106 -200
    -131 -38 -24 -110 -71 -160 -104 -49 -32 -93 -61 -98 -62 -4 -2 -57 -37 -118
    -78 -61 -41 -113 -74 -116 -74 -2 0 -20 -12 -39 -26 -37 -29 -55 -27 -79 9
    -18 28 -11 53 19 69 46 24 153 113 182 152 60 79 69 105 68 191 0 96 -19 139
    -140 328 -24 37 -64 97 -87 135 -24 37 -70 108 -103 157 -32 50 -61 95 -64
    100 -3 6 -36 57 -75 115 -38 58 -72 110 -75 115 -3 6 -32 51 -64 100 -33 50
    -80 122 -104 160 -25 39 -84 129 -131 200 -47 72 -88 135 -91 140 -3 6 -32 51
    -64 100 -33 50 -80 122 -104 160 -25 39 -84 129 -131 200 -47 72 -88 135 -91
    140 -29 61 -220 325 -275 380 -62 62 -77 72 -126 83 -42 10 -68 10 -110 1 -95
    -20 -288 -128 -289 -161 0 -16 -65 -58 -89 -58 -21 0 -132 -74 -179 -119 -55
    -53 -76 -87 -92 -152 -10 -38 -8 -57 9 -119 28 -108 60 -197 116 -325 7 -16
    27 -66 45 -110 18 -44 39 -95 46 -112 8 -18 20 -48 28 -65 7 -18 28 -69 46
    -113 18 -44 38 -93 45 -110 7 -16 28 -66 45 -110 18 -44 39 -95 46 -112 8 -18
    20 -48 28 -65 7 -18 28 -69 46 -113 18 -44 38 -93 45 -110 8 -16 34 -82 60
    -145 26 -63 52 -128 60 -145 7 -16 34 -81 59 -142 25 -62 67 -164 92 -225 54
    -132 66 -161 86 -208 9 -19 30 -71 48 -115 85 -211 148 -332 201 -385 41 -41
    54 -48 116 -60 96 -18 190 -5 303 40 80 32 89 31 105 -12 12 -37 12 -38 -20
    -58 -18 -11 -37 -20 -42 -20 -6 0 -44 -14 -84 -31 -121 -52 -138 -58 -171 -73
    -18 -7 -69 -28 -113 -46 -44 -18 -93 -38 -110 -45 -16 -8 -82 -35 -145 -60
    -63 -26 -128 -53 -145 -60 -16 -8 -82 -34 -145 -60 -63 -26 -128 -52 -145 -60
    -16 -7 -66 -27 -110 -45 -44 -17 -92 -38 -107 -46 -48 -25 -69 -15 -82 38 -6
    27 -3 29 99 79 137 66 213 136 240 222 16 52 17 66 5 130 -15 87 -46 182 -109
    337 -13 30 -43 105 -68 165 -25 61 -51 124 -58 140 -8 17 -34 82 -60 145 -26
    63 -52 129 -60 145 -7 17 -27 66 -45 110 -31 77 -44 109 -74 178 -8 17 -17 40
    -20 50 -7 21 -25 22 -73 1 -67 -30 -96 -42 -213 -89 -63 -26 -128 -53 -145
    -60 -16 -8 -82 -34 -145 -60 -63 -26 -128 -52 -145 -60 -16 -7 -66 -27 -110
    -45 -44 -18 -96 -39 -115 -48 -48 -21 -82 -35 -175 -72 -44 -18 -93 -38 -110
    -45 -74 -32 -252 -104 -272 -110 -43 -12 -39 -36 23 -182 35 -81 44 -103 74
    -178 18 -44 38 -93 45 -110 8 -16 34 -82 60 -145 26 -63 52 -128 60 -145 7
    -16 34 -82 60 -145 60 -147 64 -156 120 -272 63 -132 93 -170 156 -202 46 -22
    66 -26 143 -26 89 0 124 8 256 55 43 16 45 16 62 -5 34 -42 24 -64 -39 -89
    -32 -13 -71 -29 -88 -36 -16 -7 -82 -34 -145 -60 -63 -26 -128 -52 -145 -60
    -16 -7 -66 -27 -110 -45 -44 -18 -95 -39 -112 -46 -18 -8 -48 -20 -65 -28 -18
    -7 -69 -28 -113 -46 -44 -18 -93 -38 -110 -45 -16 -8 -82 -34 -145 -60 -63
    -26 -128 -52 -145 -60 -150 -65 -154 -66 -175 -45 -11 11 -20 29 -20 39 0 22
    25 51 43 51 39 0 194 95 245 150 32 35 72 126 72 164 -1 71 -73 291 -165 501
    -7 17 -27 66 -45 110 -18 44 -39 95 -46 113 -8 17 -20 47 -28 65 -7 17 -28 68
    -46 112 -18 44 -38 94 -45 110 -8 17 -34 82 -60 145 -26 63 -52 129 -60 145
    -7 17 -27 66 -45 110 -18 44 -39 95 -46 113 -8 17 -20 47 -28 65 -7 17 -28 68
    -46 112 -18 44 -38 94 -45 110 -7 17 -28 66 -45 110 -18 44 -39 95 -46 113 -8
    17 -20 47 -28 65 -7 17 -28 68 -46 112 -18 44 -38 94 -45 110 -8 17 -37 86
    -66 155 -139 335 -192 390 -373 390 -84 0 -143 -12 -221 -45 -53 -23 -66 -25
    -86 -15 -26 14 -27 78 -1 86 9 3 31 11 47 18 17 8 66 28 110 46 93 37 127 51
    175 72 19 9 71 30 115 48 44 18 94 38 110 45 17 8 82 34 145 60 63 26 129 52
    145 60 17 7 66 27 110 45 44 18 94 38 110 45 71 31 253 104 281 113 29 9 32 8
    47 -23 20 -42 10 -56 -63 -86 -30 -12 -62 -27 -70 -34 -8 -6 -28 -17 -44 -25
    -16 -8 -57 -45 -91 -82 -54 -58 -64 -75 -74 -125 -15 -80 0 -151 71 -333 16
    -41 36 -88 43 -105 7 -16 27 -66 45 -110 18 -44 38 -93 45 -110 8 -16 34 -82
    60 -145 26 -63 52 -128 60 -145 7 -16 27 -66 45 -110 18 -44 38 -93 45 -110 8
    -16 23 -55 35 -85 36 -90 31 -90 210 -13 51 23 86 37 175 73 44 17 94 38 110
    45 17 7 66 27 110 45 44 18 95 39 113 46 17 8 47 20 65 28 17 7 68 28 112 46
    44 18 94 38 110 45 17 8 82 34 145 60 63 26 129 52 145 60 17 7 66 27 110 45
    163 66 165 68 154 97 -12 33 -76 188 -94 228 -7 17 -27 66 -45 110 -18 44 -38
    94 -45 110 -8 17 -34 82 -60 145 -26 63 -52 129 -60 145 -7 17 -36 86 -65 155
    -126 303 -179 375 -286 398 -108 22 -202 10 -333 -43 -35 -14 -66 -24 -69 -20
    -3 3 -11 21 -18 40 -13 40 -6 47 86 85 30 12 69 27 85 35 17 7 81 34 143 59
    61 25 163 67 225 92 61 25 126 52 142 59 17 8 82 34 145 60 63 26 129 52 145
    60 17 7 66 27 110 45 83 33 122 50 178 74 17 8 42 17 54 21 12 3 36 22 53 42
    26 29 107 92 137 105 4 1 49 30 98 62 50 33 122 80 160 104 39 25 129 84 200
    131 72 47 135 88 140 91 6 3 37 23 70 45 33 22 65 42 70 45 10 5 85 54 320
    209 72 47 134 88 139 90 4 2 37 23 72 47 35 24 66 44 69 44 3 0 34 20 69 44
    35 24 68 45 72 47 5 2 68 43 139 90 250 165 310 204 320 208 5 2 38 24 73 48
    35 23 66 43 70 43 4 1 21 12 37 25 17 14 37 25 45 24 8 0 31 -22 50 -50z
    m-17865 -2199 c39 -6 93 -13 120 -16 72 -7 250 -53 313 -80 18 -8 38 -14 44
    -14 10 0 36 -11 168 -73 137 -64 306 -183 420 -298 72 -71 197 -230 244 -309
    36 -61 156 -306 156 -319 0 -6 6 -25 14 -43 7 -18 21 -58 29 -88 8 -30 21 -75
    28 -100 7 -25 16 -70 20 -100 4 -30 12 -95 19 -145 19 -137 5 -369 -32 -525
    -37 -159 -48 -201 -69 -260 -84 -239 -209 -475 -344 -650 -60 -78 -201 -221
    -270 -275 -27 -22 -52 -42 -55 -45 -9 -11 -121 -85 -178 -117 -124 -71 -234
    -121 -382 -172 -135 -47 -345 -81 -505 -81 -212 0 -498 60 -670 140 -22 10
    -67 31 -100 46 -325 149 -619 445 -774 779 -18 39 -38 79 -43 90 -6 11 -15 33
    -19 50 -4 16 -18 62 -32 100 -45 132 -50 159 -77 385 -37 323 74 814 262 1158
    23 42 47 86 53 97 55 102 190 270 307 383 57 55 230 192 242 192 3 0 25 13 49
    30 166 112 470 221 697 249 47 6 96 13 110 15 52 7 188 5 255 -4z m12079 -672
    c2 -7 11 -41 20 -75 9 -35 23 -91 32 -125 38 -145 43 -166 59 -228 16 -65 30
    -118 58 -220 9 -30 18 -73 22 -96 6 -39 4 -42 -24 -53 -45 -19 -61 -11 -83 44
    -27 68 -48 117 -59 135 -5 8 -14 26 -19 39 -6 13 -43 56 -83 96 -53 52 -93 81
    -152 110 l-80 38 -155 0 c-109 1 -176 -4 -225 -16 -39 -9 -106 -23 -150 -32
    -44 -9 -107 -23 -140 -30 -33 -8 -77 -16 -98 -19 -73 -10 -76 -23 -42 -171 13
    -58 29 -138 62 -295 8 -41 21 -102 28 -135 7 -33 21 -98 30 -145 9 -47 23
    -112 30 -145 7 -33 21 -96 30 -140 9 -44 22 -109 29 -145 8 -36 21 -99 29
    -140 23 -109 47 -226 62 -295 7 -33 21 -96 30 -140 9 -44 22 -109 29 -145 8
    -36 21 -100 30 -142 34 -165 49 -235 61 -293 14 -62 35 -166 62 -295 19 -95
    41 -186 54 -225 6 -16 14 -43 19 -60 14 -54 63 -136 99 -166 71 -58 171 -71
    333 -43 87 15 93 15 105 -2 7 -10 13 -30 13 -46 0 -22 -5 -29 -27 -33 -16 -4
    -57 -12 -93 -20 -36 -8 -101 -21 -145 -30 -44 -9 -107 -23 -140 -30 -33 -7
    -98 -21 -145 -30 -47 -9 -112 -23 -145 -30 -33 -7 -95 -20 -137 -29 -43 -9
    -113 -23 -155 -32 -43 -9 -105 -22 -138 -29 -33 -7 -98 -21 -145 -30 -47 -9
    -103 -21 -125 -26 -77 -17 -78 -17 -86 21 -10 45 9 66 64 75 97 15 198 57 257
    109 82 70 103 157 81 330 -7 50 -19 121 -27 159 -13 63 -20 96 -58 280 -34
    164 -49 234 -61 292 -7 33 -21 96 -30 140 -9 44 -22 109 -29 145 -8 36 -21
    100 -30 143 -34 164 -49 234 -61 292 -7 33 -21 96 -30 140 -9 44 -22 109 -29
    145 -8 36 -21 99 -29 140 -35 167 -49 238 -62 295 -7 33 -21 98 -30 145 -9 47
    -23 112 -30 145 -7 33 -21 96 -30 140 -21 106 -47 218 -51 226 -2 4 -30 1 -61
    -6 -32 -8 -89 -20 -128 -28 -342 -71 -445 -98 -529 -137 -59 -28 -164 -131
    -194 -190 -13 -25 -27 -52 -31 -60 -32 -56 -44 -129 -45 -263 l-1 -141 -30 -8
    c-16 -4 -37 -5 -46 -3 -18 6 -39 73 -39 122 0 15 -6 64 -14 108 -8 44 -22 127
    -31 185 -17 110 -41 263 -54 342 -7 46 -7 48 23 62 17 8 49 17 71 21 22 3 69
    12 105 20 36 8 101 21 145 30 44 9 107 23 140 30 33 7 98 21 145 30 47 9 112
    23 145 30 33 7 95 20 138 29 42 9 112 23 155 32 42 9 104 22 137 29 33 7 98
    21 145 30 47 9 112 23 145 30 33 7 95 20 138 29 42 9 112 23 155 32 42 9 104
    22 137 29 33 7 98 21 145 30 47 9 112 23 145 30 33 7 96 21 140 30 220 45 348
    72 400 84 58 13 95 13 99 -1z m-9874 -147 c30 -5 100 -17 155 -26 55 -9 134
    -22 175 -30 72 -13 134 -23 355 -60 55 -9 134 -23 175 -30 41 -7 120 -21 175
    -30 296 -49 349 -58 515 -90 140 -27 290 -66 395 -102 39 -13 84 -29 102 -34
    18 -5 35 -14 38 -19 4 -6 14 -10 24 -10 10 0 24 -7 31 -15 7 -8 20 -15 30 -15
    10 0 23 -7 30 -15 7 -8 18 -15 25 -15 46 0 264 -184 353 -297 44 -57 116 -204
    139 -283 23 -83 26 -347 4 -425 -22 -81 -62 -167 -112 -246 -38 -58 -184 -199
    -235 -226 -100 -53 -165 -83 -181 -83 -10 -1 -29 -7 -43 -15 -14 -8 -37 -14
    -51 -15 -36 0 -86 -19 -92 -34 -3 -8 15 -22 51 -37 32 -12 91 -43 132 -67 220
    -131 329 -261 413 -497 18 -52 21 -82 21 -220 0 -165 -3 -179 -56 -326 -69
    -188 -293 -411 -500 -496 -112 -46 -134 -53 -258 -73 -236 -40 -490 -20 -1105
    85 -44 7 -125 21 -180 30 -55 9 -134 22 -175 30 -72 13 -134 23 -355 60 -55 9
    -134 23 -175 30 -41 7 -120 21 -175 30 -55 9 -125 21 -155 27 -30 6 -84 14
    -119 18 -35 5 -68 14 -72 22 -14 22 -10 47 10 67 17 17 25 18 63 9 23 -5 93
    -10 153 -10 100 0 115 2 163 26 57 30 88 56 115 98 43 68 84 230 132 523 16
    98 27 164 60 355 9 52 23 136 31 185 9 50 21 126 29 170 7 44 21 125 30 180 9
    55 22 134 30 175 13 72 23 134 60 355 9 55 23 134 30 175 7 41 21 120 30 175
    32 196 45 271 60 355 20 114 20 318 0 368 -42 99 -154 168 -318 197 -101 18
    -114 29 -93 80 13 31 20 36 38 32 13 -3 48 -10 78 -16z m5425 -452 c124 -24
    236 -56 310 -88 57 -25 174 -84 200 -101 17 -11 37 -23 45 -27 26 -11 197
    -142 260 -199 203 -181 377 -436 475 -694 33 -87 43 -115 55 -165 80 -327 92
    -659 34 -970 -21 -110 -66 -270 -90 -317 -8 -14 -14 -31 -14 -37 0 -12 -56
    -132 -97 -206 -135 -245 -381 -504 -618 -652 -66 -41 -203 -113 -235 -124 -14
    -4 -50 -17 -80 -29 -30 -12 -71 -26 -90 -30 -19 -5 -53 -13 -75 -19 -97 -25
    -208 -41 -315 -46 -130 -5 -374 7 -435 23 -22 6 -65 16 -95 23 -69 15 -179 51
    -245 79 -86 36 -195 93 -207 107 -6 7 -14 13 -18 13 -27 0 -193 133 -301 240
    -105 105 -249 275 -249 294 0 3 -17 34 -38 68 -21 35 -48 86 -61 113 -12 28
    -27 59 -33 70 -28 50 -92 247 -108 330 -45 233 -52 285 -57 476 -10 333 54
    639 193 924 28 56 29 58 100 170 123 194 298 369 524 526 114 79 353 186 468
    210 24 5 47 11 52 14 5 3 46 12 92 21 182 35 483 36 653 3z"/>
    <path d="M31840 51903 c-41 -2 -80 -9 -87 -15 -7 -6 -13 -31 -13 -57 0 -25 -6
    -127 -15 -226 -8 -99 -22 -272 -30 -385 -8 -113 -20 -263 -26 -335 -9 -120 -9
    -130 8 -143 14 -11 60 -13 218 -9 202 5 204 5 360 54 34 11 138 75 173 107 72
    65 134 162 168 261 24 73 24 281 0 355 -31 94 -77 169 -141 231 -77 75 -122
    102 -237 139 -61 20 -239 31 -378 23z"/>
    <path d="M28654 51412 c-3 -15 -11 -43 -19 -62 -7 -19 -48 -143 -90 -275 -42
    -132 -83 -256 -90 -275 -27 -76 -37 -106 -45 -135 -5 -16 -14 -46 -21 -65 -6
    -19 -17 -51 -24 -70 -7 -19 -18 -51 -24 -70 -13 -35 -28 -87 -38 -127 -5 -22
    -3 -23 68 -23 69 0 165 8 539 46 80 8 168 14 196 14 89 0 114 9 114 39 0 15
    -4 31 -9 36 -8 9 -59 104 -169 315 -24 47 -60 114 -79 150 -19 36 -65 124
    -103 195 -38 72 -84 159 -104 195 -20 36 -40 76 -46 90 -6 14 -19 31 -30 38
    -17 11 -20 9 -26 -16z"/>
    <path d="M26040 51443 c-108 -16 -292 -87 -303 -115 -5 -15 4 -77 23 -153 6
    -27 18 -75 25 -105 7 -30 18 -77 25 -105 7 -27 19 -79 27 -115 8 -36 18 -81
    23 -100 18 -78 41 -178 54 -235 22 -97 60 -251 72 -293 3 -9 30 -11 116 -6
    149 8 305 44 349 81 8 7 19 13 23 13 14 0 96 77 130 122 91 121 129 282 102
    443 -15 93 -59 228 -82 256 -8 8 -14 18 -14 21 0 17 -72 110 -120 154 -119
    111 -288 162 -450 137z"/>
    <path d="M41365 49012 c-13 -9 -58 -77 -85 -127 -6 -11 -18 -32 -28 -47 -9
    -14 -44 -70 -77 -123 -33 -53 -67 -107 -75 -120 -8 -13 -49 -79 -90 -145 -41
    -67 -83 -134 -92 -148 -10 -15 -22 -36 -28 -47 -5 -11 -35 -58 -65 -105 -30
    -47 -54 -92 -55 -101 0 -18 51 -53 165 -114 39 -21 75 -41 80 -45 6 -4 19 -10
    30 -13 11 -3 45 -15 75 -27 101 -39 264 -52 381 -30 65 13 152 54 217 103 51
    38 162 177 162 201 0 7 5 17 11 23 23 23 49 120 55 203 6 89 -5 181 -27 240
    -17 44 -74 136 -108 174 -35 38 -156 127 -211 155 -25 12 -52 26 -60 31 -12 7
    -113 50 -155 66 -5 2 -15 0 -20 -4z"/>
    <path d="M30635 47210 c-27 -5 -230 -9 -450 -9 -307 -1 -420 -4 -485 -16 -47
    -8 -141 -15 -210 -15 -69 0 -163 -7 -210 -15 -47 -8 -114 -15 -150 -15 -36 0
    -103 -7 -150 -15 -47 -8 -105 -15 -130 -15 -25 0 -92 -6 -150 -15 -58 -8 -161
    -22 -230 -31 -256 -34 -971 -156 -1160 -198 -58 -13 -170 -38 -250 -56 -285
    -62 -773 -189 -1060 -274 -224 -68 -820 -267 -1020 -342 -247 -92 -741 -290
    -842 -337 -42 -20 -105 -48 -140 -62 -35 -15 -121 -55 -193 -90 -71 -34 -173
    -81 -225 -103 -109 -47 -465 -225 -527 -265 -23 -15 -111 -62 -195 -105 -84
    -44 -188 -100 -231 -125 -43 -26 -79 -47 -82 -47 -2 0 -66 -37 -142 -83 -76
    -46 -165 -98 -198 -116 -136 -74 -808 -514 -1040 -681 -437 -314 -1058 -804
    -1312 -1036 -45 -41 -108 -95 -140 -121 -32 -25 -134 -121 -228 -213 -93 -92
    -204 -194 -245 -228 -86 -69 -366 -350 -430 -432 -24 -30 -118 -131 -210 -225
    -92 -93 -188 -196 -213 -228 -26 -32 -80 -95 -121 -140 -232 -254 -722 -875
    -1036 -1312 -167 -232 -607 -904 -681 -1040 -18 -33 -70 -122 -116 -198 -46
    -76 -83 -140 -83 -142 0 -3 -21 -39 -47 -82 -25 -43 -81 -147 -125 -231 -43
    -84 -90 -172 -105 -195 -40 -62 -218 -418 -265 -527 -22 -52 -69 -153 -103
    -225 -35 -71 -75 -158 -90 -193 -14 -35 -42 -98 -62 -140 -47 -101 -245 -595
    -337 -842 -75 -200 -274 -796 -342 -1020 -85 -287 -212 -775 -274 -1060 -18
    -80 -43 -192 -56 -250 -42 -189 -164 -904 -198 -1160 -9 -69 -23 -172 -31
    -230 -9 -58 -15 -125 -15 -150 0 -25 -7 -83 -15 -130 -8 -47 -15 -114 -15
    -150 0 -36 -7 -103 -15 -150 -8 -47 -15 -143 -15 -215 -1 -71 -6 -150 -12
    -175 -8 -37 -29 -750 -29 -1020 1 -287 21 -1014 29 -1050 6 -25 11 -103 12
    -175 0 -71 7 -168 15 -215 8 -47 15 -114 15 -150 0 -36 7 -103 15 -150 8 -47
    15 -105 15 -130 0 -25 6 -92 15 -150 8 -58 22 -161 31 -230 34 -256 156 -971
    198 -1160 13 -58 38 -170 56 -250 62 -285 189 -773 274 -1060 68 -224 267
    -820 342 -1020 92 -247 290 -741 337 -842 20 -42 48 -105 62 -140 15 -35 55
    -121 90 -193 34 -71 81 -173 103 -225 47 -109 225 -465 265 -527 15 -23 62
    -111 105 -195 44 -84 100 -188 125 -231 26 -43 47 -79 47 -82 0 -2 37 -66 83
    -142 46 -76 98 -165 116 -198 74 -136 514 -808 681 -1040 314 -437 804 -1058
    1036 -1312 41 -45 95 -108 121 -140 25 -32 121 -134 213 -228 92 -93 186 -195
    210 -225 64 -82 344 -363 430 -432 41 -34 152 -136 245 -228 94 -92 196 -188
    228 -213 32 -26 95 -80 140 -121 254 -232 875 -722 1312 -1036 232 -167 904
    -607 1040 -681 33 -18 122 -70 198 -116 76 -46 140 -83 142 -83 3 0 39 -21 82
    -47 43 -25 147 -81 231 -125 84 -43 172 -90 195 -105 62 -40 418 -218 527
    -265 52 -22 154 -69 225 -103 72 -35 158 -75 193 -90 35 -14 98 -42 140 -62
    101 -47 595 -245 842 -337 200 -75 796 -274 1020 -342 287 -85 775 -212 1060
    -274 80 -18 192 -43 250 -56 189 -42 904 -164 1160 -198 69 -9 172 -23 230
    -31 58 -9 125 -15 150 -15 25 0 83 -7 130 -15 47 -8 114 -15 150 -15 36 0 103
    -7 150 -15 47 -8 144 -15 215 -15 72 -1 150 -6 175 -12 38 -9 751 -29 1035
    -29 284 0 997 20 1035 29 25 6 104 11 175 12 72 0 168 7 215 15 47 8 114 15
    150 15 36 0 103 7 150 15 47 8 105 15 130 15 25 0 92 6 150 15 58 8 161 22
    230 31 256 34 971 156 1160 198 58 13 170 38 250 56 285 62 773 189 1060 274
    224 68 820 267 1020 342 247 92 741 290 842 337 42 20 105 48 140 62 35 15
    122 55 193 90 72 34 173 81 225 103 109 47 465 225 527 265 23 15 111 62 195
    105 84 44 188 100 231 125 43 26 79 47 82 47 2 0 66 37 142 83 76 46 165 98
    198 116 136 74 808 514 1040 681 437 314 1058 804 1312 1036 45 41 108 95 140
    121 32 25 135 121 228 213 94 92 204 194 245 228 86 69 366 350 430 432 24 30
    118 132 210 225 92 94 188 196 213 228 26 32 80 95 121 140 232 254 722 875
    1036 1312 167 232 607 904 681 1040 18 33 70 122 116 198 46 76 83 140 83 142
    0 3 21 39 47 82 25 43 81 147 125 231 43 84 90 172 105 195 40 62 218 418 265
    527 22 52 69 154 103 225 35 72 75 158 90 193 14 35 42 98 62 140 47 101 245
    595 337 842 75 200 274 796 342 1020 85 287 212 775 274 1060 18 80 43 192 56
    250 42 189 164 904 198 1160 9 69 23 172 31 230 9 58 15 125 15 150 0 25 7 83
    15 130 8 47 15 114 15 150 0 36 7 103 15 150 8 47 15 144 15 215 1 72 6 150
    12 175 8 36 28 763 29 1050 0 270 -21 983 -29 1020 -6 25 -11 104 -12 175 0
    72 -7 168 -15 215 -8 47 -15 114 -15 150 0 36 -7 103 -15 150 -8 47 -15 105
    -15 130 0 25 -6 92 -15 150 -8 58 -22 161 -31 230 -34 256 -156 971 -198 1160
    -13 58 -38 170 -56 250 -62 285 -189 773 -274 1060 -68 224 -267 820 -342
    1020 -92 247 -290 741 -337 842 -20 42 -48 105 -62 140 -15 35 -55 122 -90
    193 -34 72 -81 173 -103 225 -47 109 -225 465 -265 527 -15 23 -62 111 -105
    195 -44 84 -100 188 -125 231 -26 43 -47 79 -47 82 0 2 -37 66 -83 142 -46 76
    -98 165 -116 198 -74 136 -514 808 -681 1040 -314 437 -804 1058 -1036 1312
    -41 45 -95 108 -121 140 -25 32 -121 135 -213 228 -92 94 -186 195 -210 225
    -64 82 -344 363 -430 432 -41 34 -151 136 -245 228 -93 92 -196 188 -228 213
    -32 26 -95 80 -140 121 -254 232 -875 722 -1312 1036 -232 167 -904 607 -1040
    681 -33 18 -122 70 -198 116 -76 46 -140 83 -142 83 -3 0 -39 21 -82 47 -43
    25 -147 81 -231 125 -84 43 -172 90 -195 105 -62 40 -418 218 -527 265 -52 22
    -153 69 -225 103 -71 35 -158 75 -193 90 -35 14 -98 42 -140 62 -101 47 -595
    245 -842 337 -200 75 -796 274 -1020 342 -287 85 -775 212 -1060 274 -80 18
    -192 43 -250 56 -189 42 -904 164 -1160 198 -69 9 -172 23 -230 31 -58 9 -125
    15 -150 15 -25 0 -83 7 -130 15 -47 8 -114 15 -150 15 -36 0 -103 7 -150 15
    -47 8 -143 15 -215 15 -71 1 -150 6 -175 12 -26 6 -245 14 -520 18 -261 5
    -488 10 -505 13 -16 3 -52 1 -80 -3z m955 -1275 c47 -8 141 -15 210 -15 69 0
    163 -7 210 -15 47 -8 119 -15 160 -15 41 0 122 -6 180 -15 58 -8 160 -21 228
    -30 272 -34 378 -49 627 -91 340 -57 526 -91 574 -104 22 -6 117 -26 211 -45
    227 -46 838 -204 1100 -284 630 -194 1375 -470 1800 -669 36 -16 94 -42 130
    -57 87 -36 943 -464 999 -499 24 -15 85 -50 137 -77 85 -45 491 -288 759 -454
    170 -106 326 -211 630 -429 341 -243 381 -273 715 -543 374 -302 418 -337 485
    -402 33 -32 94 -85 135 -119 41 -33 120 -106 175 -162 55 -56 134 -129 175
    -162 108 -88 408 -387 500 -498 43 -52 124 -140 180 -195 56 -55 129 -134 162
    -175 34 -41 87 -102 119 -135 65 -67 100 -111 402 -485 270 -334 300 -374 543
    -715 218 -304 323 -460 429 -630 166 -268 409 -674 454 -759 27 -52 62 -113
    77 -137 35 -56 463 -912 499 -999 15 -36 41 -94 57 -130 199 -425 475 -1170
    669 -1800 80 -262 238 -873 284 -1100 19 -94 39 -189 45 -211 13 -48 47 -234
    104 -574 42 -248 56 -344 91 -625 9 -69 22 -172 30 -230 9 -58 15 -139 15
    -180 0 -41 7 -113 15 -160 8 -47 15 -141 15 -210 0 -69 7 -163 15 -210 12 -71
    15 -219 15 -885 0 -666 -3 -814 -15 -885 -8 -47 -15 -141 -15 -210 0 -69 -7
    -163 -15 -210 -8 -47 -15 -119 -15 -160 0 -41 -6 -122 -15 -180 -8 -58 -21
    -159 -29 -225 -33 -265 -50 -381 -92 -630 -57 -340 -91 -526 -104 -574 -6 -22
    -26 -117 -45 -211 -46 -227 -204 -838 -284 -1100 -194 -630 -470 -1375 -669
    -1800 -16 -36 -42 -94 -57 -130 -36 -87 -464 -943 -499 -999 -15 -24 -50 -85
    -77 -137 -45 -85 -288 -491 -454 -759 -106 -170 -211 -326 -429 -630 -243
    -341 -273 -381 -543 -715 -302 -374 -337 -418 -402 -485 -32 -33 -85 -94 -119
    -135 -33 -41 -106 -120 -162 -175 -56 -55 -137 -143 -180 -195 -92 -111 -392
    -410 -500 -498 -41 -33 -120 -106 -175 -162 -55 -56 -134 -129 -175 -162 -41
    -34 -102 -87 -135 -119 -67 -65 -111 -100 -485 -402 -334 -270 -374 -300 -715
    -543 -304 -218 -460 -323 -630 -429 -268 -166 -674 -409 -759 -454 -52 -27
    -113 -62 -137 -77 -56 -35 -912 -463 -999 -499 -36 -15 -94 -41 -130 -57 -425
    -199 -1170 -475 -1800 -669 -262 -80 -873 -238 -1100 -284 -94 -19 -189 -39
    -211 -45 -48 -13 -234 -47 -574 -104 -249 -42 -355 -57 -627 -91 -68 -9 -170
    -22 -228 -30 -58 -9 -139 -15 -180 -15 -41 0 -113 -7 -160 -15 -47 -8 -141
    -15 -210 -15 -69 0 -163 -7 -210 -15 -126 -22 -1644 -22 -1770 0 -47 8 -141
    15 -210 15 -69 0 -163 7 -210 15 -47 8 -119 15 -160 15 -41 0 -122 6 -180 15
    -58 8 -160 21 -227 30 -273 34 -379 49 -628 91 -340 57 -526 91 -574 104 -22
    6 -117 26 -211 45 -227 46 -838 204 -1100 284 -630 194 -1375 470 -1800 669
    -36 16 -94 42 -130 57 -87 36 -943 464 -999 499 -24 15 -85 50 -137 77 -85 45
    -491 288 -759 454 -170 106 -326 211 -630 429 -341 243 -381 273 -715 543
    -374 302 -418 337 -485 402 -33 32 -94 85 -135 119 -41 33 -120 106 -175 162
    -55 56 -143 137 -195 180 -111 92 -410 392 -498 500 -33 41 -106 120 -162 175
    -56 55 -129 134 -162 175 -34 41 -87 102 -119 135 -65 67 -100 111 -402 485
    -270 334 -300 374 -543 715 -218 304 -323 460 -429 630 -166 268 -409 674
    -454 759 -27 52 -62 113 -77 137 -35 56 -463 912 -499 999 -15 36 -41 94 -57
    130 -199 425 -475 1170 -669 1800 -80 262 -238 873 -284 1100 -19 94 -39 189
    -45 211 -13 48 -47 234 -104 574 -42 248 -56 344 -91 625 -9 69 -22 172 -30
    230 -9 58 -15 139 -15 180 0 41 -7 113 -15 160 -8 47 -15 141 -15 210 0 69 -7
    163 -15 210 -22 126 -22 1644 0 1770 8 47 15 141 15 210 0 69 7 163 15 210 8
    47 15 119 15 160 0 41 6 122 15 180 8 58 21 160 30 228 34 272 49 378 91 627
    57 340 91 526 104 574 6 22 26 117 45 211 46 227 204 838 284 1100 194 630
    470 1375 669 1800 16 36 42 94 57 130 36 87 464 943 499 999 15 24 50 85 77
    137 45 85 288 491 454 759 106 170 211 326 429 630 243 341 273 381 543 715
    302 374 337 418 402 485 32 33 85 94 119 135 33 41 106 120 162 175 56 55 129
    134 162 175 88 108 387 408 498 500 52 43 140 124 195 180 55 56 134 129 175
    162 41 34 102 87 135 119 67 65 111 100 485 402 334 270 374 300 715 543 304
    218 460 323 630 429 268 166 674 409 759 454 52 27 113 62 137 77 56 35 912
    463 999 499 36 15 94 41 130 57 425 199 1170 475 1800 669 262 80 873 238
    1100 284 94 19 189 39 211 45 48 13 234 47 574 104 249 42 355 57 628 91 67 9
    169 22 227 30 58 9 139 15 180 15 41 0 113 7 160 15 47 8 144 15 215 16 72 0
    162 6 200 13 110 21 1657 22 1775 1z"/>
    <path d="M30485 45150 c-141 -20 -277 -107 -382 -243 -49 -63 -157 -267 -212
    -402 -19 -44 -40 -94 -47 -112 -8 -17 -14 -35 -14 -41 0 -5 -7 -23 -15 -38 -8
    -16 -15 -36 -15 -45 0 -9 -3 -18 -6 -22 -6 -6 -37 -89 -89 -242 -13 -38 -40
    -115 -60 -170 -20 -55 -39 -109 -42 -120 -4 -11 -12 -31 -20 -45 -7 -14 -13
    -31 -13 -38 0 -7 -4 -20 -9 -30 -5 -9 -26 -57 -46 -107 -45 -108 -151 -316
    -202 -394 -112 -172 -261 -327 -402 -421 -96 -64 -187 -102 -381 -159 -174
    -52 -202 -59 -270 -75 -143 -32 -181 -38 -333 -50 -265 -22 -463 9 -655 104
    -81 39 -250 149 -322 208 -72 60 -324 313 -425 427 -141 160 -177 198 -320
    340 -241 242 -342 318 -481 365 -77 25 -266 28 -340 4 -185 -59 -341 -187
    -414 -338 -61 -127 -82 -467 -59 -936 25 -490 16 -889 -21 -1056 -50 -217
    -138 -371 -308 -536 -102 -98 -173 -154 -297 -230 -213 -130 -400 -207 -605
    -250 -85 -17 -391 -17 -490 0 -196 35 -298 59 -500 117 -68 20 -400 125 -440
    140 -19 7 -82 28 -140 46 -58 18 -136 43 -175 55 -38 13 -88 27 -110 33 -22 5
    -78 19 -125 30 -398 100 -573 107 -790 30 -169 -60 -298 -243 -367 -519 -26
    -106 -26 -458 0 -575 39 -175 95 -361 157 -525 12 -30 25 -66 30 -80 4 -14 29
    -81 55 -150 129 -345 180 -519 206 -705 65 -481 -63 -982 -342 -1338 -171
    -216 -343 -351 -609 -477 -20 -9 -142 -45 -205 -59 -154 -35 -504 -51 -730
    -32 -311 26 -389 31 -601 42 -313 15 -593 -5 -661 -47 -67 -43 -120 -125 -161
    -249 -36 -110 -47 -187 -47 -340 0 -153 7 -192 51 -277 65 -126 286 -350 565
    -573 68 -54 126 -102 129 -105 3 -3 37 -30 75 -61 366 -293 570 -551 651 -824
    26 -85 26 -414 1 -530 -24 -113 -47 -206 -56 -235 -5 -14 -16 -50 -24 -80
    -108 -363 -200 -558 -351 -746 -190 -237 -472 -415 -911 -578 -38 -15 -79 -30
    -90 -35 -11 -5 -60 -23 -110 -41 -89 -31 -122 -43 -200 -73 -22 -9 -74 -28
    -115 -43 -41 -14 -88 -32 -105 -39 -16 -7 -70 -29 -120 -50 -203 -84 -467
    -236 -553 -318 -29 -28 -122 -168 -122 -183 0 -3 -6 -20 -14 -37 -23 -54 -44
    -129 -51 -189 -4 -32 -11 -58 -16 -58 -5 0 -9 -54 -9 -120 0 -66 4 -120 9
    -120 5 0 12 -19 16 -42 9 -62 62 -195 101 -253 53 -79 151 -175 204 -198 8 -4
    38 -21 65 -37 86 -52 239 -123 373 -174 23 -9 59 -23 80 -31 49 -19 329 -114
    367 -125 30 -8 69 -21 135 -46 19 -7 69 -25 110 -40 41 -15 93 -34 115 -44 22
    -9 60 -25 85 -35 84 -36 252 -125 324 -172 146 -97 324 -256 421 -378 22 -27
    48 -59 58 -70 37 -41 167 -243 206 -321 58 -117 92 -226 123 -404 18 -104 18
    -661 0 -745 -52 -237 -127 -361 -366 -601 -106 -106 -142 -140 -286 -264 -34
    -30 -68 -59 -74 -65 -6 -5 -47 -41 -91 -80 -275 -240 -467 -466 -523 -615 -38
    -99 -51 -165 -58 -290 -7 -124 7 -279 30 -346 18 -51 63 -131 96 -173 43 -53
    100 -87 180 -105 149 -35 637 -32 1080 5 174 14 667 15 760 0 197 -30 267 -48
    363 -95 268 -129 572 -496 734 -886 19 -47 39 -93 44 -102 5 -10 13 -34 18
    -55 5 -21 19 -73 30 -117 27 -102 39 -265 29 -391 -10 -134 -28 -227 -87 -450
    -15 -55 -70 -213 -146 -415 -26 -69 -51 -136 -55 -150 -5 -14 -18 -50 -30 -80
    -25 -64 -84 -241 -107 -320 -9 -30 -19 -66 -24 -80 -38 -125 -69 -347 -69
    -503 0 -247 47 -423 149 -561 47 -63 71 -84 141 -128 195 -122 531 -129 975
    -21 55 13 118 28 141 33 22 5 51 13 65 18 13 6 71 24 129 42 58 18 114 36 125
    41 23 10 453 151 535 175 30 9 66 20 80 25 22 7 109 28 245 59 244 57 509 56
    695 -2 175 -54 370 -146 515 -244 170 -114 203 -141 310 -248 189 -189 228
    -269 286 -571 17 -91 23 -508 9 -670 -13 -154 -26 -368 -36 -602 -11 -240 23
    -564 70 -674 16 -37 85 -131 121 -162 47 -42 185 -127 252 -155 201 -85 391
    -62 588 71 67 46 272 243 325 314 19 26 40 51 45 57 6 6 51 63 100 126 300
    387 427 526 589 646 91 68 247 154 278 154 8 0 18 4 23 9 11 10 147 44 250 61
    80 14 377 9 495 -9 93 -13 165 -30 395 -91 68 -18 225 -84 277 -117 21 -13 40
    -23 43 -23 7 0 160 -105 215 -148 75 -58 292 -282 351 -362 92 -126 185 -292
    253 -455 9 -22 21 -47 25 -55 5 -9 12 -30 15 -48 4 -17 12 -34 17 -37 5 -4 9
    -14 9 -23 0 -9 7 -30 15 -46 8 -15 15 -34 15 -42 0 -7 6 -28 14 -46 17 -42 35
    -94 46 -133 9 -34 29 -90 41 -120 5 -11 20 -54 33 -95 67 -208 126 -349 226
    -540 48 -90 121 -189 177 -240 84 -74 247 -150 356 -165 26 -3 47 -10 47 -16
    0 -5 67 -9 160 -9 90 0 160 4 160 9 0 5 19 12 43 16 70 10 184 59 264 111 48
    32 153 138 153 155 0 5 13 27 29 51 42 63 201 385 201 407 0 6 6 25 14 43 8
    18 29 74 46 123 18 50 36 99 40 110 5 11 32 89 60 173 28 84 55 163 60 175 5
    12 28 74 51 137 159 433 359 722 655 947 43 33 86 63 94 66 8 4 29 16 45 27
    131 85 349 156 660 217 134 25 499 25 610 -1 348 -82 590 -255 990 -706 435
    -492 621 -658 799 -714 77 -25 95 -27 212 -23 142 4 184 13 283 58 129 60 222
    151 255 250 42 126 54 590 27 1064 -22 397 -8 733 40 925 70 281 248 546 481
    718 82 60 268 161 363 196 28 10 64 24 80 31 55 22 188 61 290 84 69 16 288
    41 358 41 108 0 366 -34 487 -65 115 -29 186 -48 225 -60 22 -7 69 -22 105
    -34 65 -20 207 -69 395 -136 139 -50 348 -119 420 -140 130 -37 153 -43 225
    -59 245 -52 311 -56 435 -26 105 25 138 37 217 84 159 93 267 240 322 438 58
    207 9 550 -158 1108 -9 30 -21 69 -26 85 -10 36 -34 115 -55 185 -8 27 -22 74
    -30 103 -9 28 -23 75 -31 102 -8 28 -19 64 -23 80 -5 17 -19 66 -31 110 -13
    44 -26 98 -31 120 -4 22 -19 92 -32 155 -13 63 -30 156 -38 205 -18 117 -19
    419 -1 500 66 297 204 544 428 770 180 183 383 314 594 384 116 38 463 79 570
    66 25 -3 122 -10 217 -15 95 -6 205 -14 245 -19 419 -55 848 -49 989 12 89 39
    199 158 246 267 40 90 48 141 47 290 0 135 -2 151 -30 231 -17 47 -51 117 -76
    155 -59 88 -183 221 -301 322 -96 81 -123 103 -322 254 -357 272 -533 455
    -633 659 -55 112 -72 154 -72 174 0 9 -4 24 -9 33 -5 10 -17 81 -27 159 -16
    132 -16 150 0 289 14 131 33 236 66 379 9 35 31 114 60 210 5 17 16 53 24 80
    14 47 30 96 46 140 5 11 16 43 25 70 28 78 128 256 167 296 8 8 20 24 28 35
    69 99 270 273 405 350 156 88 330 166 495 220 58 20 114 39 125 44 11 4 88 29
    170 55 83 26 166 53 185 60 19 7 60 20 90 30 30 9 69 23 87 31 17 8 37 14 45
    14 8 0 18 4 24 8 5 5 32 17 59 27 75 27 229 102 325 159 236 139 378 382 395
    676 10 162 -12 272 -80 414 -72 150 -156 235 -337 339 -186 107 -295 152 -713
    292 -273 92 -411 140 -468 163 -16 7 -30 12 -32 12 -13 0 -232 105 -310 148
    -265 147 -495 364 -608 572 -52 95 -147 392 -190 595 -37 169 -42 225 -42 440
    0 235 5 273 56 425 81 244 261 473 589 750 36 30 67 57 70 60 3 4 48 42 100
    86 145 120 368 344 436 436 95 130 123 187 165 343 19 67 18 294 0 360 -44
    161 -151 299 -274 355 -135 61 -485 84 -937 60 -415 -22 -540 -23 -720 -6 -96
    9 -184 20 -195 23 -11 3 -47 12 -80 19 -88 18 -143 33 -163 43 -9 5 -37 17
    -62 27 -62 25 -211 116 -290 176 -87 67 -221 205 -296 303 -145 191 -232 376
    -297 635 -18 72 -21 115 -21 290 -1 199 4 253 40 425 29 141 49 221 88 355 20
    68 151 474 167 515 6 17 15 44 19 60 5 17 23 75 40 130 66 213 106 377 136
    555 26 163 28 231 9 332 -50 269 -190 484 -367 565 -116 54 -137 58 -288 57
    -84 0 -164 -6 -189 -13 -25 -7 -65 -17 -90 -21 -59 -11 -151 -34 -221 -55 -30
    -9 -68 -20 -85 -24 -35 -9 -541 -178 -570 -191 -25 -10 -200 -64 -275 -84
    -216 -58 -291 -72 -525 -97 -125 -14 -557 -6 -640 11 -281 58 -469 150 -669
    326 -308 271 -490 582 -562 964 -30 160 -35 290 -32 935 3 672 3 679 -39 870
    -32 147 -58 177 -188 217 -98 29 -412 33 -490 5 -102 -36 -175 -79 -256 -150
    -125 -110 -189 -181 -563 -634 -255 -308 -458 -488 -676 -599 -205 -104 -548
    -121 -860 -44 -258 64 -426 125 -635 230 -370 186 -603 409 -765 730 -44 87
    -130 294 -130 312 0 5 -6 24 -14 41 -7 18 -28 79 -46 137 -18 58 -38 123 -45
    145 -7 22 -28 87 -45 145 -156 511 -346 855 -521 942 -141 69 -198 81 -424 84
    -110 1 -227 -1 -260 -6z m535 -6199 c30 -6 89 -19 130 -28 110 -23 220 -61
    310 -106 283 -145 459 -385 533 -727 9 -41 21 -100 27 -130 15 -72 27 -839 39
    -2530 14 -1847 3 -3180 -30 -3860 -6 -113 -14 -288 -19 -390 -9 -223 -25 -298
    -92 -440 -81 -170 -229 -336 -418 -466 -115 -80 -309 -160 -465 -191 -275 -56
    -777 25 -1080 173 -101 50 -106 52 -190 112 -156 110 -298 300 -337 452 -5 19
    -13 51 -18 70 -23 89 -30 425 -39 1770 -6 795 -13 1558 -16 1695 -6 324 13
    2472 25 2810 34 907 42 995 113 1165 127 305 433 549 782 620 33 7 65 14 70
    16 26 9 625 -4 675 -15z m3713 -2505 c43 -14 99 -37 126 -50 101 -52 462 -305
    661 -464 30 -25 73 -59 95 -76 154 -122 434 -377 605 -553 161 -164 329 -349
    389 -428 12 -16 37 -46 55 -65 34 -37 189 -239 268 -349 83 -115 348 -515 348
    -525 0 -3 17 -34 38 -68 85 -138 332 -609 332 -632 0 -3 12 -30 26 -59 96
    -197 251 -613 329 -882 21 -74 43 -149 58 -195 6 -19 14 -51 18 -70 4 -19 15
    -66 24 -105 58 -241 114 -552 139 -770 72 -613 78 -1164 20 -1740 -8 -82 -20
    -179 -25 -215 -6 -36 -14 -96 -19 -135 -19 -149 -66 -397 -116 -610 -9 -38
    -19 -86 -24 -105 -4 -19 -18 -71 -30 -115 -12 -44 -26 -93 -30 -110 -4 -16
    -12 -43 -18 -60 -5 -16 -17 -57 -27 -90 -9 -33 -20 -67 -24 -75 -5 -9 -12 -31
    -16 -50 -4 -19 -11 -39 -15 -45 -4 -5 -10 -21 -13 -35 -8 -38 -65 -198 -98
    -275 -5 -11 -18 -45 -29 -75 -11 -30 -25 -64 -30 -75 -5 -11 -16 -38 -25 -60
    -55 -135 -139 -316 -222 -480 -51 -101 -70 -137 -140 -265 -202 -368 -591
    -925 -847 -1210 -23 -25 -66 -75 -96 -110 -58 -68 -437 -451 -530 -536 -167
    -152 -437 -373 -595 -487 -181 -131 -246 -176 -355 -246 -63 -41 -133 -86
    -155 -101 -22 -14 -47 -29 -55 -33 -8 -4 -28 -16 -45 -27 -16 -11 -37 -23 -45
    -27 -8 -3 -31 -16 -50 -28 -19 -12 -64 -38 -100 -58 -36 -19 -94 -51 -130 -71
    -103 -57 -491 -246 -504 -246 -4 0 -68 -28 -161 -70 -11 -5 -45 -19 -75 -30
    -30 -11 -64 -24 -75 -29 -11 -5 -38 -15 -60 -24 -22 -8 -58 -22 -80 -30 -179
    -69 -515 -177 -675 -217 -19 -5 -71 -18 -115 -30 -80 -21 -97 -25 -230 -55
    -142 -32 -348 -72 -435 -85 -36 -6 -87 -14 -115 -20 -27 -5 -104 -16 -170 -24
    -66 -8 -158 -20 -205 -26 -349 -44 -1082 -44 -1444 0 -266 32 -376 47 -476 64
    -241 42 -377 70 -585 122 -33 8 -78 19 -101 24 -22 5 -51 13 -65 18 -13 6 -53
    18 -89 27 -36 10 -96 28 -135 40 -38 13 -101 32 -140 44 -38 12 -79 26 -90 31
    -11 5 -47 18 -80 29 -33 11 -85 30 -115 41 -30 12 -66 25 -80 30 -14 4 -38 13
    -55 20 -16 7 -39 16 -50 21 -127 51 -280 116 -322 135 -29 13 -79 36 -110 50
    -172 78 -499 250 -638 334 -33 20 -67 39 -75 43 -8 4 -27 15 -41 25 -22 14
    -137 87 -214 135 -28 18 -171 114 -245 166 -74 51 -342 251 -350 261 -3 3 -27
    23 -55 44 -27 21 -66 52 -85 69 -19 18 -55 48 -80 68 -25 21 -59 50 -76 65
    -251 229 -426 400 -570 558 -54 60 -103 114 -109 120 -5 6 -42 50 -82 97 -39
    48 -78 93 -85 100 -112 120 -401 523 -575 804 -7 11 -17 29 -23 40 -6 11 -19
    34 -30 50 -11 17 -23 37 -27 45 -3 8 -16 31 -28 50 -12 19 -36 61 -53 93 -18
    31 -45 81 -61 110 -110 200 -231 462 -360 782 -18 42 -62 161 -80 215 -99 289
    -138 420 -204 685 -9 33 -20 78 -26 100 -36 138 -101 503 -126 705 -49 390
    -60 576 -60 985 0 330 13 609 37 790 5 44 14 116 18 160 9 86 32 240 54 370 8
    44 19 109 25 145 6 36 15 83 20 105 5 22 17 74 26 115 26 123 50 224 91 378
    21 79 45 164 54 192 4 14 22 72 40 130 17 58 38 120 46 138 8 18 14 39 14 47
    0 8 6 29 14 47 8 18 21 52 30 76 30 79 48 127 57 147 4 11 24 61 44 110 76
    187 210 456 292 587 13 21 23 40 23 43 0 4 94 158 142 233 10 15 21 33 24 40
    19 38 151 235 259 386 44 61 88 123 97 137 9 14 23 32 30 40 7 8 42 52 77 99
    36 47 88 112 116 145 28 33 61 74 74 90 26 35 36 46 233 260 297 325 683 678
    923 845 27 19 56 41 63 48 12 11 87 62 144 97 209 130 240 146 397 201 263 93
    581 105 821 30 265 -82 571 -301 703 -501 57 -88 137 -247 137 -275 0 -5 4
    -18 9 -28 12 -22 35 -105 57 -207 25 -115 25 -429 0 -540 -21 -97 -29 -121
    -73 -210 -41 -86 -44 -91 -114 -185 -107 -143 -424 -464 -764 -775 -153 -138
    -563 -547 -602 -598 -17 -23 -45 -55 -61 -72 -50 -52 -168 -217 -242 -339 -77
    -128 -191 -357 -285 -571 -37 -83 -67 -156 -90 -215 -9 -25 -22 -56 -27 -70
    -30 -76 -86 -258 -112 -365 -49 -199 -96 -740 -96 -1105 0 -440 54 -960 135
    -1310 8 -36 20 -87 25 -115 6 -27 14 -63 19 -80 5 -16 17 -57 25 -90 34 -125
    102 -341 117 -367 5 -10 9 -23 9 -29 0 -6 7 -27 16 -47 9 -21 22 -52 30 -70 8
    -18 14 -37 14 -42 0 -6 14 -40 31 -77 17 -38 43 -93 56 -123 46 -101 102 -215
    134 -270 17 -30 42 -75 56 -100 32 -60 44 -79 70 -117 13 -17 23 -34 23 -37 0
    -21 303 -439 360 -496 3 -3 36 -41 74 -85 79 -92 276 -288 355 -355 29 -25 70
    -60 90 -78 48 -43 275 -215 376 -284 205 -140 553 -331 785 -431 36 -15 79
    -34 95 -41 17 -7 50 -21 75 -31 25 -9 56 -22 70 -27 30 -12 274 -91 345 -112
    171 -51 364 -96 515 -121 52 -8 120 -20 150 -26 167 -31 788 -15 1190 31 345
    40 608 84 830 140 156 39 417 122 515 163 39 16 79 33 90 37 138 52 526 268
    695 386 75 53 275 200 305 225 11 9 67 56 125 104 646 534 1146 1200 1440
    1920 17 44 36 89 41 100 22 52 89 256 114 345 9 36 23 85 30 110 36 130 87
    393 106 545 34 283 41 386 46 698 5 332 -5 530 -37 732 -40 257 -61 358 -117
    568 -25 95 -130 411 -158 474 -14 30 -25 59 -25 64 0 4 -14 38 -31 76 -17 37
    -38 84 -46 103 -112 255 -296 574 -453 785 -131 176 -257 310 -666 713 -332
    327 -436 436 -521 547 -20 28 -45 59 -55 69 -10 11 -37 51 -60 89 -24 37 -51
    80 -60 95 -37 58 -102 201 -141 307 -146 407 -93 823 146 1140 51 69 222 229
    296 279 71 47 240 136 260 136 6 0 25 6 43 14 62 27 211 62 368 86 41 7 82 13
    90 15 8 2 92 2 185 1 153 -3 178 -6 248 -30z"/>
    <path d="M49755 38235 c-33 -8 -74 -14 -91 -15 -17 0 -50 -6 -75 -14 -24 -7
    -69 -20 -99 -29 -96 -27 -183 -55 -240 -77 -30 -12 -67 -25 -81 -30 -14 -4
    -34 -12 -45 -18 -10 -6 -50 -25 -89 -43 -291 -136 -530 -347 -637 -564 -64
    -129 -73 -166 -73 -320 0 -159 12 -216 76 -350 51 -109 132 -213 220 -285 67
    -55 141 -100 164 -100 5 0 16 -6 24 -13 15 -12 58 -27 171 -58 75 -20 328 -23
    416 -5 42 9 90 16 108 16 17 0 54 7 81 16 28 9 70 22 95 28 147 42 174 51 280
    91 214 81 480 231 594 334 72 66 154 156 188 206 44 64 108 199 108 228 0 12
    7 44 15 71 29 95 15 241 -36 399 -11 32 -23 65 -28 75 -5 9 -17 35 -27 57 -32
    69 -121 179 -186 232 -72 58 -177 114 -258 136 -30 8 -75 21 -100 28 -67 18
    -400 21 -475 4z"/>
    <path d="M11700 36853 c-69 -7 -195 -20 -280 -29 -85 -8 -216 -21 -290 -29
    -74 -9 -155 -15 -180 -15 -52 0 -219 -26 -229 -35 -6 -6 7 -18 85 -85 16 -14
    65 -56 109 -94 44 -38 91 -78 105 -90 30 -25 174 -149 212 -182 15 -13 71 -60
    125 -106 54 -45 100 -84 103 -87 3 -4 34 -31 70 -62 71 -61 99 -76 104 -56 11
    39 40 134 66 217 17 52 38 120 46 150 9 30 22 71 29 90 12 34 22 69 60 195 10
    33 23 76 30 95 30 88 42 130 37 135 -8 7 -58 4 -202 -12z"/>
    <path d="M50350 34810 c-203 -31 -373 -73 -520 -130 -30 -12 -66 -25 -80 -29
    -14 -5 -41 -18 -60 -30 -19 -11 -42 -24 -50 -28 -36 -17 -165 -96 -175 -107
    -5 -7 -42 -40 -82 -75 -196 -169 -283 -389 -254 -637 6 -54 19 -124 27 -154
    19 -65 72 -180 111 -237 81 -119 221 -231 363 -291 94 -39 118 -46 273 -77
    100 -19 323 -20 442 0 50 8 110 15 135 15 25 0 61 5 80 10 19 6 62 15 95 20
    98 16 330 81 377 106 14 8 31 14 38 14 20 0 203 95 276 143 137 91 267 243
    316 369 44 114 60 215 55 338 -3 63 -10 129 -16 145 -5 17 -18 59 -27 95 -10
    36 -22 72 -29 80 -6 8 -21 35 -32 60 -45 92 -185 231 -285 282 -120 60 -250
    108 -298 108 -14 0 -47 7 -74 15 -72 22 -451 19 -606 -5z"/>
    <path d="M9950 33816 c-121 -43 -147 -59 -225 -136 -124 -123 -174 -279 -175
    -541 0 -94 3 -128 13 -132 16 -6 176 -31 332 -52 66 -9 165 -22 220 -30 55 -7
    154 -21 220 -30 66 -9 170 -23 232 -32 85 -13 115 -14 126 -5 9 8 17 59 26
    164 18 212 -3 373 -65 498 -52 105 -158 212 -264 264 -119 59 -322 74 -440 32z"/>
    <path d="M15225 20133 c-182 -30 -433 -130 -648 -257 -43 -25 -79 -46 -81 -46
    -3 0 -39 -20 -80 -45 -42 -25 -79 -45 -81 -45 -2 0 -54 -34 -116 -75 -61 -41
    -113 -75 -116 -75 -3 0 -22 -13 -42 -28 -20 -16 -43 -32 -51 -35 -38 -17 -329
    -239 -440 -336 -146 -127 -344 -337 -445 -471 -167 -222 -231 -387 -204 -529
    31 -165 116 -256 299 -322 77 -28 264 -28 370 0 80 21 127 36 175 57 243 104
    444 210 653 346 15 10 34 21 42 25 14 7 135 88 295 198 88 61 347 254 360 269
    6 6 57 52 115 103 293 255 491 514 565 740 26 81 18 221 -17 293 -44 89 -106
    152 -193 194 -75 36 -81 38 -203 41 -70 2 -140 1 -157 -2z"/>
    <path d="M43845 17621 c-158 -35 -276 -88 -402 -181 -66 -49 -198 -182 -254
    -255 -48 -64 -139 -241 -139 -271 0 -13 -8 -43 -17 -66 -26 -63 -26 -385 0
    -478 51 -181 74 -244 140 -370 69 -134 101 -187 169 -282 265 -368 629 -707
    948 -882 168 -93 269 -132 472 -181 90 -23 355 -23 419 -1 24 9 52 16 62 16
    34 0 207 90 285 148 108 79 226 204 289 307 22 36 80 159 93 195 44 132 52
    185 52 360 1 180 1 179 -50 350 -25 86 -87 223 -132 295 -40 65 -49 79 -60
    100 -15 29 -126 181 -194 266 -78 97 -420 438 -517 516 -119 95 -258 191 -344
    237 -27 15 -57 31 -65 36 -28 16 -126 59 -181 78 -226 80 -405 99 -574 63z"/>
    <path d="M20324 15164 c-33 -52 -67 -107 -76 -122 -9 -15 -23 -37 -30 -49 -16
    -28 -156 -254 -180 -291 -10 -15 -22 -36 -28 -47 -6 -11 -18 -32 -28 -47 -9
    -15 -51 -82 -92 -148 -41 -67 -83 -134 -92 -148 -10 -15 -22 -36 -28 -47 -6
    -11 -18 -32 -28 -47 -58 -91 -122 -201 -122 -212 0 -13 64 -56 175 -116 33
    -18 80 -44 105 -58 25 -13 56 -28 70 -33 14 -4 50 -17 80 -29 120 -47 207 -62
    351 -61 97 1 147 5 172 16 20 8 45 15 57 15 28 0 164 73 219 117 63 51 80 69
    132 138 127 170 189 389 160 560 -17 97 -50 193 -83 243 -15 24 -28 47 -28 51
    0 5 -32 43 -71 85 -70 75 -169 149 -276 207 -83 45 -263 119 -289 119 -7 0
    -38 -43 -70 -96z"/>
    <path d="M23655 13541 c-163 -32 -302 -93 -436 -192 -91 -68 -230 -202 -297
    -287 -42 -53 -143 -204 -155 -232 -4 -8 -17 -31 -29 -50 -20 -34 -105 -202
    -133 -265 -48 -109 -110 -275 -146 -395 -50 -170 -58 -201 -95 -395 -25 -131
    -25 -498 0 -610 47 -211 94 -319 201 -462 173 -230 501 -386 810 -385 92 1
    251 24 285 42 10 5 26 10 35 10 26 0 215 93 283 140 88 59 223 190 292 280 55
    73 140 200 153 230 4 8 17 31 28 50 42 70 149 292 149 309 0 5 12 35 26 67 25
    56 75 195 94 259 5 17 18 62 30 100 26 87 47 172 60 249 6 33 13 63 16 67 8
    13 34 289 34 364 0 92 -19 259 -36 314 -8 25 -14 53 -14 64 0 10 -6 33 -14 50
    -22 50 -67 149 -83 183 -8 17 -22 40 -31 50 -10 11 -39 47 -65 81 -26 34 -61
    71 -77 83 -16 12 -52 40 -80 64 -57 49 -253 149 -360 185 -53 18 -259 51 -310
    50 -25 -1 -85 -9 -135 -18z"/>
    <path d="M26958 12563 c-92 -4 -98 -5 -103 -26 -12 -57 -35 -181 -50 -272 -9
    -55 -22 -136 -30 -180 -7 -44 -21 -125 -30 -180 -9 -55 -23 -134 -30 -175 -7
    -41 -21 -120 -30 -175 -9 -55 -22 -134 -30 -175 -21 -117 -24 -158 -11 -172
    32 -38 384 -119 642 -148 177 -20 389 -6 480 31 133 54 210 113 295 224 181
    237 186 568 14 816 -56 80 -169 183 -263 239 -45 28 -86 50 -91 50 -5 0 -35
    12 -67 26 -61 27 -139 51 -209 64 -22 4 -60 12 -85 18 -25 6 -94 16 -155 22
    -60 7 -119 13 -130 14 -11 1 -64 1 -117 -1z"/>
    <path d="M26586 10949 c-6 -20 -31 -161 -82 -464 -9 -49 -21 -126 -29 -170 -7
    -44 -21 -125 -30 -180 -9 -55 -23 -134 -30 -175 -7 -41 -21 -120 -30 -175 -9
    -55 -23 -135 -31 -177 -9 -43 -13 -82 -10 -87 3 -5 16 -11 28 -14 13 -3 48
    -15 78 -26 82 -32 188 -67 250 -81 30 -7 78 -18 105 -26 225 -58 596 -58 730
    1 156 68 225 115 317 213 55 59 138 210 138 250 0 11 7 36 15 55 22 53 20 232
    -4 327 -29 117 -59 182 -121 265 -155 207 -423 347 -805 419 -265 50 -481 70
    -489 45z"/>
    <path d="M30925 12313 c-134 -19 -301 -72 -357 -113 -15 -11 -29 -20 -32 -20
    -17 0 -122 -84 -174 -138 -173 -183 -270 -367 -345 -657 -61 -233 -90 -601
    -67 -860 23 -271 29 -320 39 -355 6 -19 11 -48 11 -64 0 -16 7 -54 16 -85 8
    -31 22 -81 31 -111 8 -30 21 -70 29 -88 8 -18 14 -39 14 -47 0 -18 75 -182
    113 -250 88 -153 145 -225 255 -321 89 -78 89 -78 204 -136 328 -165 767 -121
    1068 108 75 57 180 161 219 219 15 22 40 58 55 79 80 117 144 297 196 546 18
    86 40 372 40 515 0 149 -24 481 -40 570 -41 219 -47 245 -86 370 -44 141 -114
    290 -192 410 -40 61 -235 264 -254 265 -3 0 -39 19 -80 43 -40 24 -98 52 -128
    61 -30 10 -73 25 -96 32 -34 12 -298 46 -329 43 -5 -1 -55 -8 -110 -16z"/>
    </g>
    </svg>
    )
}

export default Robotheosis