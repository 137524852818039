
import './App.scss';
import Navbar from './Navbar';



import  './js/script.js';


import { useEffect, useState } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import {    } from './js/database';
import RequestPreview from './RequestPreview';

import $ from 'jquery';
import { materials } from './js/newRequest';



const auth = getAuth()

function Dashboard() {
  const [user, ,] = useAuthState(auth, {onUserChanged:() => {
   
   // window.location.href="/dashboard"
  }});

  useEffect (() => {
    //fetchRequests()
  }, [])
  const [data, ] = useState({})

  
  

  var fabricator = data.fabricator
  var requests = data.requests

  var filtersTemp = {materials:{"3d":{}, "laser":{}, "cnc":{}}, colors:{}, thicknesses:{}};
  if (fabricator !== undefined) {
  filtersTemp.tools = fabricator.resources
  }
  ["Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"].forEach((thickness) => {
    filtersTemp.colors[thickness] = true
  })
  
  
Object.entries(materials).forEach(([keyTool, valTool]) => {
  Object.entries(valTool).forEach(([key, val]) => {
    filtersTemp.materials[keyTool][key] = true
  })
});



["1/4in", "1/8in", "Other"].forEach((thickness) => {
  filtersTemp.thicknesses[thickness] = "on"
})



const [filters, setFilters] = useState(filtersTemp)

if (filters.tools===undefined && fabricator !== undefined) {
  setFilters(filtersTemp)
}
console.log(filters)
  
  useEffect(() => {
    
    if (user!= null) {
    
     
    }
  })
  console.log(requests)

  return (
    <div className="App ">
      <Navbar user={auth.currentUser}></Navbar>
      
      <div className=" mt-2 container-fluid">
        
        <div className='row '>
          {filters.tools !== undefined &&
      <div className="d-flex flex-column flex-shrink-0 p-3" style={{width: "280px"}}>
      <h2>Filter Results</h2>
      <h5>Tool:</h5>
      



<div className="accordion" id="accordionExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      
      <button className={filters.tools["3d"]==="on" ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={() => {
        $("#3dcheck").prop("checked", !$("#3dcheck").prop("checked"))}}>
       <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="3dcheck" defaultChecked={fabricator.resources["3d"]==="on"}/>
    3D Printing
</div>

      </button>
      
    </h2>
    <div id="collapseOne" className={filters.tools["3d"]==="on" ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingOne" >
      <div className="accordion-body">
        {Object.entries(materials["3d"]).map(([key, val]) => {
          return (<div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id={"mat-3d-"+key+"-check"} defaultChecked={true}/>
          <label htmlFor={"mat-3d-"+key+"-check"}>{key}</label>
            
        </div>);
        })}
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className={fabricator.resources["laser"]==="on" ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"onClick={() => {
        $("#lasercheck").prop("checked", !$("#lasercheck").prop("checked"))}}>
       <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="lasercheck" defaultChecked={fabricator.resources["laser"]==="on"}/>
    Laser Cutting
</div>

      </button>
    </h2>
    <div id="collapseTwo" className={fabricator.resources["laser"]==="on" ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingTwo">
      <div className="accordion-body">
      {Object.entries(materials["laser"]).map(([key, val]) => {
          return (<div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id={"laser"+key+"check"} defaultChecked={true}/>
          <label htmlFor={"laser"+key+"check"}>{key}</label>
            
        </div>);
        })}
      </div>
    </div>
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
    <button className={fabricator.resources["cnc"]==="on" ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree"onClick={() => {
        $("#cnccheck").prop("checked", !$("#cnccheck").prop("checked"))}}>
       <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id="cnccheck" defaultChecked={fabricator.resources["cnc"]==="on"}/>
    CNCing
</div>

      </button>
    </h2>
    <div id="collapseThree" className={fabricator.resources["cnc"]==="on" ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby="headingThree" >
      <div className="accordion-body">
      {Object.entries(materials["cnc"]).map(([key, val]) => {
          return (<div className="form-check">
          <input className="form-check-input" type="checkbox" value="" id={"cnc"+key+"check"} defaultChecked={true}/>
          <label htmlFor={"cnc"+key+"check"}>{key}</label>
            
        </div>);
        })}
      </div>
    </div>
  </div>
</div>
<h5>Color: </h5>
<div className='row ms-2'>

{[ "Red", "Orange", "Yellow", "Green", "Blue", "Purple", "Brown", "Black", "White", "Clear", "Other"].map(color => {
return (<div className="form-check">
<input className="form-check-input" type="checkbox" id={"color-"+color+"-check"} defaultChecked={filters.colors[color]} onChange={filterRequests}/>
<label htmlFor={color+"check"}>{color}</label>
  
</div>);
})}
  </div>
  <h5>Thickness: </h5>
<div className='row ms-2'>

{[ "1/4in", "1/8in", "Other"].map(color => {
return (<div className="form-check">
<input className="form-check-input" type="checkbox" value="" id={color+"check"} defaultChecked={true}/>
<label htmlFor={color+"check"}>{color}</label>
  
</div>);
})}
  </div>
  </div>
}
  <div className='col'>
        <div className="row">
        
            <div className="col">
            <h2>Active Requests</h2>
            </div>
            <div className="col d-flex justify-content-end">
                <a href="/newRequest"><button type="button" className="btn btn-yellow me-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                  </svg> Create Request</button></a>
            </div>
           
        </div>
          
        <div className="mt-2 row" id="requests">
        
  <div className='col' key={filters}>
    {requests !== undefined &&
    requests.map(([key, value]) => {
      return <RequestPreview id={key} request={value}/>
    })}
    </div>

    </div>
    </div>
    </div>
    </div>
    </div>
    
  );
  // function getDistance(lat1, lon1, lat2, lon2) {
  //   const R = 6371; // radius of Earth in kilometers
  //   const dLat = (lat2 - lat1) * Math.PI / 180;
  //   const dLon = (lon2 - lon1) * Math.PI / 180;
  //   const a =
  //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
  //     Math.sin(dLon / 2) * Math.sin(dLon / 2);
  //   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   const d = R * c;
  //   return d * 0.621371; // convert to miles
  // }
//   function fetchRequests() {
//     getUser(auth.currentUser.uid).then(userProfile => {
//       getTeams().then(teams => {
//         getRequests().then(r => {
          
//           var requestsEntries = Object.entries(r).filter(val => {
//             var yeet = false;
//             console.log(val[1])
//             console.log(filters.colors[val[1].color])
//             yeet = !val[1].hasOwnProperty("assigned")
//             //yeet = (filters.colors.hasOwnProperty(val[1].color) && filters.colors[val[1].color]===true) ? false : (!filters.colors.hasOwnProperty(val[1].color) && filters.colors["Other"]===true) ? false : false
//             yeet = filters.colors[val[1].color] 
//             console.log(yeet)
//             return yeet;
//           })
//               requestsEntries.sort((a, b) => {
//                   var fabricator = teams[userProfile.team]
//                   var aTeam = teams[a[1]["team"]]
//                   var bTeam = teams[b[1]["team"]]
//                   return getDistance(aTeam.lat, aTeam.long, fabricator.lat, fabricator.lon) - getDistance(bTeam.lat, bTeam.long, fabricator.lat, fabricator.lon)
//               })
//               setRequests({requests:requestsEntries.slice(0, 10), fabricator:teams[userProfile.team]})
            
//         })
      
       
//       })
//     })
    
//   }

  function filterRequests(e) {
   var  element = e.target
   console.log(element)
   var filterCopy = JSON.parse(JSON.stringify(filters))
   if (element.id.split("-")==="mat") {
      filterCopy.materials[element.id.split("-")[1]][element.id.split("-")[2]] = element.checked
   }
   if (element.id.split("-")[0]==="color") {
    console.log(element.checked)
    filterCopy.colors[element.id.split("-")[1]] = element.checked
 }
   setFilters(filterCopy)
   
  }
 }


export default Dashboard;
