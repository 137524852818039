
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.scss';
import { getTeam } from './js/database';






function RequestPreview({id, request, team}) {
    // const {id, request} = useParams();
    // console.log(request)
    const navigate = useNavigate()
  const [teamProf, setTeam] = useState()
    useEffect(() => {
      getTeam(request.team).then(setTeam)
    }, [request.team])
  if (team) {
    return (
        <div className="card mx-auto mt-2 bg-body-secondary" style={{"maxWidth": "50em", "minWidth": "60%"}}>
            <div className="card-header">
              Status: {!request.hasOwnProperty("assigned") ? "Pending" : (request.complete) ? "Request Completed" : "Accepted" }
            </div>
            <div className="card-body">
              <h5 className="card-title">{request.tool==="3d" && "3D Printing"}{request.tool==="laser" && "Laser Cutting"}{request.tool==="cnc" && "CNCing"}</h5>
              <p className="card-text">Material: {request.material} {request.hasOwnProperty("color") && "| Color: "+request.color} {request.hasOwnProperty("thickness") && "| Thickness: "+request.thickness}<br/> {request.hasOwnProperty("notes") && request.notes.length > 100 ? "Notes: "+request.notes.slice(0, 100)+"..." : request.hasOwnProperty("notes") && "Notes: "+request.notes}</p>
              <button onClick={() => {
            navigate(
            "/request/"+request.id)}} className="btn btn-yellow-opaq">View Details</button>
            </div>
          </div>
        
      );
  } else{
    if (teamProf === undefined) return;
  return (
    <div className='row' key={request.id}>
    <div className="card mx-auto mt-2 bg-body-secondary" style={{"maxWidth": "50em", "minWidth": "60%"}}>
        <div className="card-header justify-content-between d-flex" >
          <div>Team {request.team} {teamProf.name}</div> <div>Distance: {request.milesAway} mi</div>
        </div>
        <div className="card-body">
          <h5 className="card-title">{request.tool==="3d" && "3D Printing"}{request.tool==="laser" && "Laser Cutting"}{request.tool==="cnc" && "CNCing"}</h5>
          <p className="card-text">Material: {request.material} {request.hasOwnProperty("color") && "| Color: "+request.color} {request.hasOwnProperty("thickness") && "| Thickness: "+request.thickness}<br/> {request.hasOwnProperty("notes") && request.notes.length > 100 ? "Notes: "+request.notes.slice(0, 100)+"..." : request.hasOwnProperty("notes") && "Notes: "+request.notes}</p>
          <button onClick={() => {
            navigate(
            "/request/"+request.id)}} className="btn btn-yellow-opaq">View Details</button>
        </div>
      </div>
      </div>
  );
  }
}


export default RequestPreview;
