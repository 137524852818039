
import laser from "./imgs/laser.svg"
import cnc from "./imgs/cnc.svg"
import threeD from "./imgs/3DPrinter.svg"
import './App.scss';
import Navbar from './Navbar';
import {select3d, selectLaser,selectCNC, materialChange, colorChange, thicknessChange, materials, modRequest, sizeChange} from './js/newRequest';

import Footer from './Footer';


import { useEffect, useState } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { useNavigate, useParams } from "react-router-dom";
import { getRequest, getUser } from "./js/database";
const auth = getAuth()

function EditRequest() {

    const {id} = useParams()
    const [userP, setUserP] = useState();
    const navigate = useNavigate()
  
  const [, loading, ] = useAuthState(auth, {onUserChanged:(auth) => {
    if (auth === null) navigate("/login?redirect="+window.location.pathname)
    getUser(auth.uid).then(usP => {
      setUserP(usP)
    })
    
   // window.location.href="/dashboard"
  }});
 const [request, setRequest] = useState()
  
  useEffect(() => {
    
    getRequest(id).then(setRequest)
  }, [id])

  if (loading) return;
  if (request === undefined) return;
  if (userP === undefined) return;
  if (request.team !== userP.team) navigate("/")

  return (
    <div className="App">
      <Navbar user={auth.currentUser}></Navbar>
      <div className="px-4 pt-5 my-5 text-center">
        <h1 className="display-4 fw-bold">Edit Request</h1>
        <div className="col-lg-6 mx-auto">
          <div className="row mt-2">
            <h4>What tool is your request for:</h4>
            <p>This is important so we can know what requests to send your way.</p>
            <div className="col">
<button className={request.tool ==="3d" ? "btn btn-yellow-tool col active" : "btn btn-yellow-tool col"} id="select3d" htmlFor="btn-check-1" onClick={select3d}>3D Printer<br/><img src={threeD} alt="3d printer" height="150"/></button>
            </div>
            <div className="col">
<button className={request.tool ==="laser" ? "btn btn-yellow-tool col active" : "btn btn-yellow-tool col"} id="selectLaser"htmlFor="btn-check-2" onClick={selectLaser}>Laser Cutter<br/><img height="150" src={laser} alt="laser"/></button>
            </div>
            <div className="col">
<button className={request.tool ==="cnc" ? "btn btn-yellow-tool col active" : "btn btn-yellow-tool col"} id="selectCNC"htmlFor="btn-check-3" onClick={selectCNC}>CNC<br/><img src={cnc} height="150" alt="cnc"/></button>
            </div>
        </div>
        </div>
        
      </div>
      <div className="container mt-3">
        <form onSubmit={(e) => {modRequest(e, navigate)}}>
        <div className="row" id="3d-row">
        <input type="hidden" id="id" name="id" defaultValue={id}/> 

            <input type="hidden" id="tool" name="tool" defaultValue={request.tool}/> 
            <div id="matSelectDiv">
            <label for="matSelect">Material:</label>
            <select className="form-control" id="matSelect" name="matSelect" onChange={materialChange}>
                <option  disabled >Select</option>
                {Object.keys(materials[request.tool]).map(mat => (
                    request.material === mat || (mat === "Other" && !materials[request.tool].hasOwnProperty(request.material)) ? <option  selected>{mat}</option>: <option>{mat}</option>
                ))}
            </select>
            {!Object.keys(materials[request.tool]).includes(request.material)? <input className="form-control mt-2" name="matText" id="matText" defaultValue={request.material}/> : <input className="form-control mt-2" name="matText" id="matText" style={{display:"none"}}/>}
            <div className="card mt-2" id="matCard">
                <div className="card-header" id="matName">
                  {request.material}
                </div>
                <div className="card-body">
                  <p className="card-text " id="matDesc">{(request.tool !== undefined && materials[request.tool].hasOwnProperty(request.material)) ? materials[request.tool][request.material].desc : <p>This was a custom material requested by the team.</p>}</p>
                </div>
            </div>
              </div>
              {request.color !== undefined ?
              <div id="colorSelectDiv" >
              <label for="colorSelect">Color:</label>
              <select className="form-control mt-2" id="colorSelect" name="colorSelect" onChange={colorChange}>
              {Object.keys(materials[request.tool]).includes(request.material) ? materials[request.tool][request.material].colors.map(mat => (
                    (request.color === mat || (mat === "Other" && !materials[request.tool][request.material].colors.includes(request.color))) ? <option  selected>{mat}</option>: <option>{mat}</option>
                )): materials[request.tool]["Other"].colors.map(mat => (
                    (request.color === mat || (mat === "Other" && !materials[request.tool]["Other"].colors.includes(request.color)) )? <option  selected>{mat}</option>: <option>{mat}</option>))}
                </select>

                {(!Object.keys(materials[request.tool]).includes(request.material) && !materials[request.tool]["Other"].colors.includes(request.color)) ||  (Object.keys(materials[request.tool]).includes(request.material)  && !materials[request.tool][request.material].colors.includes(request.color) )? <input className="form-control mt-2" name="colorText" id="colorText" defaultValue={request.color}/> : <input className="form-control mt-2" name="colorText" id="colorText" style={{display:"none"}}/> }
            </div> : <div id="colorSelectDiv" style={{display:"none"}}>
              <label for="colorSelect">Color:</label>
              <select className="form-control mt-2" id="colorSelect" name="colorSelect" onChange={colorChange}>
                <option selected disabled>Select</option>
                </select>
                <input className="form-control mt-2" name="colorText" id="colorText" style={{display:"none"}}/>
            </div>}
            {request.thickness !== undefined ?
            <div id="thicknessSelectDiv" className="mt-2" >
                <label for="thicknessSelect">Thickness:</label>
                <select className="form-control mt-2" id="thicknessSelect" name="thicknessSelect" onChange={thicknessChange}>
                {Object.keys(materials[request.tool]).includes(request.material) ? materials[request.tool][request.material].thickness.map(mat => (
                    (request.thickness === mat || (mat === "Other" && !materials[request.tool][request.material].thickness.includes(request.thickness)) )? <option  selected>{mat}</option>: <option>{mat}</option>
                )): materials[request.tool]["Other"].thickness.map(mat => (
                   ( request.thickness === mat || (mat === "Other" && !materials[request.tool]["Other"].thickness.includes(request.thickness)) ) ? <option  selected>{mat}</option>: <option>{mat}</option>))}
                  </select>
                  {(!Object.keys(materials[request.tool]).includes(request.material) && !materials[request.tool]["Other"].thickness.includes(request.thickness)) || (Object.keys(materials[request.tool]).includes(request.material) && !materials[request.tool][request.material].thickness.includes(request.thickness)) ? <input className="form-control mt-2" defaultValue={request.thickness} name="thicknessText" id="thicknessText"/> : <input className="form-control mt-2" style={{display:"none"}} name="thicknessText" id="thicknessText"/> }
              </div>:
              <div id="thicknessSelectDiv" className="mt-2"  style={{display:"none"}}>
              <label for="thicknessSelect">Thickness:</label>
              <select className="form-control mt-2" id="thicknessSelect" name="thicknessSelect" onChange={thicknessChange}>
                <option selected disabled>Select</option>
                </select>
                <input className="form-control mt-2" style={{display:"none"}} name="thicknessText" id="thicknessText"/>
            </div>}
            {request.size !== undefined ?
            <div id="sizeSelectDiv" className="mt-2" >
                <label for="sizeSelect">Size:</label>
              <div className="row g-3">
              <div className="col-sm">
  <label for="sizeSelect">Width:</label>
                <input className="form-control mt-2" name="xSizeText" id="xSizeText" defaultValue={request.size.x}/>
  </div>
  <div className="col-sm">
  <label for="sizeSelect">Length:</label>
                <input className="form-control mt-2" name="ySizeText" id="ySizeText" defaultValue={request.size.y}/>
  </div>
  <div className="col-sm" id="heightDiv" style={{display:!request.size.hasOwnProperty("z") ? "none" : ""}}>
                <label for="sizeSelect">Height:</label>
                <input className="form-control mt-2" name="zSizeText" id="zSizeText" defaultValue={request.size.hasOwnProperty("z") ? request.size.z : ""}/>
  </div>
  <div className="col-sm">
  <label for="unitSelect">Unit:</label>
  <select className="form-control mt-2" id="unitSelect" name="unitSelect" onChange={sizeChange}>
                  <option selected disabled>Select</option>
                  <option>mm</option>
                  <option>in</option>

                  </select>
  </div>
</div>
              </div> : <div id="sizeSelectDiv" className="mt-2"  style={{display:"none"}}>
                <label for="sizeSelect">Size:</label>
              <div className="row g-3">
              <div className="col-sm">
  <label for="sizeSelect">Width:</label>
                <input className="form-control mt-2" name="xSizeText" id="xSizeText"/>
  </div>
  <div className="col-sm">
  <label for="sizeSelect">Length:</label>
                <input className="form-control mt-2" name="ySizeText" id="ySizeText"/>
  </div>
  <div className="col-sm" id="heightDiv" style={{display:"none"}}>
                <label for="sizeSelect">Height:</label>
                <input className="form-control mt-2" name="zSizeText" id="zSizeText"/>
  </div>
  <div className="col-sm">
  <label for="unitSelect">Unit:</label>
  <select className="form-control mt-2" id="unitSelect" name="unitSelect" onChange={sizeChange}>
                  <option selected disabled>Select</option>
                  <option>mm</option>
                  <option>in</option>

                  </select>
  </div>
</div>
              </div>}
            <div id="notesDiv" className="mt-2">
                <label for="notes">Additional Notes:</label>
                <textarea className="form-control mt-2" defaultValue={request.notes}  placeholder="Anything else we should know (Special Requests)" name="notes" id="notes"></textarea>
                <p>Replace File:</p>
                {request.tool === "3d" ? <input className='form-control mt-2' accept=".stl" type="file" id="fileInput"></input> : <input className='form-control mt-2' accept=".dxf,.svg" type="file" id="fileInput"></input>}

                <input className='form-control mt-2' type="hidden" defaultValue={request.file} id="fileInputDefault"></input>
                <button className="btn btn-yellow mt-2 text-center">Submit</button>
              </div> 

        </div>
    </form>
</div>
<Footer></Footer>

    </div>
    
  );
}


export default EditRequest;
