import { useMemo, useState, useEffect } from "react"
import {useTable, useFilters, usePagination} from 'react-table'
import RequestPreview from "./RequestPreview"

import $ from 'jquery'
import { getAuth } from "firebase/auth"
import { getTeam, getUser } from "./js/database"
import { Link } from "react-router-dom"

function Table (d) {
    const columns = useMemo(
      () => [
        {
          Header: 'Requests',
          columns: [
            {
              Header: 'Team Number',
              Filter: SelectColumnFilter,
              filter:(rows, id, filterValues) => {
                if (filterValues.length === 0) return rows;
                return rows.filter((r) => filterValues.includes(r.values[id]));
              },
              accessor: 'team',
            },
            {
                Header: 'Tool',
                Filter: ToolFilter,
                filter:(rows, id, filterValues) => {
                    if (filterValues.length === 0) return rows;
                    return rows.filter((r) => filterValues.includes(r.values[id]));
                  },
                accessor: 'tool',
              },
            {
              Header: 'Material',
              Filter: SelectColumnFilter,
              filter:(rows, id, filterValues) => {
                if (filterValues.length === 0) return rows;
                return rows.filter((r) => filterValues.includes(r.values[id]));
              },
              accessor: 'material',
            },
            {
              Header: 'Color',
              Filter: SelectColumnFilter,
              filter:(rows, id, filterValues) => {
                if (filterValues.length === 0) return rows;
                return rows.filter((r) => filterValues.includes(r.values[id]));
              },
              accessor: 'color',
            },
            {
              Header: 'Thickness',
              Filter: SelectColumnFilter,
              filter:(rows, id, filterValues) => {
                if (filterValues.length === 0) return rows;
                return rows.filter((r) => filterValues.includes(r.values[id]));
              },
              accessor: 'thickness',
            },
            {
                Header: 'ID',
                accessor: 'id',
              },
          ],
        },
        
      ],
      []
    )
    const {
      headerGroups,
      page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    } = useTable({
      columns,
      data:d.data,
      filterTypes:() => ({
              multiSelect: (rows, id, filterValues) => {
                if (filterValues.length === 0) return rows;
                return rows.filter((r) => filterValues.includes(r.values[id]));
              }
            }),
            initialState: { pageIndex: 0 },
    },
    useFilters,
    usePagination)
    return (<div className='row '><div className='row '></div>
  <div className="d-flex flex-column flex-shrink-0 p-3 filterBackground " style={{width: "280px"}}>
  <button className="btn btn-yellow mobileOnly" onClick={(e) => {
    if (!$("#filterDiv").hasClass("desktopOnly")) {
      $("#filterDiv").addClass("desktopOnly");
      e.target.innerHTML = "Show Filters";
    } else {
      $("#filterDiv").removeClass("desktopOnly");
    e.target.innerHTML = "Hide Filters";
  }
  }}>Hide Filters</button>
  <div id="filterDiv">
  <h2>Filter Results</h2>
  
  
  {headerGroups[0].headers[0].headers.map(column => {
    if (column.Header !== "Team Number" && column.Header !== "Material" && column.Header !== "ID") {
    return column.render("Filter")
    } 
    return ("");
  })}
  
  
  
  </div>
  </div>

  
  <div className='col mt-2'>
  <div className="row">
  
      <div className="col">
      <h2>Active Requests</h2>
      </div>
      <div className="col d-flex justify-content-end">
          <Link to="/newRequest"><button type="button" className="btn btn-yellow me-0"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus" viewBox="0 0 16 16">
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg> Create Request</button></Link>
      </div>
     
  </div>
    
  <div className="mt-2 row" id="requests">
  
  <div className='col'>
  {page.map((row) => (<RequestPreview id="" request={row.original}></RequestPreview>))}
  <div className="row text-center mt-2">
  <div className="col">
    <button className="btn btn-yellow mx-1" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
      {'<<'}
    </button>
    <button className="btn btn-yellow mx-1"  onClick={() => previousPage()} disabled={!canPreviousPage}>
      {'<'}
    </button>
    <button className="btn btn-yellow mx-1" onClick={() => nextPage()} disabled={!canNextPage}>
      {'>'}
    </button>
    <button className="btn btn-yellow mx-1"  onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
      {'>>'}
    </button>
    <span>
      Page{' '}
      <strong>
        {pageIndex + 1} of {pageOptions.length}
      </strong>{' | '}
    </span>
 
    <span>
       Go to page:{' '}
      
      <input
        type="number"
        defaultValue={pageIndex + 1}
        className="form-control mx-1"
        onChange={e => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0
          gotoPage(page)
        }}
        style={{ width: '100px', display:"inline-block" }}
      />
      
    </span>
    
    <select
      value={pageSize}
      className="form-control mx-1"
      style={{width:"100px", display:"inline-block" }}
      onChange={e => {
        setPageSize(Number(e.target.value))
      }}
    >
      {[10, 20, 30, 40, 50].map(pageSize => (
        <option key={pageSize} value={pageSize}>
          Show {pageSize}
        </option>
      ))}
      
    </select>
  </div>
  </div>
  </div>
  </div>
  </div>
  </div>)
  }


  function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id, Header },
  }) {
    // Calculate the options for filtering
    // using the preFilteredRows

    var saved = window.localStorage.getItem(id) || "{}"
    saved = JSON.parse(saved)
    const options = useMemo(() => {
      const options = new Set()
     
      preFilteredRows.forEach(row => {
        options.add(row.values[id])
      })
      return [...options.values()]
    }, [id, preFilteredRows])

    const selectedOG = useMemo(() => {
      var selected = []
      options.forEach(option => {
        if (saved[option] || !saved.hasOwnProperty(option)) {
          selected.push(option)
        }
      })
      return selected
    }, [options, saved])
    const [checked, setChecked] = useState(selectedOG);
    useEffect(() => {
        setFilter(checked)
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    const onChange = (e) => {
      const t = e.target.name.toString();
      if (e.target.checked) {
        e.target.checked = false;
        

      } else {
        e.target.checked = true;
       
      }
  
        if (checked && checked.includes(t)) {
            e.target.checked = false;
          setFilter(checked.filter((v) => v !== t).length === 0 ? [""] : checked.filter((v) => v !== t));
          // setChecked((p) => p.filter((v) => v !== t));
          setChecked((prevChecked) => {
          //  if (prevChecked.length === 1) return Object.keys(options);
            return prevChecked.filter((v) => v !== t);
          });
          saved = window.localStorage.getItem(id) || "{}"
        saved = JSON.parse(saved)
        saved[e.target.name] = false
        window.localStorage.setItem(id, JSON.stringify(saved))
        } else {
            e.target.checked = true;

          setFilter([...checked, t]);
          setChecked((prevChecked) => [...prevChecked, t]);
          saved = window.localStorage.getItem(id) || "{}"
          saved = JSON.parse(saved)
          saved[e.target.name] = true
          window.localStorage.setItem(id, JSON.stringify(saved))
        }
    };
    if (options.length === 0) return;
    return (
      <div key={id}>

        <h5>{Header}</h5>
        {Object.entries(options).map(([count, option], i) => {
            if (option !== undefined) {
          return ( <div className="form-check" key={count}>
          <input className="form-check-input" type="checkbox" name={option} defaultChecked={checked.includes(option)} onChange={onChange}/>
          <label htmlFor={option}>{option}</label>
            
          </div>);
        }
        return ("")
        })}
      </div>)
  
  }

  function ToolFilter(    { column: { filterValue, setFilter, preFilteredRows, id }, allColumns }) {
    // console.log(config)

    // var filterValue = config.column.filterValue
    // var setFilter = config.column.setFilter
    // var preFilteredRows = config.column.preFilteredRows
    // var id = config.column.id

    var saved = window.localStorage.getItem(id) || "{}"
    var savedMats = window.localStorage.getItem("mats") || "{}"
    saved = JSON.parse(saved)
    savedMats = JSON.parse(savedMats)
  
    const options = useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(option => {
            preFilteredRows.forEach(row => {
                options.add(row.values[id])
              })
              return [...options.values()]
        })
        
        return [...options.values()]
      }, [id, preFilteredRows])
      const optionsMats = useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(option => {
            preFilteredRows.forEach(row => {
                options.add(row.values["material"])
              })
              return [...options.values()]
        })
        
        return [...options.values()]
      }, [preFilteredRows])
      const selectedOG = useMemo(() => {
        var selected = []
        options.forEach(option => {
          if (saved[option]) {
            selected.push(option)
          } 
        })
        return selected
      }, [options, saved])

      const [checked, setChecked] = useState(selectedOG);
      const [team, setTeam] = useState({})
      var mats = {"3d":new Set(), "laser":new Set(), "cnc":new Set()}
      preFilteredRows.forEach((row) => {
        mats[row.original.tool].add(row.original.material)
      })
      // eslint-disable-next-line
      useEffect(() => {
          setFilter(checked)
          var selectMats = []
          
          optionsMats.forEach(mat => {
            if (savedMats[mat] || !savedMats.hasOwnProperty(mat)) {
              selectMats.push(mat)
            }
          })
          allColumns[2].setFilter(selectMats)
          var auth = getAuth();
          getUser(auth.currentUser.uid).then(userProfile => {
            getTeam(userProfile.team).then (team => {
              setTeam(team)

              var defaults = []
              options.forEach(option => {
                if (team.resources[option] === "on" && !checked.includes(option) && !saved.hasOwnProperty(option)) {
                  defaults.push(option)
                }
              })
              setChecked(checked.concat(defaults))
              setFilter(checked.concat(defaults).length === 0 ? [""] : checked.concat(defaults))

              
            })
          })
             // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])
    
      const onChange = (e) => {
        console.log(e.name)
        const t = e.name.toString();
    
          if (checked && checked.includes(t)) {
            e.checked = false;
            setFilter(checked.filter((v) => v !== t).length === 0 ? [""] : checked.filter((v) => v !== t));
            // setChecked((p) => p.filter((v) => v !== t));
            setChecked((prevChecked) => {
             // if (prevChecked.length === 1) return Object.keys(options);
              return prevChecked.filter((v) => v !== t);
            });
            saved = window.localStorage.getItem(id) || "{}"
        saved = JSON.parse(saved)
        saved[t] = false
        window.localStorage.setItem(id, JSON.stringify(saved))
          } else {
            e.checked = true;
            setFilter([...checked, t]);
            setChecked((prevChecked) => [...prevChecked, t]);
            saved = window.localStorage.getItem(id) || "{}"
        saved = JSON.parse(saved)
        saved[t] = true
        window.localStorage.setItem(id, JSON.stringify(saved))
          }
      };

      const matChange = (e) => {
   //    var matCheck = e.target.checked;
        var matName = e.target.id.split("-")[1];
        var matChecked = allColumns[2].filterValue
        if (matChecked.includes(matName)) {
            allColumns[2].setFilter(matChecked.filter((v) => v !== matName).length === 0 ? [""] : matChecked.filter((v) => v !== matName));
            savedMats = window.localStorage.getItem("mats") || "{}"
        savedMats = JSON.parse(savedMats)
        savedMats[matName] = false
        window.localStorage.setItem("mats", JSON.stringify(savedMats))
        } else {
            allColumns[2].setFilter([...matChecked, matName])
            savedMats = window.localStorage.getItem("mats") || "{}"
        savedMats = JSON.parse(savedMats)
        savedMats[matName] = true
        window.localStorage.setItem("mats", JSON.stringify(savedMats))
        }
      }


      // Render a multi-select box
     


       if (Object.entries(team).length===0) return;
      return (
        <div className="accordion" id="accordionExample" key={id}>

        {options.map((option, i) => (
        <div className="accordion-item" key={i}>
    <h2 className="accordion-header" id={"heading"+i}>
      
      <button className={checked.includes(option)? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+i} aria-expanded="true" aria-controls={"collapse"+i} name={option} onClick={() => {
        onChange($("#"+option+"check")[0])
        }}>
       <div className="form-check">
  <input className="form-check-input" type="checkbox" value="" id={option+"check"} name={option} defaultChecked={checked.includes(option)} onChange={onChange  }/>
    {option==="3d" && "3D Printing"}  {option==="laser" && "Laser Cutting"} {option==="cnc" && "CNCing"}
</div>

      </button>
      
    </h2>
    <div id={"collapse"+i} className={checked.includes(option) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading"+i} >
      <div className="accordion-body">
        {[...mats[option].values()].map(mat => (
            <div className="form-check" key={mat}>
            <input className="form-check-input" type="checkbox" value="" id={"3d-"+mat+"-check"} defaultChecked={allColumns[2].filterValue !== undefined && allColumns[2].filterValue.includes(mat)} onClick={matChange}/>
              {mat}
          </div>
        ))}
      </div>
    </div>
  </div>))}
  </div>
  )
  }
  export default Table;