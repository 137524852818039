import logo from './imgs/CrowdforgeLong.svg';
import lightlogo from './imgs/CrowdforgeLongLight.svg';

import './App.scss';
import Navbar from './Navbar';




import './js/script.js';


import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { uiConfig, setNavigate } from './js/script.js';
import Dashboard from './Dashboard2';
import { useNavigate } from 'react-router-dom';
import { lazy } from 'react';
const StyledFirebaseAuth = lazy(() => import('react-firebaseui/StyledFirebaseAuth'))
const auth = getAuth()

function App() {
  const navigate = useNavigate()

  setNavigate(navigate)
  
  const [, loading, ] = useAuthState(auth, {onUserChanged:  (u) => {
    
   
   // console.log(userProfile)
   
  }});
  
  // useEffect( () => {
  //   if (loading) return
  //   if (user !== null) {
  //     getUser(user.uid).then((userProfile) => {
  //       if (userProfile !== null ) {
  //         window.location.href="/dashboard"
  //       } else {
  //         window.location.href="/onboard"
  //       }
  //     })
  //   }
    
     
  // })
  if (auth.currentUser !== null) {
    return <Dashboard />
  } else  if (loading){
    return;
  } else {


  return (
    <div className="App">
      <Navbar user={auth.currentUser}></Navbar>
      <div className="px-4 pt-5 my-5 text-center">
        <img src={logo} className="img-fluid dark-logo" alt="white text logo"></img>
        <img src={lightlogo} className="img-fluid light-logo" alt="grey text logo"></img>
        <div className="col-lg-6 mx-auto">
          <p className="lead mb-4">Welcome to CrowdForge, an easy way to connect with FTC (First Tech Challenge) teams in your area. CrowdForge’s mission is to give every team all the resources they need to succeed. Help us create a tight-knit community of makers!</p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center mb-5">
            <button type="button" className="btn btn-yellow btn-lg px-4 me-sm-3" data-bs-target="#loginModal" data-bs-toggle="modal" >Request a Part</button>
            <button type="button" className="btn btn-outline-secondary btn-lg px-4"  data-bs-target="#loginModal" data-bs-toggle="modal" >Start Helping</button>
          </div>
        </div>
        
      </div>
      <div className="container mt-3">
        <div className="row">
            <div className="col-sm-4 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
                    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                  </svg>
                  <h2 className="mt-2">Meet teams near you</h2>
                    <p>Find other teams in your area and learn from them.</p>
        </div>
        <div className="col-sm-4 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="currentColor" className="bi bi-truck" viewBox="0 0 16 16">
                <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
              </svg>
              <h2 className="mt-2">Free fabrication</h2>
                <p>Get free fabrication from other teams or offer it yourself.</p>
    </div>
    <div className="col-sm-4 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" fill="currentColor" className="bi bi-award-fill" viewBox="0 0 16 16">
            <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"/>
            <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/>
          </svg>
          <h2 className="mt-2">Help others</h2>
            <p>Be a true Gracious Profssional by helping other teams complete at their full potential.</p>
</div>

<div className="modal fade" id="loginModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Login</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        <div id="loader"></div>
      </div>

    </div>
  </div>
</div>
    </div>
    </div>
    </div>
  );
}
}

export default App;
