import { getAuth, GoogleAuthProvider, browserLocalPersistence, setPersistence, EmailAuthProvider } from "firebase/auth";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


import $ from 'jquery'



import {getTeam, setTeam, createTeam, getUser} from './database'
//import {getFunctions, httpsCallable} from "firebase/functions"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
  databaseURL: process.env.REACT_APP_FIRE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_APP_ID,
  measurementId: process.env.REACT_APP_FIRE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
//const database = getDatabase(app)
const fireauth = getAuth();
//const functions = getFunctions()

var navigate;
// const provider = new GoogleAuthProvider();
// var ui = new auth.AuthUI(fireauth)
// var modalRef;

// function setModalRef(ref) {
//     console.log(ref)
//     modalRef = ref;
// }

function setNavigate(nav) {
    navigate = nav;
}

  
var uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        // User successfully signed in.
        // Return type determines whether we continue the redirect automatically
        // or whether we leave that to developer to handle.
        console.log(authResult)
        getUser(authResult.user.uid).then(uP => {
            // console.log(modalRef)
            // var modal = Modal.getInstance(modalRef.current)
            navigate(0)
        })
        return false;
      },
      uiShown: function() {
        // The widget is rendered.
        // Hide the loader.
        document.getElementById('loader').style.display = 'none';
      },

    },
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      EmailAuthProvider.PROVIDER_ID,
      GoogleAuthProvider.PROVIDER_ID

    ],
    // Terms of service url.
    tosUrl: '<your-tos-url>',
    // Privacy policy url.
    privacyPolicyUrl: '<your-privacy-policy-url>'
    // callbacks: {
    //     signInSuccessWithAuthResult: function(currentUser) {
    //         const userId = currentUser.user.uid; 
    //         var credential = currentUser.credential

    //         currentUser.user.getIdToken().then(idToken => {

    //         return $.ajax({
    //             type: "POST",
    //             url: "/login",
    //             data: {idToken:idToken, email:currentUser.email, uid:userId},
    //             contentType: 'application/x-www-form-urlencoded',
    //             success: () => {
    //              // window.location.reload()
    //             }
    //           })
    //         })
    //         // Do not automatically redirect.
    //         return false;
    //       }
        

  };

  setPersistence(fireauth, browserLocalPersistence).then(() => {
    // ui.start('#firebaseui-auth-container', uiConfig);
  })


// function getUser() {

//     return currentUser;
// }


$("#searchTeam").blur(() => {
    $.ajax({type:"GET",
    url:"/searchTeam/"+$("#searchTeam").val(),
    success: (data) => {
       // setTeam(data)
    }})
})

function searchTeam(e) {
    if(e.which === 13){

       //Disable textbox to prevent multiple submit
       $(this).attr("disabled", "disabled");

    // const search = httpsCallable(functions, "searchTeam")
    //    search({team:+$("#searchTeam").val()}).then(result => {
    //     console.log(result)
    //    }).catch((error) => {
    //     // Getting the Error details.
    //     console.log(error)
    //     // ...
    //   });
       
    //    Do Stuff, submit, etc..
       $.ajax({type:"GET",
    url:`/api/searchTeam?team=${$("#searchTeam").val()}`,
    headers: {
        "accepts":"application/json"
    },
    success:(data) => {
        showTeam(data)
    }
   }).then((d) => {
        console.log(d)
     }).catch((e) => {
        console.log(e)
     })

//      $.ajax({type:"GET",
//        crossDomain:true,
//     url:"https://ftc-api.firstinspires.org/v2.0/2022/teams/?teamNumber="+$("#searchTeam").val(),
//     headers: {
//         "accepts":"application/json",
//         "Authorization":"Basic anNhMjAyNTo4RTlDNjdDRi05MzNELTRFNkUtODZBRS1EOTM1MjA4NzU2Qjk="
//     },
//     success:(data) => {
//         console.log(data)
//     //     $("#teamLat").val(locdata[0].lat)
//     //     $("#teamLong").val(locdata[0].lon)
//     //     $("#teamAddrIframe").attr("src", 'https://www.openstreetmap.org/export/embed.html?bbox='+locdata[0].boundingbox[2]+'%2C'+locdata[0].boundingbox[0]+'%2C'+locdata[0].boundingbox[3]+'%2C'+locdata[0].boundingbox[1]+'%2C'+'&amp;layer=mapnik')
//     //     $("#teamAddrIframe").show()
//     //     $("#teamSurveySubmit").show()
//      }
//    })
     console.log("submitted")

       //Enable the textbox again if needed.
       $(this).removeAttr("disabled");
    }
}

function confirmTeam()  {
   getTeam($("#searchTeam").val()).then((data) => {
    console.log(data)
            if (data !== null) {
                // $.ajax({
                //     type:"POST",
                //     url:"/setTeam",
                //     data:{teamId:$("#searchTeam").val()},
                //     success: () => {
                //         window.location.href = "/"
                //     }
                // })
                setTeam(fireauth.currentUser.uid, $("#searchTeam").val(), fireauth.currentUser.email).then(() => {
                    navigate("/")
                })
            } else {
                $("#teamNumberSurvey").val($("#searchTeam").val())
                $("#teamNameSurvey").val($("#teamName").text())
                $("#teamSurvey").show()
                window.scroll(0, window.innerHeight*1.5)
            }
        })
    }


$("#teamAddr").blur(() => {
    $.ajax({
        type: "GET",
        url:"https://nominatim.openstreetmap.org/search.php?q="+$("#teamAddr").val()+"&format=jsonv2",
        success: (locdata) => {
            $("#teamLat").val(locdata[0].lat)
            $("#teamLong").val(locdata[0].lon)
            $("#teamAddrIframe").attr("src", `https://www.openstreetmap.org/export/embed.html?bbox=${locdata[0].boundingbox[2]}%2C${locdata[0].boundingbox[0]}%2C${locdata[0].boundingbox[3]}%2C${locdata[0].boundingbox[1]}%2C&amp;layer=mapnik`)
            $("#teamAddrIframe").show()
            $("#teamSurveySubmit").show()
        }
    })
})

function signOut(){
    fireauth.signOut().then(() => {
        window.location.href = "/"
    })
}

function addrSearch(e) {
    if(e.which === 13){

       //Disable textbox to prevent multiple submit

       //Do Stuff, submit, etc..
       e.preventDefault()
       $.ajax({
        type: "GET",
        url:"https://nominatim.openstreetmap.org/search.php?q="+$("#teamAddr").val()+"&format=jsonv2",
        success: (locdata) => {
            if (locdata[0].display_name !== undefined) {
                $("#teamAddr").val(locdata[0].display_name)
            }
            $("#teamLat").val(locdata[0].lat)
            $("#teamLong").val(locdata[0].lon)
            $("#teamAddrIframe").attr("src", `https://www.openstreetmap.org/export/embed.html?bbox=${locdata[0].boundingbox[2]}%2C${locdata[0].boundingbox[0]}%2C${locdata[0].boundingbox[3]}%2C${locdata[0].boundingbox[1]}%2C&amp;layer=mapnik`)
            $("#teamAddrIframe").show()
            $("#teamSurveySubmit").show()
        }
    })

       //Enable the textbox again if needed.
    }
};

function newTeam(e) {
    e.preventDefault()
    const team = {
        name: $("#teamName").text(),
        coords: {
            lat: $("#teamLat").val(),
            long: $("#teamLong").val()
        },
        resources:{
            "3d":$("#btn-check-1").prop("checked") ? "on" : "off",
            laser: $("#btn-check-2").prop("checked") ? "on" : "off",
            cnc: $("#btn-check-3").prop("checked") ? "on" : "off"
        },
        address:$("#teamAddr").val()
    }
    console.log(team)
    createTeam($("#searchTeam").val(), team).then(() => {
        setTeam(fireauth.currentUser.uid, $("#searchTeam").val(), fireauth.currentUser.email).then(() => {
            navigate("/")
        })
    })
}




function showTeam(data) {
    if (data !== null) {
        $("#teamNumber").text("Team "+$("#searchTeam").val())
        $("#teamName").text(data.teamName)
        $("#teamLoc").text(data.locText)
        $.ajax({
            type: "GET",
            url:"https://nominatim.openstreetmap.org/search.php?q="+data.locText+"&format=jsonv2",
            success: (locdata) => {
                console.log(locdata)
                $("#teamIframe").attr("src", `https://www.openstreetmap.org/export/embed.html?bbox=${locdata[0].boundingbox[2]}%2C${locdata[0].boundingbox[0]}%2C${locdata[0].boundingbox[3]}%2C${locdata[0].boundingbox[1]}%2C&amp;layer=mapnik`)
                $("#teamIframe").show()
                $("#confirmTeam").show()
            }
        })
       
    }
}

const getPreferredTheme = () => {
    if (window.localStorage.getItem("theme") !== null) {
        return window.localStorage.getItem("theme")
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
  }
  
  const getCurrentTheme = () => {
    return document.documentElement.getAttribute("data-bs-theme")
  }
  const setTheme = function (theme) {
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
  }

  function toggleTheme() {
    if (getCurrentTheme() === "dark") {
        window.localStorage.setItem("theme", "light")
        setTheme("light")
    } else {
        window.localStorage.setItem("theme", "dark")
        setTheme("dark")
    }
  }

  setTheme(getPreferredTheme())

export {searchTeam, confirmTeam, newTeam, addrSearch, signOut, uiConfig, toggleTheme, setNavigate}