
import laser from "./imgs/laser.svg"
import cnc from "./imgs/cnc.svg"
import threeD from "./imgs/3DPrinter.svg"
import './App.scss';
import Navbar from './Navbar';
import {select3d, selectLaser,selectCNC, materialChange, colorChange, thicknessChange, newRequest, sizeChange} from './js/newRequest';

import Footer from './Footer';


import { useEffect } from 'react';

import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';
import { useNavigate } from "react-router-dom";

import { useState } from 'react';
import { CircularProgress } from "react-loading-indicators";
const auth = getAuth()

function NewRequest() {
  const navigate = useNavigate()
  
  const [user, , ] = useAuthState(auth, {onUserChanged:(auth) => {
    if (auth === null) navigate("/login?redirect="+window.location.pathname)
    
   // window.location.href="/dashboard"
  }});
  const [loading, setLoading] = useState(false)
  console.log(auth)
  
  useEffect(() => {
    
    if (user!= null) {
    
     
    }
  })
  return (
    <div className="App">
      <Navbar user={auth.currentUser}></Navbar>
      <div className="px-4 pt-5 my-5 text-center">
        <h1 className="display-4 fw-bold">Create Request</h1>
        <div className="col-lg-6 mx-auto">
          <div className="row mt-2">
            <h4>What tool is your request for:</h4>
            <p>This is important so we can know what requests to send your way.</p>
            <div className="col">
<button className="btn btn-yellow-tool col" id="select3d" htmlFor="btn-check-1" onClick={select3d}>3D Printer<br/><img src={threeD} alt="3d printer" height="150"/></button>
            </div>
            <div className="col">
<button className="btn btn-yellow-tool col" id="selectLaser"htmlFor="btn-check-2" onClick={selectLaser}>Laser Cutter<br/><img height="150" src={laser} alt="laser"/></button>
            </div>
            <div className="col">
<button className="btn btn-yellow-tool col" id="selectCNC"htmlFor="btn-check-3" onClick={selectCNC}>CNC<br/><img src={cnc} height="150" alt="cnc"/></button>
            </div>
        </div>
        </div>
        
      </div>
      <div className="container mt-3">
        <form onSubmit={(e) => {setLoading(true); newRequest(e, navigate)}}>
        <div className="row" id="3d-row" style={{display:"none"}}>
            <input type="hidden" id="tool" name="tool"/> 
            <div id="matSelectDiv">
            <label for="matSelect">Material:</label>
            <select className="form-control" id="matSelect" name="matSelect" onChange={materialChange}>
                <option selected disabled>Select</option>
                <option>PLA</option>
                <option>ABS</option>
                <option>PETG</option>
                <option>TPU</option>
                <option>Nylon</option>
                <option>PC</option>
                <option>Other</option>
            </select>
            <input className="form-control mt-2" style={{display:"none"}} name="matText" id="matText"/>
            <div className="card mt-2" style={{display:"none"}} id="matCard">
                <div className="card-header" id="matName">
                  PLA
                </div>
                <div className="card-body">
                  <p className="card-text " id="matDesc">With supporting text below as a natural lead-in to additional content.</p>
                </div>
            </div>
              </div>
              <div id="colorSelectDiv" style={{display:"none"}}>
              <label for="colorSelect">Color:</label>
              <select className="form-control mt-2" id="colorSelect" name="colorSelect" onChange={colorChange}>
                <option selected disabled>Select</option>
                </select>
                <input className="form-control mt-2" name="colorText" id="colorText" style={{display:"none"}}/>
            </div>
            <div id="thicknessSelectDiv" className="mt-2"  style={{display:"none"}}>
                <label for="thicknessSelect">Thickness:</label>
                <select className="form-control mt-2" id="thicknessSelect" name="thicknessSelect" onChange={thicknessChange}>
                  <option selected disabled>Select</option>
                  </select>
                  <input className="form-control mt-2" style={{display:"none"}} name="thicknessText" id="thicknessText"/>
              </div>
              <div id="sizeSelectDiv" className="mt-2"  style={{display:"none"}}>
                <label for="sizeSelect">Size:</label>
              <div className="row g-3">
              <div className="col-sm">
  <label for="sizeSelect">Width:</label>
                <input className="form-control mt-2" name="xSizeText" id="xSizeText"/>
  </div>
  <div className="col-sm">
  <label for="sizeSelect">Length:</label>
                <input className="form-control mt-2" name="ySizeText" id="ySizeText"/>
  </div>
  <div className="col-sm" id="heightDiv" style={{display:"none"}}>
                <label for="sizeSelect">Height:</label>
                <input className="form-control mt-2" name="zSizeText" id="zSizeText"/>
  </div>
  <div className="col-sm">
  <label for="unitSelect">Unit:</label>
  <select className="form-control mt-2" id="unitSelect" name="unitSelect" onChange={sizeChange}>
                  <option selected disabled>Select</option>
                  <option>mm</option>
                  <option>in</option>

                  </select>
  </div>
</div>
              </div>
            <div id="notesDiv" style={{display:"none"}} className="mt-2">
                <label for="notes">Additional Notes:</label>
                <textarea className="form-control mt-2" placeholder="Anything else we should know (Special Requests)" name="notes" id="notes"></textarea>
                <input className='form-control mt-2' type="file" id="fileInput"></input>
                <button className="btn btn-yellow mt-2 text-center" disabled={loading}>{loading ? <CircularProgress variant="disc" color="#fff" style={{fontSize:"5px"}}/>
 : "Submit"}</button>
              </div>

        </div>
    </form>
</div>
<Footer></Footer>

    </div>
    
  );
}


export default NewRequest;
