import HelpPage from "./HelpPage";
import Navbar from "./Navbar";
import CFLogo from "./imgs/CrowdForgeLogo.svg"
import { getAuth } from "firebase/auth";
import Footer from "./Footer";
function Help() {
    const auth = getAuth();
return (<div className="App">
<Navbar user={auth.currentUser}></Navbar>
<div className="px-4 pt-5 my-5 text-center">
  <h1 className="display-4 fw-bold">Help</h1>
  <div className="col-lg-9 mx-auto">
  <div className="container px-5">
    <div className="row">
    <div className="col"><img src={CFLogo} className="img-fluid" alt="CF Logo" style={{maxWidth:"400px", width:"100%"}}/></div>
    </div>
    </div>
    

    <p className="lead mb-4">Find a few tutorials below.</p>
  </div>
</div>
<div className="container mt-3">
    <div className="col-lg-6 mx-auto">
    <div class="accordion" id="accordionExample">
  <HelpPage id="newrequest" title="Creating a Request" symbol={<i class="bi bi-plus-lg"></i>
}  page="One"/>
  
  <HelpPage id="signingup" title="Creating an Account" symbol={<i class="bi bi-person"></i>
}  page="Two"/>
 <HelpPage id="finding" title="Finding and Accepting Requests" symbol={<i class="bi bi-search"></i>
}  page="Three"/>
</div>
    </div>
</div>
<Footer></Footer>
</div>)
}
export default Help