import { getDatabase, ref, get, set, push } from "firebase/database";
import { deleteObject, getDownloadURL, getStorage, ref as sRef, uploadBytes } from "firebase/storage";


function getUser(uid) {
    const database = getDatabase();

    return new Promise((resolve, reject) => {
       get(ref(database, "/users/"+uid)).then(snapshot => {
            var user = snapshot.val()
            resolve(user)
        })
    })
    
}
function getTeam(uid) {
    const database = getDatabase();
    return new Promise((resolve, reject) => {
       get(ref(database, "/teams/"+uid)).then(snapshot => {
            var team = snapshot.val()
            resolve(team)
        })
    })
    
}

function createTeam(teamid, team) {
    const database = getDatabase();
    return new Promise((resolve, reject) => {
        set(ref(database, "/teams/"+teamid), team).then(() => {
            resolve()
        })
    })
}

function setTeam(uid, teamId, email) {
    const database = getDatabase()
    return new Promise((resolve, reject) => {
        set(ref(database, "/users/"+uid), {team:teamId, email:email}).then(() => {
            resolve()
        })
    })
}

function createRequest(request) {
    const database = getDatabase()
    //console.log("creating request")
    // if (window.location.hostname.includes("localhost")) {
    //     // Point to the RTDB emulator running on localhost.
    //     console.log("connecting to emulator")
    //     connectDatabaseEmulator(database, "localhost", 9000)
    //   } 
      
    return new Promise((resolve, reject) => {
        push(ref(database, "/requests"), request).then(() => {
            resolve()
        })
    })
}

function getTeams() {
    const database = getDatabase()
    return new Promise((resolve, reject) => {
        get(ref(database, "/teams")).then((snapshot) => {
            resolve(snapshot.val())
        })
    })
}
function getRequests() {
    const database = getDatabase()
    return new Promise((resolve, reject) => {
        get(ref(database, "/requests")).then((snapshot) => {
            resolve(snapshot.val())
        })
    })
}
function getRequest(id) {
    const database = getDatabase()
    return new Promise((resolve, reject) => {
        get(ref(database, "/requests/"+id)).then((snapshot) => {
            resolve(snapshot.val())
        })
    })
}

function uploadFile(id, file) {
    const storage = getStorage();
    return new Promise((resolve, reject) => {
        
        uploadBytes(sRef(storage, "/requests/"+id), file).then(data => {
          //  console.log(data)
            resolve(data)
        })
    })

}

function deleteFile(path) {
    const storage = getStorage()
    return new Promise((resolve, reject) => {
        deleteObject(sRef(storage, path)).then(resolve)
    })
}

function downloadUrl(path) {
    const storage = getStorage()
    return new Promise((resolve, reject) => {
        getDownloadURL(sRef(storage, path)).then(data => {
            resolve(data)
        })
    })
}

function assignRequest(id, teamid) {
    const database = getDatabase()
    return new Promise((resolve, reject) => {
        set(ref(database, "requests/"+id+"/assigned"), teamid).then(resolve)
    })
}

function requestRecieved(id, request) {
    const database = getDatabase();
    console.log(id, request)
    return new Promise((resolve, reject) => {
        set(ref(database, "/requests/"+id+"/complete"), new Date().getTime()).then(() => {
            // getTeam(request.team).then(rTeam => {
            //     getTeam(request.assigned).then(aTeam => {
            //         if (rTeam.hasOwnProperty("stats")) {
            //             if (rTeam.stats.hasOwnProperty("requests")){
            //                 rTeam = rTeam.stats.requests
            //             } else {
            //                 rTeam.stats.requests = 0
            //             }
            //             rTeam.stats.request += 1
                       
            //         } else {
            //             rTeam.stats = {requests:1}
            //         }
            //         if (aTeam.hasOwnProperty("stats")) {
            //             if (aTeam.stats.hasOwnProperty("fufills")){
            //                 aTeam = aTeam.stats.fufills
            //             } else {
            //                 aTeam.stats.fufills = 0
            //             }
            //             aTeam.stats.fufills += 1
                       
            //         }
            //         else {
            //             aTeam.stats = {fufills:1}
            //         }
            //         setStats(request.team, rTeam.stats).then(() => {
            //             setStats(request.assigned, aTeam.stats).then(resolve)
            //         })
    

        //         })
        //     })
        })
    })
}

// function setStats(id, stats) {
//     const database = getDatabase()
//     return new Promise((resolve, reject) => {
//         set(ref(database, "/teams/"+id+"/stats"), stats).then(resolve)
//     } )
// }

function deleteRequest(id){
    const database = getDatabase()
    return new Promise((resolve, reject) => {
        set(ref(database, "/requests/"+id), null).then(resolve)
    })
}

function editRequest(id, request) {
    const database = getDatabase()
    return new Promise((resolve, reject) => {
        set(ref(database, "/requests/"+id), request).then(resolve)
    })
}

function updateNotifSettings(uid, settings) {
    const database = getDatabase()
    return new Promise((resolve, reject) => {
        set(ref(database, "/users/"+uid+"/notifSettings"), settings).then(resolve)
    })
}

export {getUser, getTeam, setTeam, createTeam, createRequest, getTeams, getRequests, getRequest, uploadFile, downloadUrl, assignRequest, requestRecieved, deleteRequest, editRequest, deleteFile, updateNotifSettings}